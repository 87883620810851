<template>
    <div>
        <b-row>
			<b-col md="4" sm="12" >
				<div class="card" >
					<div class="card-header custom-card-header fixed">
						<div class="d-flex justify-content-between mb-2">
							<div> 
								{{ mshift_name ? mshift_name :"Morning Shift"  }}
							</div>
							<div>
								
							</div>
						</div>
					</div>
					<div class="card-body " >
						<!-- <div> 
							<input type="text" class="popover-valid-custom-time-picker mb-2" v-model="mshift_name" placeholder="Enter Shift name" >
						</div> -->
                        <div class="d-flex justify-content-between ">
						<div> 
							<input type="time" :class="{[isValidTime('morning','11:59:00',mst,'L')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" :state="isValidTime('morning','11:59:00',mst,'L')" v-model="mst"  >
							<!-- <b-form-timepicker  name="example-input-1" id="example-input-1"    size="sm" v-model="mst" placeholder="Time From" locale="en"></b-form-timepicker>  -->
						</div>
						<div>TO</div>
						<div> 
							<input type="time" :class="{[isValidTime('morning',met,mst,'L')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" :state="isValidTime('morning',met,mst,'L')" v-model="met"  >
							<!-- 	<b-form-timepicker size="sm" v-model="met" :state="isValidTime('morning',met,mst,'R')" @input="get_data('morning')"  placeholder="Time To" locale="en" ></b-form-timepicker> -->
							
						</div>
						
                    </div>
                    <b-form-checkbox v-model="card_enabled" name="check-button" switch class="mt-3">
     Publish
    </b-form-checkbox>
                  
						<!-- <div class="mt-3">
							<b-button @click="onClose" size="sm" variant="danger" class="mr-2">Cancel</b-button>
							<b-button  size="sm" variant="primary" @click="get_data('Morning')"> 
							<b-spinner small v-if="morningsave"></b-spinner> Save</b-button>
						</div> -->
					</div>
				</div>
			</b-col>		
			<b-col md="4" sm="12" >
				<div class="card" >
					<div class="card-header custom-card-header fixed">
						<div class="d-flex justify-content-between mb-2">
							<div> 
								{{ ashift_name ? ashift_name :"Afternoon Shift"  }}
							</div>
						</div>
					</div>
					<div class="card-body " >
                        <div class="d-flex justify-content-between ">
						<div> 
                            <input type="time" :class="{[isValidTime1('afternoon',card_enabled==true ? met : '23:59',ast,'L')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }"  v-model="ast" :state="isValidTime1('afternoon',card_enabled==true ? met : '23:59',ast,'L')" >
								
						</div>
						<div>TO</div>
						<div> 
							<input type="time" :class="{[isValidTime1('afternoon',aet,ast,'R')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" v-model="aet" :state="isValidTime1('afternoon',aet,ast,'R')" >
							
						</div>
						
                    </div>
                    <b-form-checkbox v-model="card_enabled1" name="check-button" switch class="mt-3">
     Publish
    </b-form-checkbox>
					</div>
				</div>
			</b-col>
			<b-col md="4" sm="12" >
				<div class="card" >
					<div class="card-header custom-card-header fixed">
						<div class="d-flex justify-content-between mb-2">
							<div> 
								{{ eshift_name ? eshift_name :"Evening Shift"  }}
							</div>
							
						</div>
					</div>
					<div class="card-body " >
                        <div class="d-flex justify-content-between ">
						<div> 
                            <input type="time" 	:class="{[isValidTime2('evening',est,card_enabled1==true ? aet : '00:00:00','L')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" v-model="est" :state="isValidTime2('evening',est,card_enabled1==true ? aet : '00:00:00','L')">
								
						</div>
						<div>TO</div>
						<div> 
							<input type="time" :class="{[isValidTime2('evening',eet,est,'R')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" v-model="eet" :state="isValidTime2('evening',eet,est,'R')">
									
						</div>
						
                    </div>
                    <b-form-checkbox v-model="card_enabled2" name="check-button" switch class="mt-3">
     Publish
    </b-form-checkbox>
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
	import axios from "axios";
	import moment from 'moment';
	export default {
		props:['bus'],
		data() {
			return {
				
				card_enabled:false,
				card_enabled1:false,
				card_enabled2:false,
				new_shift_enable:false,
				
				child_venue_id:"",
				timevalue:"",
				timevalue1:"",
				notes: '',
				/* time  */
				
				mshift_name:'Morning',
				ashift_name:'Afternoon',
				eshift_name:'Evening',
				met:"",
				mst:"",
				msd:"",
				med:"",
				msa:"",
				ast:"",
				aet:"",
				asd:"",
				aed:"",
				aea:"",
				est:"",
				eet:"",
				esd:"",
				eed:"",
				eea:"",
				formatted: '',
				format : 'hh:mm:ss',
				current_year:new Date().getFullYear(),
				morningsave:false,
				afternoonsave:false,
				eveningsave:false,
				headers:[],
			}
		},
		methods:
		{
            isValidTime(checkshift,fromtime,totime,side) 
			{
				side
				// const totimes =fromtime ? fromtime : totime;
				const time = moment(fromtime, this.format);
				const beforeTime = moment(totime, this.format);
				const afterTime = moment('23:59:00', this.format);
				/* 	let m=0;
					let n=0;
				let c=false; */
				if (time.isBetween(beforeTime, afterTime))
				{
					//this.card_enabled=true;
					//this.card_enabled=true;
					/* if(checkshift=="morning")
					{ */
					//this.card_enabled=true;
					/* m=this.mst;
						n = this.met;
					c=this.card_enabled; */
					//	}
					/* else if(checkshift=="afternoon")
						{
						this.card_enabled1=true;
						m=this.ast;
						n = this.aet;
						c=this.card_enabled1;
						}
						else if(checkshift=="evening")
						{
						this.card_enabled2=true;
						m=this.est;
						n = this.eet;
						c=this.card_enabled2;
					} */
					//this.saveTiming(checkshift,m,n,side,c);
					return true;
					
				} 
				else 
				{
					return false;
				}
				
				
			},
			isValidTime1(checkshift,fromtime,totime,side) 
			{
				side
				var beforeTime;
				var afterTime;
				var time;
				if(this.card_enabled==true && side=="L")
				{
					beforeTime = moment(this.met, "HH:mm"); //proper
					//	console.log('Moring Enabled Proper condiiton');
					afterTime = moment('23:59', "HH:mm");
					time= moment(totime, "HH:mm");
				}
				else if(side=="L")
				{
					beforeTime = moment(totime, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(totime, "HH:mm");
					//console.log('Moring Dibled Proper condiiton');  //proper
				}
				if(side=="R")
				{
					beforeTime = moment(this.ast, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.aet, "HH:mm");
					console.log('Right side Enabled Proper condiiton');
				}
				
				
				
				
				
				
				
				if(time.isSameOrAfter(beforeTime) && time.isSameOrBefore(afterTime))
				{
					return true;
				}
				/* if(moment(fromtime,'HH:mm:ss').isSameOrAfter(moment(totime,'HH:mm:ss')) && 
					moment(fromtime,'HH:mm:ss').isSameOrAfter(moment('23:59:00','HH:mm:ss')))
					{
					return true;
				} */
				/* 	if(side=="L")
					{
					console.log(fromtime);
					console.log(totime);
					console.log(side);
					}
					const time1 = moment(fromtime, this.format);
					const beforeTime1 = moment(totime, this.format);
					const afterTime1 = moment('23:59:00', this.format);
					if (time1.isBetween(beforeTime1, afterTime1))
					{
					return true;
					} 
					else 
					{
					return false;
				} */
				
				/* side
					var time1=null;
					
					if(this.card_enabled==true && side=="L")
					{
					time1 = moment(fromtime, this.format);
					}
					else if(this.card_enabled==false && side=="L")
					{
					time1 = moment('00:00:00', this.format);
					}
					if(side=="R")
					{
					time1 = moment(this.aet, this.format);
				}  */
				
				//time1 = moment(fromtime, this.format);
				
				//const beforeTime1 = moment(totime, this.format);
				//const afterTime1 = moment('23:59:00', this.format);
				
				
				
				
			},
			isValidTime2(checkshift,fromtime,totime,side) 
			{
				var beforeTime;
				var afterTime;
				var time;
				if(this.card_enabled==true && this.card_enabled1==true && side=="L")
				{
					beforeTime = moment(this.aet, "HH:mm"); //proper
					//	console.log('Moring Enabled Proper condiiton');
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.est, "HH:mm");
				}
				else if(this.card_enabled==true && this.card_enabled1==false && side=="L")
				{
					beforeTime = moment(this.met, "HH:mm"); //proper
					//	console.log('Moring Enabled Proper condiiton');
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.est, "HH:mm");
				}
				else if(this.card_enabled==false && this.card_enabled1==true && side=="L")
				{
					beforeTime = moment(this.aet, "HH:mm"); //proper
					//	console.log('Moring Enabled Proper condiiton');
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.est, "HH:mm");
				}
				else if(side=="L")
				{
					beforeTime = moment(totime, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(totime, "HH:mm");
					//console.log('Moring Dibled Proper condiiton');  //proper
				}
				if(side=="R")
				{
					beforeTime = moment(this.est, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.eet, "HH:mm");
					console.log('Right side Enabled Proper condiiton');
				}
				
				
				
				
				
				
				
				if(time.isSameOrAfter(beforeTime) && time.isSameOrBefore(afterTime))
				{
					return true;
				}
				/* side
					
					var  time2;
					if(fromtime=="")
					{
					time2 = moment('00:00:00', this.format);
					}
					else{
					time2 = moment(fromtime, this.format);
					}
					//const time2 = moment(fromtime, this.format);
					
					const beforeTime2 = moment(totime, this.format);
					const afterTime2 = moment('23:59:00', this.format);
					
					if (time2.isBetween(beforeTime2, afterTime2))
					{
					//this.card_enabled2=true;
					
					return true;
					} 
					else 
					{
					return false;
				} */
				
				
			}, 
			/* saveTiming(checkshift,time,beforeTime,side,status)
				{
				if(time!=null && beforeTime!=null)
				{
				console.log(checkshift+' -- '+time +' --- '+beforeTime+' --- '+side);
				axios.post("/api/calender_time_update",{shift:checkshift,time:time,beforeTime:beforeTime,status:status,vid:this.child_venue_id }).then((resp) => {
				resp;
				});
				}
				
			}, */
			get_toggle_data(checkshift,status)
			{
				axios.post("/api/Enable_public",{shift:checkshift,status:status,vid:this.child_venue_id }).then((resp) => {
					resp;
					this.year_calender();
					this.allHeader();
				});
			},
			today_date()
			{
				var myDate = new Date();
				var d = new Date(myDate),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();
				
				if (month.length < 2) 
				month = '0' + month;
				if (day.length < 2) 
				day = '0' + day;
				return [year,month,day].join('-');
			},
			checkvalidation(a,b,c)
			{
				return [c,b,a].join('-');
				
			},
			get_data(shift)
			{
				if(shift=='Morning')
				{
					this.morningsave=true;
					
					if(this.mst =="" || this.met =="")
					{
						this.$root.$refs.app.showToast("danger", 'Please select Time');
						
						//this.card_enabled='false';
						return  false;
					}
					if(this.card_enabled==true)
					{    //Same time			 //morning afternoon
                        if(this.mst==this.met)
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							
							return false;
						}
					}
					if(this.card_enabled1==true)
					{
						if(this.mst==this.ast || this.mst==this.aet || this.met==this.ast || this.met==this.aet   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					if(this.card_enabled2==true)
					{
						if(this.mst==this.est || this.mst==this.eet || this.met==this.est || this.met==this.eet   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					
					axios.post("/api/calender_time_update",{shift_name:this.mshift_name,shift:shift,time:this.mst,beforeTime:this.met,status:this.card_enabled,vid:this.child_venue_id }).then((resp) => {
						resp;
						this.$root.$refs.app.showToast("success", 'Morning Time Updated');
						this.popoverShow = false
						this.morningsave=false
						this.year_calender();
						this.allHeader();
						
					});
					
				}
				else if(shift=='Afternoon')
				{
					this.afternoonsave=true;
					if(this.ast =="" || this.aet =="")
					{
						//this.card_enabled1=false;
						this.$root.$refs.app.showToast("danger", 'Please select Time');
						return  false;
					}
					if(this.card_enabled1==true)
					{    //Same time			 //morning afternoon
                        if(this.ast==this.aet)
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					if(this.card_enabled==true)
					{
						if(this.ast==this.mst || this.ast==this.met || this.aet==this.mst || this.aet==this.met   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					if(this.card_enabled2==true)
					{
						if(this.ast==this.est || this.ast==this.eet || this.aet==this.est || this.aet==this.eet   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					axios.post("/api/calender_time_update",{shift_name:this.ashift_name,shift:shift,time:this.ast,beforeTime:this.aet,status:this.card_enabled1,vid:this.child_venue_id }).then((resp) => {
						resp;
						this.$root.$refs.app.showToast("success", 'Afternoon Time Updated');
						this.popoverShow1 = false
						this.afternoonsave=false;
						this.year_calender();
						this.allHeader();
						
					});
				}
				else if(shift=='Evening')
				{
					this.eveningsave=true
					if(this.est =="" || this.eet =="")
					{
						this.$root.$refs.app.showToast("danger", 'Please select Time');
						//this.card_enabled2='false';
						return  false;
						
					}
					if(this.card_enabled3==true)
					{    //Same time			 //morning afternoon
                        if(this.est==this.eet)
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					if(this.card_enabled==true)
					{
						if(this.est==this.mst || this.est==this.met || this.eet==this.mst || this.eet==this.met   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					if(this.card_enabled1==true)
					{
						if(this.est==this.ast || this.est==this.aet || this.eet==this.ast || this.eet==this.aet   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					axios.post("/api/calender_time_update",{shift_name:this.eshift_name,shift:shift,time:this.est,beforeTime:this.eet,status:this.card_enabled2,vid:this.child_venue_id }).then((resp) => {
						resp;
						this.$root.$refs.app.showToast("success", 'Evening Time Updated');
						this.popoverShow3 = false
						this.eveningsave=false
						this.year_calender();
						this.allHeader();
					});
				}
               
			},
			allHeader()
			{
				axios.post("/api/all_publish_header",{vid:this.child_venue_id} ).then((resp) => {
					this.headers=resp.data.data;
					
				});
			},
            check_shift_header()
			{
                this.child_venue_id =this.$route.params.id;
				axios.get("/api/check_shift_header?vid="+this.child_venue_id).then((resp) => {
				
					var morning=resp.data.morning
					var afternoon=resp.data.afternoon
					var evening=resp.data.evening

					this.mshift_name = morning ? morning.custom_name : "Morning";
					this.mst = morning ? morning.from_time : "";
					this.met = morning ? morning.to_time : "";
					this.card_enabled =  morning ? morning.publish==1 ? true : false : false;

                   

					this.ashift_name = afternoon ? afternoon.custom_name : "Afternoon";
					this.ast = afternoon ? afternoon.from_time : "";
					this.aet = afternoon ? afternoon.to_time : "";
					this.card_enabled1 =  afternoon ? afternoon.publish==1 ? true : false : false;

					this.eshift_name = evening ? evening.custom_name : "Evening";
					this.est = evening ? evening.from_time : "";
					this.eet = evening ? evening.to_time : "";
					this.card_enabled2 =  evening ? evening.publish==1 ? true : false : false;
					
					
				});
			},
			submits() {
       alert("hello")
    }
		},
        mounted()
        {
            this.check_shift_header();
			this.bus.$on('submits', this.submits)
        }
	}
</script>

<style>
	
</style>