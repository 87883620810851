<template>
	<div>
		<div class="card p-3 mb-2">
			<b-row >
				<b-col cols="4" class="text-left">
					<span v-if="headers.length > 0 ">
						<input type="checkbox"  v-model="new_shift_enable"> Base Price 
					</span>
				</b-col>
				<b-col cols="4" v-if="calender_enable" class="text-center">
					
					
					<b-input-group>
						<template #prepend>
							<button class="btn btn-venuecolor btn-sm">
								<i class="mdi mdi-chevron-left cursor-pointer"  @click="nextMonth('Pre')"></i>
							</button>
							
						</template> 
						
						<datepicker
						minimum-view="month"
						id="renovated"
						format="MMMM-yyyy"
						placeholder="Enter built year"
						v-model="year_month_val"
						class="form-control custom_date_picker"
						@input="date_calender('Nrl')"
						:disabled-dates="disabledDates"
						
						>
						</datepicker>  
						
						<template #append>
							<button class="btn btn-venuecolor btn-sm">
								<i class="mdi mdi-chevron-right cursor-pointer"  @click="nextMonth('Nxt')"></i>
							</button>
						</template>
					</b-input-group>
				</b-col>
				<b-col cols="4" v-else class="text-center">
					<b-input-group>
						<template #prepend>
							<button class="btn btn-venuecolor btn-sm">
								<i class="mdi mdi-chevron-left cursor-pointer" v-if="year_val_< year_val" @click="nextMonthList_('Pre')"></i>
								<i class="mdi mdi-lock cursor-pointer" v-else></i>
							</button>
						</template>
						<datepicker
						minimum-view="month"
						id="renovated"
						format="MMMM-yyyy"
						placeholder="Enter built year"
						v-model="year_val"
						class="form-control custom_date_picker"
						@input="year_calender('Nrl')"
						:disabled-dates="disabledDates"
						>
						</datepicker>  
						
						<template #append>
							<button class="btn btn-venuecolor btn-sm">
								<i class="mdi mdi-chevron-right  cursor-pointer"  @click="nextMonthList_('Nxt')"></i>
							</button>
							
						</template>
					</b-input-group>
				</b-col>
				
				<b-col cols="4" >
					<div class="d-flex justify-content-end">
						<div class="mr-2"> 
							List  
						</div>
						<div> 
							<b-form-checkbox v-model="calender_enable" switch @change="filterMedia()"></b-form-checkbox>
						</div>
						<div> 
							Mini Calender 
						</div>
					</div> 
				</b-col>
			</b-row>
		</div>	
		
		<div v-if="new_shift_enable" class="mb-2">
			<ShiftAdd  @reload-timeshift="updateDescription" @load-todo="loadTodo"/>
		</div>
		
		<b-modal id="modal-1" title="Amount update" no-close-on-esc  no-close-on-backdrop header-bg-variant="primary" header-text-variant="light" hide-footer>
			You Selected Date {{ selected_shift }}
			
			<span v-if="selected_shift=='morning'" >
				<table class="table">
					<tr v-for="(sd,r) in datechecks" :key="r">
						<td>{{ custom_date_format(sd[0])}}   </td>
						<td><i class="mdi mdi-currency-inr"></i>{{ sd[1]}}  </td>
						<td v-if="aftersave"><span v-bind:class="{'text-success': response[r]=='done',  'text-danger': response[r]!='done'}">=><i class="mdi mdi-currency-inr"></i>{{ shift_update_amount }} </span> </td>
					</tr>
				</table>
				
			</span>
			<span v-if="selected_shift=='afternoon'">
				<table class="table">
					<tr v-for="(sd,r) in datechecks_afternoon" :key="r">
						<td>{{ custom_date_format(sd[0]) }}   </td>
						<td><i class="mdi mdi-currency-inr"></i>{{ sd[1]}}  </td>
						<td v-if="aftersave"><span v-bind:class="{'text-success': response[r]=='done',  'text-danger': response[r]!='done'}">=><i class="mdi mdi-currency-inr"></i> {{ shift_update_amount }} </span> </td>
					</tr>
				</table>
			</span>
			<span v-if="selected_shift=='evening'">
				<table class="table">
					<tr v-for="(sd,r) in datechecks_evening" :key="r">
						<td>{{ custom_date_format(sd[0])}}   </td>
						<td><i class="mdi mdi-currency-inr"></i>{{ sd[1]}}  </td>
						<td v-if="aftersave"><span v-bind:class="{'text-success': response[r]=='done',  'text-danger': response[r]!='done'}">=><i class="mdi mdi-currency-inr"></i>{{ shift_update_amount }} </span> </td>
					</tr>
				</table>
			</span>
			
			
			<input type="text"  v-if="beforesave" class="form-control" placeholder="Enter amount" v-model="shift_update_amount">
			<b-row class="mt-3">
				<b-col cols="9 " offset-md="3">
					<button class="btn btn-venuecolor btn-sm btn-sm mx-auto w-50" v-if="beforesave" @click="clicktosave()">Save  </button>
					<button class="btn btn-venuecolor btn-sm btn-sm mx-auto w-50" v-if="aftersave"  @click="clicktocloose()">Confirm  </button>
				</b-col>
			</b-row> 
			
		</b-modal>
		
		<b-modal id="modal-2" title="Amount update" no-close-on-esc  no-close-on-backdrop header-bg-variant="primary" header-text-variant="light" hide-footer>
			You Selected Shift {{ selected_shift }}
			<span v-if="selected_shift=='morning'" >
				<table class="table">
					<tr v-for="(sd,r) in List_datechecks" :key="r">
						<td>{{ custom_date_format(sd[0]) }}   </td>
						<td><i class="mdi mdi-currency-inr"></i>{{ sd[1]}}  </td>
						<td v-if="aftersave"><span v-bind:class="{'text-success': response[r]=='done',  'text-danger': response[r]!='done'}">=><i class="mdi mdi-currency-inr"></i>{{ shift_update_amount }} </span> </td>
					</tr>
				</table>
				
			</span>
			<span v-if="selected_shift=='afternoon'">
				<table class="table">
					<tr v-for="(sd,r) in List_datechecks_afternoon" :key="r">
						<td>{{ custom_date_format(sd[0]) }}   </td>
						<td><i class="mdi mdi-currency-inr"></i>{{ sd[1]}}  </td>
						<td v-if="aftersave"><span v-bind:class="{'text-success': response[r]=='done',  'text-danger': response[r]!='done'}">=><i class="mdi mdi-currency-inr"></i> {{ shift_update_amount }} </span> </td>
					</tr>
				</table>
			</span>
			<span v-if="selected_shift=='evening'">
				<table class="table">
					<tr v-for="(sd,r) in List_datechecks_evening" :key="r">
						<td>{{ custom_date_format(sd[0]) }}   </td>
						<td><i class="mdi mdi-currency-inr"></i>{{ sd[1]}}  </td>
						<td v-if="aftersave"><span v-bind:class="{'text-success': response[r]=='done',  'text-danger': response[r]!='done'}">=><i class="mdi mdi-currency-inr"></i>{{ shift_update_amount }} </span> </td>
					</tr>
				</table>
			</span>
			<input type="text"  v-if="beforesave" class="form-control" placeholder="Enter amount" v-model="shift_update_amount">
			<b-row class="mt-3">
				<b-col cols="9 " offset-md="3">
					<button class="btn btn-venuecolor btn-sm btn-sm mx-auto w-50" v-if="beforesave" @click="listclicktosave()">Save  </button>
					<button class="btn btn-venuecolor btn-sm btn-sm mx-auto w-50" v-if="aftersave"  @click="listclicktocloose()">Confirm  </button>
				</b-col>
			</b-row> 
		</b-modal>
		<!--  First List Month -->
		<b-row>
			<b-col md="4" sm="12" >
                
				
				<b-overlay :show="show" rounded="sm" opacity="0.24" blur="2px">
					<div class="card" >
						<div class="card-header custom-card-header fixed">
							<div class="d-flex justify-content-between mb-2">
								
								<!-- <vue-clock-picker  v-model="value"></vue-clock-picker> -->
								
								<div> 
									{{ mshift_name ? mshift_name :"Morning Shift"  }}
								</div>
								<div>
									<div class="d-flex justify-content-end">
										<div class="mr-2"> 
											Publish
										</div>
										<div> 
											<b-form-checkbox v-model="card_enabled" switch  @change="get_toggle_data('Morning',card_enabled)"></b-form-checkbox>
										</div> 
									</div> 
									
								</div>
								
								
								
							</div>
							<!-- Popover  -->
							<b-popover
							target="popover-reactive-1"
							triggers="click"
							:show.sync="popoverShow"
							placement="bottom"
							ref="popover"
							@show="onShow"
							@shown="onShown"
							@hidden="onHidden"
							no-fade
							>
								<template #title>
									<b-button @click="onClose" class="close" aria-label="Close">
										<span class="d-inline-block" aria-hidden="true">&times;</span>
									</b-button>
									{{ mshift_name ? mshift_name :"Morning"  }} Time
								</template>
								
								
								<!-- <vue-clock-picker  v-model="value"></vue-clock-picker> -->
								<div> 
									<input type="text" class="popover-valid-custom-time-picker mb-2" v-model="mshift_name" placeholder="Enter Shift name" >
									<!-- <b-form-timepicker  name="example-input-1" id="example-input-1"    size="sm" v-model="mst" placeholder="Time From" locale="en"></b-form-timepicker>  -->
								</div>
								<div> 
									<input type="time" :class="{[isValidTime('morning','11:59:00',mst,'L')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" :state="isValidTime('morning','11:59:00',mst,'L')" v-model="mst"  >
									<!-- <b-form-timepicker  name="example-input-1" id="example-input-1"    size="sm" v-model="mst" placeholder="Time From" locale="en"></b-form-timepicker>  -->
								</div>
								<div>TO</div>
								<div> 
									<input type="time" :class="{[isValidTime('morning',met,mst,'L')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" :state="isValidTime('morning',met,mst,'L')" v-model="met"  >
									<!-- 	<b-form-timepicker size="sm" v-model="met" :state="isValidTime('morning',met,mst,'R')" @input="get_data('morning')"  placeholder="Time To" locale="en" ></b-form-timepicker> -->
									
								</div>
								<div class="mt-3">
									
									
									
									
									
									
									<b-button @click="onClose" size="sm" variant="danger" class="mr-2">Cancel</b-button>
									<b-button  size="sm" variant="primary" @click="get_data('Morning')"> 
									<b-spinner small v-if="morningsave"></b-spinner> Save</b-button>
								</div>
							</b-popover>
							<!-- Popover -->
							<div :class="{ card_disabled1: card_enabled==false,'w-50 border rounded p-1 fs-12  border-light':true}" id="popover-reactive-1">
								<span :class="{[isValidTime('morning','11:59:00',mst,'L')== true ?  'valid-text-color' : 'invalid-text-color']:true }">{{ mst=="" ? 'Set' : convert_24_12hour(mst)}}</span> 
								To
								<span :class="{[isValidTime('morning',met,mst,'R')== true ? 'valid-text-color' : 'invalid-text-color']:true }">{{ met=="" ? 'Set' : convert_24_12hour(met)}}</span>
							</div>
							
							<!--<div class="d-flex justify-content-between" :class="{ card_disabled1: card_enabled==false}" id="popover-reactive-1">
								
								
								<div> 
								<input type="text" readonly :class="{[isValidTime('morning','11:59:00',mst,'L')== true ? 'valid-custom-time-picker' : 'custom-time-picker']:true }" :state="isValidTime('morning','11:59:00',mst,'L')" v-model="mst"  >
								</div>
								<div>TO</div>
								<div> 
								<input type="text" readonly :class="{[isValidTime('morning',met,mst,'L')== true ? 'valid-custom-time-picker' : 'custom-time-picker']:true }" :state="isValidTime('morning',met,mst,'L')" v-model="met"  >
								
								</div>
								
								
								
							</div>-->
						</div>
						<div class="card-body custom-card-body" :class="{ card_disabled: card_enabled==false}" overlay-opacity="0.5">
							<div class=""  v-if="calender_enable">
								
								<!-- @mouseover="upHere = true" @mouseleave="upHere = false" :class="{hovering: upHere}" -->
								
								<div class="table-responsive" >
									<span v-if="days.length>0">
										<table class="table table-bordered custom-venue-table mt-3">
											<thead>
												<tr>
													<th colspan="2">
														<div class="fs-10" v-if="datechecks.length>0">{{ datechecks.length }} <a href="javascript:void(0)" @click="clearall('morning')">Clear</a> </div>
													</th>
													<th colspan="3" class="text-center">{{ dateformate(year_month_val) }} {{ dateYear_format(year_month_val) }}</th>
													<th colspan="2">
														<div class="fs-10" v-if="datechecks.length>0"> {{ datechecks.length }} <a href="javascript:void(0)" @click="update_amount('morning')">Update </a></div>
														
													</th>
												</tr>
												
												<tr>
													<th v-for="(head,h) in days" :key="h" :class="head">
														
														{{ head }} 
														
														
														
													</th>
													
												</tr>
											</thead>
											<tbody>
												<tr v-for="(row) in 6" :key="row" class="text-center ">
													<td  v-for="(day1,indexs) in days" :key="indexs" :class="{'mini-calender-width':true,[checkcalender(results[day1][row-1])]:true,[day1]:true,[checkvalidation(checkcalender(results[day1][row-1]),dateMonth_format(year_month_val),dateYear_format(year_month_val)) < today_date() ? 'DisabledClass' : ''] :true}" > 
														<div>
															<span v-if="checkBookOrBlock(bookBlock,1, checkcalender(results[day1][row-1]))" >
																{{checkcalender(results[day1][row-1])}} <br>
																{{ checkBookOrBlock(bookBlock,1, checkcalender(results[day1][row-1]))[0]['status']}}
															</span>
															<span v-else>
																<!--<span v-if="custom_cal_amount && custom_cal_amount[checkcalender(results[day1][row-1])] && custom_cal_amount[checkcalender(results[day1][row-1])][dateMonth_format(year_month_val)] && custom_cal_amount[checkcalender(results[day1][row-1])][dateMonth_format(year_month_val)][dateYear_format(year_month_val)][1][0]" class="custom-venue-checkbox">-->
																<input type="checkbox" class="morning" @change="onChange($event, checkcalender(results[day1][row-1]),'M',dateMonth_format(year_month_val),dateYear_format(year_month_val))"   :id="checkcalender(results[day1][row-1])" v-model="selectedmorning" :value="checkcalender(results[day1][row-1])" style="display: none;">
																<label :for="checkcalender(results[day1][row-1])" class="custom-venue-checkbox"> 
																	{{checkcalender(results[day1][row-1])}} 
																	<span class="select_amount" >
																		<span  ref="pEl" v-if="custom_cal_amount && custom_cal_amount[checkcalender(results[day1][row-1])] && custom_cal_amount[checkcalender(results[day1][row-1])][dateMonth_format(year_month_val)] && custom_cal_amount[checkcalender(results[day1][row-1])][dateMonth_format(year_month_val)][dateYear_format(year_month_val)][1][0]">
																			<i class="mdi mdi-currency-inr"></i>{{ numberFormatter(custom_cal_amount[checkcalender(results[day1][row-1])][dateMonth_format(year_month_val)][dateYear_format(year_month_val)][1][0]) }}
																		</span>
																		<span  ref="pEl" v-else>
																			
																		</span> 
																		
																	</span> 
																</label>
																<!-- </span> -->
															</span> 
														</div>
													</td>
												</tr>
												
												
											</tbody>
										</table>
									</span>
									
								</div>
							</div>
							<!--  First List Month -->
							<!--  First Calender  Month -->
							<div class=""  v-else>
								<span v-if="monthdata.length>0">
									<table class="table table-bordered custom-list-calender " role="tablist" v-for="(months ,i) in monthdata" :key="i"> 
										
										<thead>
											<tr>
												<th>
													<b-row>
														<b-col col lg="3">
															<div v-if="List_datechecks.length>0" >
																<div v-if="months.month===selectedMonth">
																	{{ List_datechecks.length }} <a href="javascript:void(0)" @click="list_clearall('morning')">Clear</a> 
																</div>
															</div>
														</b-col>
														<b-col col lg="5">
															<div v-b-toggle="'accordion-' + i" class="text-center"  @click="clicktochange(months.month_name,months.year)">{{ months.month_name }} {{ months.year }} </div>
														</b-col>
														<b-col col lg="4">
															<div v-if="List_datechecks.length>0" >
																<div v-if="months.month===selectedMonth">
																	<a href="javascript:void(0)" @click="update_list_amount('morning')">
																	Update Amount</a>
																</div>
															</div>
														</b-col>
													</b-row>
													
													
													
													
												</th>
											</tr>
										</thead>
										
										<tr>
											<b-collapse :id="'accordion-'+i"  :visible="open_status[i]==true" accordion="my-accordion" role="tabpanel">
												<table class="table list-custom-table-all ">
													<tbody  class="custom-table-tbody-sticky" >
														<tr>
															<th class="text-center">
																<input type="checkbox" 
																@change="selectAll(months.year,months.month,$event)" 
																v-model="checkAllSelect"> All
															</th>
															<th class="text-center">Day </th>
															<th class="text-center">Date</th>
															<th class="text-center">Price </th>
														</tr>
													</tbody>
													<tbody   >
														<tr v-for="(listdays,m) in checkempty(daydetails)" :key="m" :class="{[checkvalidation(listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : '' ,months.month,months.year) < today_date() ? 'DisabledClass' : ''] :true}">
															
															<td :class="{[listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : '']:true}" v-if="listdays[months.year][months.month]"> 
																<span v-if="checkBookOrBlock(bookBlock,1, listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : 0)" >
																	<input type="checkbox" >
																</span>
																<span v-else>
																	<input type="checkbox"  
																	v-model="List_datechecks" 
																	:value="[listdays[months.year][months.month] ? listdays[months.year][months.month]['date'] : '',compare_null_amount(custom_amount,listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : '',months.month,months.year,'1')]" 
																	@click="select(months.month)" >
																</span>
															</td>
															<td v-if="listdays[months.year][months.month]" :class="{[listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : '']:true}"> {{ listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : ''}}</td>
															<td v-if="listdays[months.year][months.month]" :class="{[listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : '']:true}"> {{ listdays[months.year][months.month] ? listdays[months.year][months.month]['dayshortname'] : ''}} {{ listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : ''}},{{ listdays[months.year][months.month] ? listdays[months.year][months.month]['year'] : ''}}</td>
															<td v-if="listdays[months.year][months.month]" :class="{[listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : '']:true,'text-right':true }"> 
																<span v-if="checkBookOrBlock(bookBlock,1, listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : 0)" >
																	{{ checkBookOrBlock(bookBlock,1, listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : 0)[0]['status']}}
																</span>
																<span v-else>
																	<i class="mdi mdi-currency-inr"></i>{{ compare_null_amount(custom_amount,listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : '',months.month,months.year,'1') }} 
																</span>
															</td>
															
														</tr>
													</tbody>
												</table>
											</b-collapse>
										</tr>
									</table>
								</span>
								
							</div>
						</div>
						
					</div>
				</b-overlay>
			</b-col>
			<!--  First Calender  Month -->
			<!--  second List  Month -->
			
			<b-col md="4" sm="12" >
				<b-overlay :show="show" rounded="sm" opacity="0.24" blur="2px">
					<div class="card">
						<div class="card-header custom-card-header fixed">
							<div class="d-flex justify-content-between mb-2">
								
								<!-- <vue-clock-picker  v-model="value"></vue-clock-picker> -->
								
								<div> 
									{{ ashift_name ? ashift_name :"Afternoon Shift"   }}	
								</div>
								<div>
									<div class="d-flex justify-content-end">
										<div class="mr-2"> 
											Publish
										</div>
										<div> 
											<b-form-checkbox v-model="card_enabled1" switch  @change="get_toggle_data('Afternoon',card_enabled1)"></b-form-checkbox>
										</div> 
									</div> 
									
								</div>
								<!-- Popover  -->
								<b-popover
								target="popover-reactive-2"
								triggers="click"
								:show.sync="popoverShow1"
								placement="bottom"
								ref="popover"
								@show="onShow1"
								@shown="onShown1"
								@hidden="onHidden1"
								no-fade
								>
									<template #title>
										<b-button @click="onClose1" class="close" aria-label="Close">
											<span class="d-inline-block" aria-hidden="true">&times;</span>
										</b-button>
										{{ ashift_name ? ashift_name :"Afternoon"  }} Time
									</template>
									
									
									<!-- <vue-clock-picker  v-model="value"></vue-clock-picker> -->
									<div> 
										<input type="text" class="popover-valid-custom-time-picker mb-2" v-model="ashift_name" placeholder="Enter Shift name" >
										<!-- <b-form-timepicker  name="example-input-1" id="example-input-1"    size="sm" v-model="mst" placeholder="Time From" locale="en"></b-form-timepicker>  -->
									</div>
									<div> 
										<input type="time" :class="{[isValidTime1('afternoon',card_enabled==true ? met : '23:59',ast,'L')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }"  v-model="ast" :state="isValidTime1('afternoon',card_enabled==true ? met : '23:59',ast,'L')" >
										<!-- <b-form-timepicker  name="example-input-1" id="example-input-1"    size="sm" v-model="mst" placeholder="Time From" locale="en"></b-form-timepicker>  -->
									</div>
									<div>TO</div>
									<div> 
										<input type="time" :class="{[isValidTime1('afternoon',aet,ast,'R')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" v-model="aet" :state="isValidTime1('afternoon',aet,ast,'R')" >
									</div>
									<div class="mt-3">
										
										
										
										
										
										
										<b-button @click="onClose1" size="sm" variant="danger" class="mr-2">Cancel</b-button>
										<b-button  size="sm" variant="primary" @click="get_data('Afternoon')">
										<b-spinner small v-if="afternoonsave"></b-spinner>Save</b-button>
									</div>
								</b-popover> 
								<!-- Popover -->	
								
								
							</div>
							<div :class="{ card_disabled1: card_enabled1==false,'w-50 border rounded p-1 fs-12 border-light':true}" id="popover-reactive-2">
								<span :class="{[isValidTime1('afternoon',card_enabled==true ? met : '23:59',ast,'L')== true ? 'valid-text-color' : 'invalid-text-color']:true }">{{ ast=="" ? '-' : convert_24_12hour(ast)}} </span> 
								To
								<span :class="{[isValidTime1('afternoon',aet,ast,'R')== true ? 'valid-text-color' : 'invalid-text-color']:true }">{{ aet=="" ? 'Set' : convert_24_12hour(aet)}}</span>
							</div>
							<!--<div class="d-flex justify-content-between" :class="{ card_disabled1: card_enabled1==false}" id="popover-reactive-2">
								
								
								<div> 
								
								<input type="text" readonly :class="{[isValidTime1('afternoon',card_enabled==true ? met : '23:59',ast,'L')== true ? 'valid-custom-time-picker' : 'custom-time-picker']:true }"  v-model="ast" :state="isValidTime1('afternoon',card_enabled==true ? met : '23:59',ast,'L')" >
								</div>
								<div>TO</div>
								<div> 
								<input type="text" readonly :class="{[isValidTime1('afternoon',aet,ast,'R')== true ? 'valid-custom-time-picker' : 'custom-time-picker']:true }" v-model="aet" :state="isValidTime1('afternoon',aet,ast,'R')" >
								
								</div>
								
								
								
							</div>-->
						</div>
						<div class="card-body custom-card-body" :class="{ card_disabled: card_enabled1==false}">
							<div class=""  v-if="calender_enable">
								
								
								<!-- @mouseover="upHere = true" @mouseleave="upHere = false" :class="{hovering: upHere}" -->
								
								<div class="table-responsive" >
									
									<table class="table table-bordered custom-venue-table mt-3">
										<thead>
											<tr>
												<th colspan="2">
													<div class="fs-10" v-if="datechecks_afternoon.length>0">{{ datechecks_afternoon.length }} <a href="javascript:void(0)" @click="clearall('afternoon')">Clear</a> </div>
												</th>
												<th colspan="3" class="text-center">{{ dateformate(year_month_val) }} {{ dateYear_format(year_month_val) }}</th>
												<th colspan="2">
													<div class="fs-10" v-if="datechecks_afternoon.length>0"> {{ datechecks_afternoon.length }} <a href="javascript:void(0)" @click="update_amount('afternoon')">Update </a></div>
													
												</th>
											</tr>
											<tr>
												
												<th v-for="(head,h) in days" :key="h" :class="head">
													
													{{ head }} 
													
													
													
												</th>
												
											</tr>
										</thead>
										<tbody>
											<tr v-for="(row) in 6" :key="row" class="text-center ">
												<td  v-for="(day1,indexs) in days" :key="indexs" :class="{'mini-calender-width':true,[checkcalender(results[day1][row-1])]:true,[day1]:true,[checkvalidation(checkcalender(results[day1][row-1]),dateMonth_format(year_month_val),dateYear_format(year_month_val)) < today_date() ? 'DisabledClass' : ''] :true}" > 
													<div>
														<span v-if="checkBookOrBlock(bookBlock,2, checkcalender(results[day1][row-1]))" >
															{{checkcalender(results[day1][row-1])}} <br>
															{{ checkBookOrBlock(bookBlock,2, checkcalender(results[day1][row-1]))[0]['status']}}
														</span>
														<span v-else>
															
															<span v-if="checkcalender(results[day1][row-1])" class="custom-venue-checkbox-afternoon">
																
																<input type="checkbox" class="afternoon" @change="onChange($event, checkcalender(results[day1][row-1]),'A',dateMonth_format(year_month_val),dateYear_format(year_month_val))"   :id="'afternoon-'+checkcalender(results[day1][row-1])" v-model="selectedafternoon" :value="checkcalender(results[day1][row-1])" style="display:none">
																<label :for="'afternoon-'+checkcalender(results[day1][row-1])" class="custom-venue-checkbox-afternoon"> 
																	{{checkcalender(results[day1][row-1])}} 
																	<span class="select_amount">
																		<span ref="pEl1" v-if="custom_cal_amount1 && custom_cal_amount1[checkcalender(results[day1][row-1])] && custom_cal_amount1[checkcalender(results[day1][row-1])][dateMonth_format(year_month_val)] && custom_cal_amount1[checkcalender(results[day1][row-1])][dateMonth_format(year_month_val)][dateYear_format(year_month_val)][2][0]">
																			<i class="mdi mdi-currency-inr"></i>{{  numberFormatter(custom_cal_amount1[checkcalender(results[day1][row-1])][dateMonth_format(year_month_val)][dateYear_format(year_month_val)][2][0]) }}
																		</span>
																		<span ref="pEl1" v-else>
																			
																		</span>
																		<!-- 	<i class="mdi mdi-currency-inr"></i>200000 -->
																	</span>
																</label>
															</span>
														</span>
													</div>
												</td>
											</tr>
											
											
										</tbody>
									</table>
								</div>
							</div>
							<!--  second List  Month -->
							<!--  second Calender  Month -->
							<div class=""  v-else>
								<table class="table table-bordered custom-list-calender " role="tablist" v-for="(months ,i) in monthdata" :key="i"> 
									
									<thead>
										<tr>
											<th >
												<b-row >
													<b-col col lg="3">
														<div v-if="List_datechecks_afternoon.length>0">
															<div v-if="months.month===selectedMonth">
																{{ List_datechecks_afternoon.length }} <a href="javascript:void(0)" @click="list_clearall('afternoon')">Clear</a> 
															</div>
														</div>
													</b-col>
													<b-col col lg="5">
														<div v-b-toggle="'accordion-' + i" class="text-center"  @click="clicktochange(months.month_name,months.year)" >{{ months.month_name }} {{ months.year }} </div>
													</b-col>
													<b-col col lg="4">
														<div v-if="List_datechecks_afternoon.length>0">
															<div v-if="months.month===selectedMonth">
																<a href="javascript:void(0)" @click="update_list_amount('afternoon')">Update Amount</a>
															</div>
														</div>
													</b-col>
													
												</b-row>	
												
												
												
											</th>
										</tr>
									</thead>
									
									<tr>
										<b-collapse :id="'accordion-'+i"  :visible="open_status[i]==true" accordion="my-accordion" role="tabpanel">
											<table class="table list-custom-table-all ">
												<tbody   class="custom-table-tbody-sticky">
													<tr>
														<th class="text-center">
															<input type="checkbox" 
															@change="selectAll_afternoon(months.year,months.month,$event)" 
															v-model="checkAllSelect1"> All
														</th>
														<th class="text-center">Day </th>
														<th class="text-center">Date</th>
														<th class="text-center">Price </th>
													</tr>
												</tbody>
												<tbody   >
													<tr v-for="(listdays,m) in checkempty(daydetails)" :key="m" :class="{[checkvalidation(listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : '' ,months.month,months.year) < today_date() ? 'DisabledClass' : ''] :true}">
														<td :class="{[listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : '']:true}" v-if="listdays[months.year][months.month]"> 
															<span v-if="checkBookOrBlock(bookBlock,2, listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : 0)" >
																<input type="checkbox" >
															</span>
															<span v-else>
																<input type="checkbox"  
																v-model="List_datechecks_afternoon" 
																:value="[listdays[months.year][months.month] ? listdays[months.year][months.month]['date'] : '',compare_null_amount(custom_amount1,listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : '',months.month,months.year,'2')]" 
																@click="select(months.month)">
															</span>
														</td>
														<td :class="{[listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : '']:true}" v-if="listdays[months.year][months.month]"> {{ listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : ''}}</td>
														<td :class="{[listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : '']:true}" v-if="listdays[months.year][months.month]"> {{ listdays[months.year][months.month] ? listdays[months.year][months.month]['dayshortname'] : ''}} {{ listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : ''}},{{ listdays[months.year][months.month] ? listdays[months.year][months.month]['year'] : ''}}</td>
														<td :class="{[listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : '']:true,'text-right':true}" v-if="listdays[months.year][months.month]"> 
															<span v-if="checkBookOrBlock(bookBlock,2, listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : 0)" >
																{{ checkBookOrBlock(bookBlock,2, listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : 0)[0]['status']}}
															</span>
															<span v-else>
																<i class="mdi mdi-currency-inr"></i>{{ compare_null_amount(custom_amount1,listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : '',months.month,months.year,'2') }} 
															</span>
														</td>
														
													</tr>
												</tbody>
											</table>
										</b-collapse>
									</tr>
								</table>
								
								<!--<table class="table table-bordered custom-list-calender" v-for="(months ,i) in monthdata" :key="i">
									<thead>
									<tr>
									<th colspan="4" class="text-center">
									<div class="d-flex justify-content-between">
									<div :class="ActiveMonth == months.month_name ?'d-block':'d-none'" >
									Clear
									</div>
									
									<div :class="ActiveMonth == months.month_name ?'d-block':'d-none'" >
									Update
									</div>
									</div>
									<div class="d-flex justify-content-between">
									<div :class="ActiveMonth == months.month_name ?'d-block':'d-none'" >
									
									<a  href="#" @click="PrvMonthList(months.month_number)" v-if="ActiveMonth!='January'  ">Prev</a>
									</div>
									<div>
									<a href="javascript:void(0)" @click="tooglemonths(months.month_name)">{{ months.month_name }} {{ months.year }} </a>
									</div>
									<div :class="ActiveMonth == months.month_name ?'d-block':'d-none'" >
									<a :href="'#'+ActiveMonth" @click="nextMonthList(months.month_number)" v-if="ActiveMonth!='December'  ">Next</a>
									</div>
									</div>
									
									
									</th>
									</tr>
									<tr :class="ActiveMonth == months.month_name ?'':'d-none'" > 
									<th><b-form-checkbox value="orange"></b-form-checkbox></th>
									<th>Day name</th>
									<th>Date</th>
									<th>Price </th>
									</tr>
									</thead>
									<tbody :id="months.month_name"  :class="ActiveMonth == months.month_name ?'':'d-none'"  >
									<tr v-for="(listdays,m) in checkempty(daydetails)" :key="m" >
									<td> <b-form-checkbox :value="listdays[months.year][months.month]" v-model="datecheck"></b-form-checkbox></td>
									<td> {{ listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : ''}}</td>
									<td> {{ listdays[months.year][months.month] ? listdays[months.year][months.month]['dayshortname'] : ''}} {{ listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : ''}},{{ listdays[months.year][months.month] ? listdays[months.year][months.month]['year'] : ''}}</td>
									<td> <i class="mdi mdi-currency-inr"></i>10000</td>
									
									</tr>
									</tbody>
								</table>-->
							</div>
							
							<!--	<div class="accordion" role="tablist" >
								<b-cards no-body class="mb-1 venue-custom-scroll" v-for="(months ,i) in monthdata" :key="i">
								<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button class="venue-custom-toggle" block v-b-toggle="'accordion-' + i" >{{ months.month_name }} {{ months.year }}</b-button>
								</b-card-header>
								<b-collapse :id="'accordion-' + i "  visible  accordion="my-accordion" role="tabpanel">
								<b-card-bodys>
								
								
								<div  v-for="(days,m) in daydetails" :key="m" >
								<div v-if="days[months.year][months.month]" class="d-flex justify-content-between">
								<div>
								<b-form-checkbox :value="days[months.year][months.month]['date']" v-model="datecheck"></b-form-checkbox>
								</div>
								<div class="text-justify">
								
								{{ days[months.year][months.month] ? days[months.year][months.month]['dayname'] : ''}}
								</div>
								<div>
								{{ days[months.year][months.month] ? days[months.year][months.month]['dayshortname'] : ''}} {{ days[months.year][months.month] ? days[months.year][months.month]['day_number'] : ''}},{{ days[months.year][months.month] ? days[months.year][months.month]['year'] : ''}}
								</div>
								<div> 
								<i class="mdi mdi-currency-inr"></i>10000
								</div>
								</div>
								</div> 
								</b-card-bodys>
								</b-collapse>
								</b-cards>
								
								
								
								
								
							</div>-->
						</div>
						
					</div>
				</b-overlay>
			</b-col>
			<!--  second Calender  Month -->
			<!--  Third List  Month -->
			<b-col md="4" sm="12" >
				<b-overlay :show="show" rounded="sm" opacity="0.24" blur="2px">
					<div class="card">
						<div class="card-header custom-card-header fixed">
							<div class="d-flex justify-content-between mb-2">
								
								<!-- <vue-clock-picker  v-model="value"></vue-clock-picker>  -->
								<div> 
									{{ eshift_name ? eshift_name :"Evening Shift"  }}
								</div>
								<div>
									<div class="d-flex justify-content-end">
										<div class="mr-2"> 
											Publish
										</div>
										<div> 
											<b-form-checkbox v-model="card_enabled2" switch @change="get_toggle_data('Evening',card_enabled2)"></b-form-checkbox>
										</div> 
									</div> 
									
								</div>
								
								
								
							</div>
							<!-- Popover  -->
							<b-popover
							target="popover-reactive-3"
							triggers="click"
							:show.sync="popoverShow3"
							placement="bottom"
							ref="popover"
							@show="onShow3"
							@shown="onShown3"
							@hidden="onHidden3"
							no-fade
							>
								<template #title>
									<b-button @click="onClose3" class="close" aria-label="Close">
										<span class="d-inline-block" aria-hidden="true">&times;</span>
									</b-button>
									{{ eshift_name ? eshift_name :"Evening"  }} Time
								</template>
								
								
								<!-- <vue-clock-picker  v-model="value"></vue-clock-picker> -->
								<div> 
									<input type="text" class="popover-valid-custom-time-picker mb-2" v-model="eshift_name" placeholder="Enter Shift name" >
									<!-- <b-form-timepicker  name="example-input-1" id="example-input-1"    size="sm" v-model="mst" placeholder="Time From" locale="en"></b-form-timepicker>  -->
								</div>
								<div> 
									<input type="time" 	:class="{[isValidTime2('evening',est,card_enabled1==true ? aet : '00:00:00','L')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" v-model="est" :state="isValidTime2('evening',est,card_enabled1==true ? aet : '00:00:00','L')">
								</div>
								<div>TO</div>
								<div> 
									<input type="time" :class="{[isValidTime2('evening',eet,est,'R')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" v-model="eet" :state="isValidTime2('evening',eet,est,'R')">
									<!-- 	<b-form-timepicker size="sm" v-model="met" :state="isValidTime('morning',met,mst,'R')" @input="get_data('morning')"  placeholder="Time To" locale="en" ></b-form-timepicker> -->
									
								</div>
								<div class="mt-3">
									
									
									
									
									
									
									<b-button @click="onClose3" size="sm" variant="danger" class="mr-2">Cancel</b-button>
									<b-button  size="sm" variant="primary" @click="get_data('Evening','box')">
									<b-spinner small v-if="eveningsave"></b-spinner>  Save</b-button>
								</div>
							</b-popover>
							
							<div :class="{ card_disabled1: card_enabled2==false,'w-50 border rounded p-1 fs-12  border-light':true} " id="popover-reactive-3">
								<span :class="{[isValidTime2('evening',est,card_enabled1==true ? aet : '00:00:00','L')== true ?  'valid-text-color' : 'invalid-text-color']:true }">{{ est=="" ? '-' : convert_24_12hour(est)}}  </span> 
								To
								<span :class="{[isValidTime2('evening',eet,est,'R')== true ? 'valid-text-color' : 'invalid-text-color']:true }">{{ eet=="" ? 'Set Time' : convert_24_12hour(eet)}} </span>
							</div>
							
							<!--	<div class="d-flex justify-content-between" :class="{ card_disabled1: card_enabled2==false}" id="popover-reactive-3">
								
								
								<div> 
								<input type="text" readonly 	:class="{[isValidTime2('evening',est,card_enabled1==true ? aet : '00:00:00','L')== true ? 'valid-custom-time-picker' : 'custom-time-picker']:true }" v-model="est" :state="isValidTime2('evening',est,card_enabled1==true ? aet : '00:00:00','L')">
								</div>
								<div>TO</div>
								<div> 
								<input type="text" readonly :class="{[isValidTime2('evening',eet,est,'R')== true ? 'valid-custom-time-picker' : 'custom-time-picker']:true }" v-model="eet" :state="isValidTime2('evening',eet,est,'R')" >
								
								</div>
								
								
								
							</div>-->
							
						</div>
						<div class="card-body custom-card-body" :class="{ card_disabled: card_enabled2==false}">
							<div class=""  v-if="calender_enable">
								
								
								<!-- @mouseover="upHere = true" @mouseleave="upHere = false" :class="{hovering: upHere}" -->
								
								<div class="table-responsive" >
									
									<table class="table table-bordered custom-venue-table mt-3">
										<thead>
											<tr>
												<th colspan="2">
													<div class="fs-10" v-if="datechecks_evening.length>0">{{ datechecks_evening.length }} <a href="javascript:void(0)" @click="clearall('evening')">Clear</a> </div>
												</th>
												<th colspan="3" class="text-center">{{ dateformate(year_month_val) }} {{ dateYear_format(year_month_val) }}</th>
												<th colspan="2">
													<div class="fs-10" v-if="datechecks_evening.length>0"> {{ datechecks_evening.length }} <a href="javascript:void(0)" @click="update_amount('evening')">Update 
													</a></div>
												</th>
											</tr>
											<tr>
												<th v-for="(head,h) in days" :key="h" :class="head">
													{{ head }} 
												</th>
												
											</tr>
										</thead>
										<tbody>
											<tr v-for="(row) in 6" :key="row" class="text-center ">
												<td  v-for="(day1,indexs) in days" :key="indexs" :class="{'mini-calender-width':true,[checkcalender(results[day1][row-1])]:true,[day1]:true,[checkvalidation(checkcalender(results[day1][row-1]),dateMonth_format(year_month_val),dateYear_format(year_month_val)) < today_date() ? 'DisabledClass' : ''] :true}" > 
													<div>
														
														
														<span v-if="checkcalender(results[day1][row-1])" class="custom-venue-checkbox-evening">
															<span v-if="checkBookOrBlock(bookBlock,3, checkcalender(results[day1][row-1]))" >
																{{checkcalender(results[day1][row-1])}} <br>
																{{ checkBookOrBlock(bookBlock,3, checkcalender(results[day1][row-1]))[0]['status']}}
															</span>
															<span v-else>
																<input type="checkbox" class="evening" @change="onChange($event, checkcalender(results[day1][row-1]),'E',dateMonth_format(year_month_val),dateYear_format(year_month_val))"   :id="'eveing-'+checkcalender(results[day1][row-1])" v-model="selectedevening" :value="checkcalender(results[day1][row-1])" style="display: none;">
																<label :for="'eveing-'+checkcalender(results[day1][row-1])" class="custom-venue-checkbox-evening"> 
																	{{checkcalender(results[day1][row-1])}} 
																	<span class="select_amount">
																		<span  ref="pEl2" v-if="custom_cal_amount2 && custom_cal_amount2[checkcalender(results[day1][row-1])] && custom_cal_amount2[checkcalender(results[day1][row-1])][dateMonth_format(year_month_val)] && custom_cal_amount2[checkcalender(results[day1][row-1])][dateMonth_format(year_month_val)][dateYear_format(year_month_val)][3][0]">
																			<i class="mdi mdi-currency-inr"></i>{{ numberFormatter(custom_cal_amount2[checkcalender(results[day1][row-1])][dateMonth_format(year_month_val)][dateYear_format(year_month_val)][3][0]) }}
																		</span>
																		<span ref="pEl2" v-else>
																			
																		</span>
																	</span>
																</label> 
																
															</span>
														</span>
													</div>
												</td>
											</tr>
											
											
										</tbody>
									</table>
								</div>
							</div>
							
							<!--  Third List  Month -->
							<!--  Third Calender  Month -->
							<div class=""  v-else>
								<table class="table table-bordered custom-list-calender " role="tablist" v-for="(months ,i) in monthdata" :key="i"> 
									
									<thead>
										<tr>
											<th >
												<b-row >
													<b-col col lg="3">
														<div v-if="List_datechecks_evening.length>0">
															<div v-if="months.month===selectedMonth">
																{{ List_datechecks_evening.length }} <a href="javascript:void(0)" @click="list_clearall('evening')">Clear</a> 
															</div>
														</div>
													</b-col>
													<b-col col lg="5">
														<div v-b-toggle="'accordion-' + i" class="text-center" @click="clicktochange(months.month_name,months.year)" >{{ months.month_name }} {{ months.year }}  </div>
													</b-col>
													<b-col col lg="4">
														<div v-if="List_datechecks_evening.length>0">
															<div v-if="months.month===selectedMonth">
																<a href="javascript:void(0)" @click="update_list_amount('evening')">Update</a>
															</div>
														</div>
													</b-col>
												</b-row>	
												
												
												
												
											</th>
										</tr>
									</thead>
									
									<tr>
										<b-collapse :id="'accordion-'+i"  :visible="open_status[i]==true" accordion="my-accordion" role="tabpanel" >
											<table class="table list-custom-table-all ">
												<tbody  class="custom-table-tbody-sticky" >
													<tr>
														<th  class="text-center">
															<input type="checkbox" 
															@change="selectAll_evening(months.year,months.month,$event)" 
															v-model="checkAllSelect2"> All
														</th>
														<th  class="text-center">Day name</th>
														<th  class="text-center">Date</th>
														<th  class="text-center">Price </th>
													</tr>
												</tbody>
												<tbody   >
													<tr v-for="(listdays,m) in checkempty(daydetails)" :key="m" :class="{[checkvalidation(listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : '' ,months.month,months.year) < today_date() ? 'DisabledClass' : ''] :true}">
														<td :class="{[listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : '']:true}" v-if="listdays[months.year][months.month]"> 
															<span v-if="checkBookOrBlock(bookBlock,3, listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : 0)" >
																<input type="checkbox" >
															</span>
															<span v-else>
																<input type="checkbox"  
																v-model="List_datechecks_evening" 
																:value="[listdays[months.year][months.month] ? listdays[months.year][months.month]['date'] : '',compare_null_amount(custom_amount2,listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : '',months.month,months.year,'3') ]" 
																@click="select(months.month)">
															</span>
														</td>
														<td :class="{[listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : '']:true}" v-if="listdays[months.year][months.month]"> {{ listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : ''}}</td>
														<td :class="{[listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : '']:true}" v-if="listdays[months.year][months.month]"> {{ listdays[months.year][months.month] ? listdays[months.year][months.month]['dayshortname'] : ''}} {{ listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : ''}},{{ listdays[months.year][months.month] ? listdays[months.year][months.month]['year'] : ''}}</td>
														<td :class="{[listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : '']:true,'text-right':true}" v-if="listdays[months.year][months.month]"> 
															<span v-if="checkBookOrBlock(bookBlock,3, listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : 0)" >
																{{ checkBookOrBlock(bookBlock,3, listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : 0)[0]['status']}}
															</span>
															<span v-else>
																<i class="mdi mdi-currency-inr"></i>{{  compare_null_amount(custom_amount2,listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : '',months.month,months.year,'3') }}
															</span>
														</td>
														
													</tr>
												</tbody>
											</table>
										</b-collapse>
									</tr>
								</table>
								
								<!--<table class="table table-bordered custom-list-calender" v-for="(months ,i) in monthdata" :key="i">
									<thead>
									<tr>
									<th colspan="4" class="text-center">
									<div class="d-flex justify-content-between">
									<div :class="ActiveMonth == months.month_name ?'d-block':'d-none'" >
									Clear
									</div>
									
									<div :class="ActiveMonth == months.month_name ?'d-block':'d-none'" >
									Update
									</div>
									</div>
									<div class="d-flex justify-content-between">
									<div :class="ActiveMonth == months.month_name ?'d-block':'d-none'" >
									
									<a  href="#" @click="PrvMonthList(months.month_number)" v-if="ActiveMonth!='January'  ">Prev</a>
									</div>
									<div>
									<a href="javascript:void(0)" @click="tooglemonths(months.month_name)">{{ months.month_name }} {{ months.year }} </a>
									</div>
									<div :class="ActiveMonth == months.month_name ?'d-block':'d-none'" >
									<a :href="'#'+ActiveMonth" @click="nextMonthList(months.month_number)" v-if="ActiveMonth!='December'  ">Next</a>
									</div>
									</div>
									
									
									</th>
									</tr>
									<tr :class="ActiveMonth == months.month_name ?'':'d-none'" > 
									<th><b-form-checkbox value="orange"></b-form-checkbox></th>
									<th>Day name</th>
									<th>Date</th>
									<th>Price </th>
									</tr>
									</thead>
									<tbody :id="months.month_name"  :class="ActiveMonth == months.month_name ?'':'d-none'"  >
									<tr v-for="(listdays,m) in checkempty(daydetails)" :key="m" >
									<td> <b-form-checkbox :value="listdays[months.year][months.month]" v-model="datecheck"></b-form-checkbox></td>
									<td> {{ listdays[months.year][months.month] ? listdays[months.year][months.month]['dayname'] : ''}}</td>
									<td> {{ listdays[months.year][months.month] ? listdays[months.year][months.month]['dayshortname'] : ''}} {{ listdays[months.year][months.month] ? listdays[months.year][months.month]['day_number'] : ''}},{{ listdays[months.year][months.month] ? listdays[months.year][months.month]['year'] : ''}}</td>
									<td> <i class="mdi mdi-currency-inr"></i>10000</td>
									
									</tr>
									</tbody>
								</table>-->
							</div>
							
							<!--	<div class="accordion" role="tablist" >
								<b-cards no-body class="mb-1 venue-custom-scroll" v-for="(months ,i) in monthdata" :key="i">
								<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button class="venue-custom-toggle" block v-b-toggle="'accordion-' + i" >{{ months.month_name }} {{ months.year }}</b-button>
								</b-card-header>
								<b-collapse :id="'accordion-' + i "  visible  accordion="my-accordion" role="tabpanel">
								<b-card-bodys>
								
								
								<div  v-for="(days,m) in daydetails" :key="m" >
								<div v-if="days[months.year][months.month]" class="d-flex justify-content-between">
								<div>
								<b-form-checkbox :value="days[months.year][months.month]['date']" v-model="datecheck"></b-form-checkbox>
								</div>
								<div class="text-justify">
								
								{{ days[months.year][months.month] ? days[months.year][months.month]['dayname'] : ''}}
								</div>
								<div>
								{{ days[months.year][months.month] ? days[months.year][months.month]['dayshortname'] : ''}} {{ days[months.year][months.month] ? days[months.year][months.month]['day_number'] : ''}},{{ days[months.year][months.month] ? days[months.year][months.month]['year'] : ''}}
								</div>
								<div> 
								<i class="mdi mdi-currency-inr"></i>10000
								</div>
								</div>
								</div> 
								</b-card-bodys>
								</b-collapse>
								</b-cards>
								
								
								
								
								
							</div>-->
						</div>
					</div>
				</b-overlay>
			</b-col>
		</b-row>
		<!--  Third Calender  Month -->
	</div>
</template>

<script>
	import axios from "axios";
	import Datepicker from "vuejs-datepicker";
	import ShiftAdd from '../newVenue/AddShift'
	import moment from 'moment';
	
	
	export default {
        components: {
			Datepicker,
			ShiftAdd
		},
		//props:["name","tests","method"],
		props: ["todoParent","childName"],
		/* props: {
			childName: String
			},
			setup(props) {
			alert(props.childName)
		}, */
		
		
		data() {
			return {
				monthdata:[],
				daydetails:[],
				datecheck:[],
				datechecks:[],
				datechecks_afternoon:[],
				datechecks_evening:[],
				open_status:[],
				calender_enable:false,
				year_month_val:new Date(),
				days:[],
				results:[],
				upHere:false,
				ActiveMonth:'January',
				year_val:new Date(),
				year_val_:new Date(),
				selected: [],
				allSelected: false,
				userIds: [],
				shift_update_amount:null,
				aftersave :false,
				beforesave:true,
				custom_amount:[],
				custom_amount1:[],
				custom_amount2:[],
				custom_cal_amount:[],
				custom_cal_amount1:[],
				custom_cal_amount2:[],
				selected_shift:"",
				next_list:0,
				selectedMonth:null,
				card_enabled:false,
				card_enabled1:false,
				card_enabled2:false,
				new_shift_enable:false,
				List_datechecks:[],
				List_datechecks_afternoon:[],
				List_datechecks_evening:[],
				response:[],
				disabledDates: {
					to: new Date(Date.now() - 8640000)
				},
				show:false,
				child_venue_id:"",
				timevalue:"",
				timevalue1:"",
				notes: '',
				/* time  */
				
				mshift_name:'Morning',
				ashift_name:'Afternoon',
				eshift_name:'Evening',
				met:null,
				mst:null,
				msd:"",
				med:"",
				msa:"",
				ast:"",
				aet:"",
				asd:"",
				aed:"",
				aea:"",
				est:"",
				eet:"",
				esd:"",
				eed:"",
				eea:"",
				formatted: '',
				format : 'hh:mm:ss',
				current_year:new Date().getFullYear(),
				montharray:[],
				totalEnablemonth:0,
				checkAllSelect:false,
				checkAllSelect1:false,
				checkAllSelect2:false,
				yearBack:'no',
				popoverShow: false,
				popoverShow1: false,
				popoverShow3: false,
				selectedafternoon:[],
				selectedmorning:[],
				selectedevening:[],
				morningsave:false,
				afternoonsave:false,
				eveningsave:false,
				headers:[],
				bookBlock:[],
				todo: this.todoParent,
			}
		},
		methods:
		{
			custom_date_format:function(date)
			{
				console.log(date);
				var myDate = new Date(new Date(date));
				var d = new Date(myDate),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();
				
				if (month.length < 2) 
				month = '0' + month;
				if (day.length < 2) 
				day = '0' + day;
				
				const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
				"Jul", "Aug", "Sep", "Octr", "Nov", "Dec"
				];
				const New_month_names=  monthNames[month-1];
				
				return [New_month_names,day,year].join(' ');
			},
			dateformate:function(date)
			{
				var myDate = new Date(new Date(date).getTime()+(1*24*60*60*1000));
				var d = new Date(myDate),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate();
				//year = d.getFullYear();
				
				if (month.length < 2) 
				month = '0' + month;
				if (day.length < 2) 
				day = '0' + day;
				
				const monthNames = ["January", "February", "March", "April", "May", "June",
				"July", "August", "September", "October", "November", "December"
				];
				const New_month_names=  monthNames[month-1];
				
				return New_month_names;
			},
			dateYear_format:function(date)
			{
				var myDate = new Date(new Date(date).getTime()+(1*24*60*60*1000));
				var d = new Date(myDate),
				//month = '' + (d.getMonth() + 1),
				//day = '' + d.getDate(),
				year = d.getFullYear();
				
				/* if (month.length < 2) 
					month = '0' + month;
					if (day.length < 2) 
				day = '0' + day; */
				
				/* const monthNames = ["January", "February", "March", "April", "May", "June",
					"July", "August", "September", "October", "November", "December"
				]; */
				//const New_month_names=  monthNames[month-1];
				
				return  year;
			},
			dateMonth_format:function(date)
			{
				var myDate = new Date(new Date(date).getTime()+(1*24*60*60*1000));
				var d = new Date(myDate),
				month = '' + (d.getMonth() + 1);
				//day = '' + d.getDate(),
				//year = d.getFullYear();
				
				if (month.length < 2) 
				month = '0' + month;
				/*if (day.length < 2) 
				day = '0' + day; */
				
				/* const monthNames = ["January", "February", "March", "April", "May", "June",
					"July", "August", "September", "October", "November", "December"
				]; */
				//const New_month_names=  monthNames[month-1];
				
				return  month;
			},
			onlyMonth_year_format:function(date)
			{
				var myDate = new Date(new Date(date).getTime()+(1*24*60*60*1000));
				var d = new Date(myDate),
				month = '' + (d.getMonth() + 1),
				//day = '' + d.getDate(),
				year = d.getFullYear();
				
				if (month.length < 2) 
				month = '0' + month;
				/*if (day.length < 2) 
				day = '0' + day; */
				
				/* const monthNames = ["January", "February", "March", "April", "May", "June",
					"July", "August", "September", "October", "November", "December"
				]; */
				//const New_month_names=  monthNames[month-1];
				
				return [month,year].join('_');
			},
			filterMedia()
			{
				const ctype="Nrl";
				this.date_calender(ctype);
				
			},
			year_calender()
			{
				
				//this.child_venue_id = this.$route.params.id;
				var child_id=localStorage.getItem('child_venue_id');
				this.child_venue_id = child_id ? child_id : this.$route.params.id;
				this.check_shift_header();
				
				this.show=true;
				this.open_status=[];
				this.montharray=[];
				
				axios.get("/api/loadMonthSetting?year="+this.year_val+'&vid='+this.child_venue_id).then((resp) => {
					this.next_list=0;
					this.show=false;
					this.monthdata=resp.data.month_year;
					this.totalEnablemonth=this.monthdata.length;
					this.daydetails=resp.data.day_details;
					this.custom_amount=resp.data.custom_amount;
					this.custom_amount1=resp.data.custom_amount1;
					this.custom_amount2=resp.data.custom_amount2;
					this.bookBlock=resp.data.job;
					const current = new Date();
					
					this.monthdata.forEach(collapse => {
						
						var selectdate= collapse.year+'-'+collapse.month+'-'+current.getDate();
						let newdates= new Date(new Date(selectdate).getTime()+(1*24*60*60*1000));
						this.montharray.push(newdates);
						var pusharray=this.checkOpenStatus(newdates);
						this.open_status.push(pusharray);
					})
				});
				
				//this.changeMonthNext(this.year_val);
			},
			checkOpenStatus(newdates)
			{
				if(this.onlyMonth_year_format(newdates)==this.onlyMonth_year_format(this.year_val))
				{
					return  true;
				}
				else
				{
					return  false;
				}
			},
			//Month Change 
			/* changeMonthNext(year_val) 
				{
				let mn=this.dateMonth_format(year_val);
				var next_month=mn+1;
				
				this.open_status[0] = false;
				this.open_status[mn] = false;
				this.open_status[next_month] = true;
				this.open_status.push();
			}, */
			update_amount(shiftname)
			{
				this.selected_shift=shiftname
				this.$root.$emit("bv::show::modal", "modal-1");
			},
			update_list_amount(shiftname)
			{
				this.selected_shift=shiftname
				this.$root.$emit("bv::show::modal", "modal-2");
			},
			list_clearall(type)//ListRef
			{
				if(type=='morning')
				{
					this.List_datechecks=[];
					this.allSelected = false;
					this.checkAllSelect = false;
				}
				else if(type=='afternoon')
				{
					this.List_datechecks_afternoon=[];
					this.allSelected = false;
					this.checkAllSelect1 = false;
				}
				else if(type=='evening')
				{
					this.List_datechecks_evening=[];
					this.allSelected = false;
					this.checkAllSelect2 = false;
				}
				
			},
			
			date_calender(ctype)
			{
				axios.post("/api/loadCalenderSetting",{month:this.year_month_val,type:ctype,vid:this.child_venue_id}).then((resp) => {
					this.days=resp.data.days;
					this.results = resp.data.calender;
					this.year_month_val=resp.data.Selected_date;
					
					this.custom_cal_amount=resp.data.custom_amount;
					this.custom_cal_amount1=resp.data.custom_amount1;
					this.custom_cal_amount2=resp.data.custom_amount2;
				});
			},
			check_shift_header()
			{
				axios.get("/api/check_shift_header?vid="+this.child_venue_id).then((resp) => {
					
					var morning=resp.data.morning
					var afternoon=resp.data.afternoon
					var evening=resp.data.evening
					//let afternoon=resp.data.afternoon
					//let evening=resp.data.evening
					this.mshift_name = morning ? morning.custom_name : "Morning";
					this.mst = morning ? morning.from_time : "";
					this.met = morning ? morning.to_time : "";
					this.card_enabled =  morning ? morning.publish==1 ? true : false : false;
					
					this.ashift_name = afternoon ? afternoon.custom_name : "Afternoon";
					this.ast = afternoon ? afternoon.from_time : "";
					this.aet = afternoon ? afternoon.to_time : "";
					this.card_enabled1 =  afternoon ? afternoon.publish==1 ? true : false : false;
					
					this.eshift_name = evening ? evening.custom_name : "Evening";
					this.est = evening ? evening.from_time : "";
					this.eet = evening ? evening.to_time : "";
					this.card_enabled2 =  evening ? evening.publish==1 ? true : false : false;
					
					
				});
			},
			checkcalender(date)
			{
				return (date ? date['month_days'] : "");
			},
            checkempty(data)
			{
				return (data ? data : "");
			},
			nextMonth(type)
			{
				this.date_calender(type);
				this.datechecks=[];
			},
			onChange(evt,days,shift,month,year){
				console.log(month+''+ year);
				
				var dats=year+'-'+month+'-'+days;
				var amt=0;
				if(shift=="M")
				{
					amt=this.$refs.pEl[days-1].innerText
					const adddat=[dats,amt];
					if (evt.target.checked){
						this.datechecks.push(adddat);
						} else {
						this.datechecks.splice(this.datechecks.indexOf(adddat), 1)
					}
				}
				else if(shift=="A")
				{
					
					amt=this.$refs.pEl1[days-1].innerText
					
					const adddat=[dats,amt];
					if (evt.target.checked){
						this.datechecks_afternoon.push(adddat);
						} else {
						this.datechecks_afternoon.splice(this.datechecks_afternoon.indexOf(adddat), 1)
					}
				}else if(shift=="E")
				{
					amt=this.$refs.pEl2[days-1].innerText;
					const adddat=[dats,amt];
					if (evt.target.checked){
						this.datechecks_evening.push(adddat);
						} else {
						this.datechecks_evening.splice(this.datechecks_evening.indexOf(adddat), 1)
					}
				}
			},	
			
            tooglemonths(toggleMonth)
            {
				this.toogleMonth=!toggleMonth;
			},
            nextMonthList(month)
            {
                const monthNames = ["January", "February", "March", "April", "May", "June",
				"July", "August", "September", "October", "November", "December"
				];
				this.ActiveMonth=  monthNames[month];
				// return  monthNames[month];
			},
            PrvMonthList(month)
            {
                const monthNames = ["January", "February", "March", "April", "May", "June",
				"July", "August", "September", "October", "November", "December"
				];
				this.ActiveMonth=  monthNames[month-2];
				// return  monthNames[month];
			},
			//amt=this.$refs.ListRef[days-1].innerText
			selectAll(year,month,e) {
				this.selected_shift="morning";
				this.selectedMonth=month;
				this.List_datechecks=[];
				if (e.target.checked) {
					for (const ndays in this.daydetails) {
						var num=parseFloat(ndays)+parseFloat(1);
						
						//console.log(num.toString().length);
						if (num.toString().length < 2) 
						
						num = '0' + num;
						if(this.checkvalidation(num,month,year) >= this.today_date())
						{
							if(!this.checkBookOrBlock(this.bookBlock,1, num))
							{
								let amounts=this.custom_amount && this.custom_amount[num] && this.custom_amount[num][month] ? this.custom_amount[num][month][year][1][0]['full_amt'] :0;
								this.List_datechecks.push([this.daydetails[ndays][year][month]['date'],amounts]);
							}
							
						}
					}
					}else{
					this.List_datechecks=[];
				}
			},
			selectAll_afternoon(year,month,e) {
				this.selected_shift="afternoon";
				this.selectedMonth=month;
				this.List_datechecks_afternoon=[];
				if (e.target.checked) {
					for (const ndays in this.daydetails) {
						var num=parseFloat(ndays)+parseFloat(1);
						
						//console.log(num.toString().length);
						if (num.toString().length < 2) 
						num = '0' + num;
						if(this.checkvalidation(num,month,year) >= this.today_date())
						{
							if(!this.checkBookOrBlock(this.bookBlock,2, num))
							{
								let amounts=this.custom_amount1 && this.custom_amount1[num] && this.custom_amount1[num][month] ? this.custom_amount1[num][month][year][2][0]['full_amt'] :0;
								
								console.log(this.daydetails[ndays][year][month]['date']);
								this.List_datechecks_afternoon.push([this.daydetails[ndays][year][month]['date'],amounts]);
							}
						}
					}
					}else{
					this.List_datechecks_afternoon=[];
				}
			},
			selectAll_evening(year,month,e) {
				this.selected_shift="evening";
				this.selectedMonth=month;
				this.List_datechecks_evening=[];
				if (e.target.checked) {
					for (const ndays in this.daydetails) {
						var num=parseFloat(ndays)+parseFloat(1);
						
						//console.log(num.toString().length);
						if (num.toString().length < 2) 
						num = '0' + num;
						if(this.checkvalidation(num,month,year) >= this.today_date())
						{
							if(!this.checkBookOrBlock(this.bookBlock,3, num))
							{
								let amounts=this.custom_amount2 && this.custom_amount2[num] && this.custom_amount2[num][month] ? this.custom_amount2[num][month][year][3][0]['full_amt'] :0;
								
								console.log(this.daydetails[ndays][year][month]['date']);
								this.List_datechecks_evening.push([this.daydetails[ndays][year][month]['date'],amounts]);
							}
						}
					}
					}else{
					this.List_datechecks_evening=[];
				}
			},
			select:function(month)
			{
				this.selectedMonth=month;
                this.allSelected=false;
			},
			selectManual: function(evt,date,dynum) {
				this.allSelected = false;
				let amt=this.$refs.ListRef[dynum-1].innerText;
				const adddat=[date,amt];
				if (evt.target.checked){
					this.List_datechecks.push(adddat);
					} else {
					this.List_datechecks.splice(this.List_datechecks.indexOf(adddat), 1)
				}
			},
			clearall(type)
			{
				if(type=='morning')
				{
					this.datechecks=[];
					this.allSelected = false;
					this.selectedmorning=[];
				}
				else if(type=='afternoon')
				{
					this.datechecks_afternoon=[];
					this.allSelected = false;
					this.selectedafternoon=[];
				}
				else if(type=='evening')
				{
					this.datechecks_evening=[];
					this.allSelected = false;
					this.selectedevening=[];
				}
				var element =
				document.styleSheets[0].cssRules[0].style;
				element.removeProperty('text-transform: scale(0)');
				
			},
			/*  Save List  */
			clicktosave()
			{
				if(this.shift_update_amount<100000)
				{
					if(confirm("please  confirm amount ?")==true)
					{
						this.save_update_amount();
					}
					
				}
				else
				{
					this.save_update_amount();
				}
				
			},
			save_update_amount()
			{
				let form_data=null;
				let shift=null;
				if(this.calender_enable == true)
				{
					if(this.selected_shift=='morning')
					{
						shift=1;
						form_data=this.datechecks;
					}
					else if(this.selected_shift=='afternoon')
					{
						shift=2;
						form_data=this.datechecks_afternoon;
					}
					else if(this.selected_shift=='evening')
					{
						shift=3;
						form_data=this.datechecks_evening;
					}
				}
				else
				{
					if(this.selected_shift=='morning')
					{
						shift=1;
						form_data=this.List_datechecks;
					}
					else if(this.selected_shift=='afternoon')
					{
						shift=2;
						form_data=this.List_datechecks_afternoon;
					}
					else if(this.selected_shift=='evening')
					{
						shift=3;
						form_data=this.List_datechecks_evening;
					}
				}
				axios.post("/api/calender_amount_update",{form_data,shift,amount:this.shift_update_amount,vid:this.child_venue_id }).then((resp) => {
					this.response=resp.data;
					
				});
				
				this.aftersave =true,
				this.beforesave=false
			},
			clicktocloose()
			{
				this.datechecks=[];
				this.allSelected = false;
				this.year_calender();
				this.aftersave =false,
				this.beforesave=true;
				this.$root.$emit("bv::hide::modal", "modal-1");
			},
			/*  Save List  */
			
			/*  Save List  */
			listclicktosave()
			{
				if(this.shift_update_amount<100000)
				{
					if(confirm("please  confirm amount ?")==true)
					{
						this.save_update_amount();
					}
					
				}
				else
				{
					this.save_update_amount();
				}
				
			},
			listclicktocloose()
			{
				this.List_datechecks=[];
				this.List_datechecks_afternoon=[];
				this.List_datechecks_evening=[];
				this.allSelected = false;
				this.year_calender();
				this.aftersave =false,
				this.beforesave=true;
				this.$root.$emit("bv::hide::modal", "modal-2");
			},
			/*  Save List  */
			onScroll(e) {
				const { scrollTop, offsetHeight, scrollHeight } = e.target
				if ((scrollTop + offsetHeight) >= scrollHeight) {
					console.log('bottom!')
				}
			},
			nextMonthList_(prv_month)
			{
				
				if(prv_month=='Nxt')
				{
					var next_month=this.next_list+1;
					console.log(prv_month);
					this.open_status[0] = false;
					this.open_status[this.next_list] = false;
					this.open_status[next_month] = true;
					this.open_status.push();
					//	this.minusOneMonth(this.year_val,'add')
					this.year_val.setMonth(this.year_val.getMonth() + 1);	
				}
				else
				{
					if(this.yearBack=='Yes')
					{
						this.next_list=this.totalEnablemonth-1; //if back Year one time call
					}
                    else
					{
						this.next_list;
					}
					this.yearBack="no"; //flag
					
					next_month=this.next_list-1;
					
					this.open_status[this.next_list] = false;
					this.open_status[next_month] = true;
					this.open_status.push();
					//this.minusOneMonth(this.year_val,'minus')
					this.year_val.setMonth(this.year_val.getMonth() - 1);	
				}
				this.year_val= new Date(this.year_val);
				
				const years =this.year_val.getFullYear();
				if(this.current_year > years )
				{
					
					this.current_year=years;
					this.year_calender();
					this.yearBack="Yes";
				}
				else if(this.current_year < years)
				{
					
					this.open_status=[];
					this.current_year=years;
					this.year_calender();
					this.yearBack="no";
				}
				this.next_list=next_month;
				this.totalEnablemonth=next_month;
				
			},
			/* minusOneMonth(d,type) { 
				
				//var myDate = new Date(new Date().getTime()+(1*24*60*60*1000));
				//var newd = new Date(myDate);
				//	current_month = newd.getMonth() + 1;
				//const month = d.getMonth();
				//const years = d.getFullYear();
				if(type=='add')
				{
				
				d.setMonth(d.getMonth() + 1);	
				
				}
				else
				{
				
				d.setMonth(d.getMonth() - 1);	
				
				}
				
			}, */
			compare_null_amount(check_amount,day,month,year,shift)
			{
				return check_amount && check_amount[day] && check_amount[day][month] ? check_amount[day][month][year][shift][0]['full_amt'] :0;
			},
			isValidTime(checkshift,fromtime,totime,side) 
			{
				side
				// const totimes =fromtime ? fromtime : totime;
				const time = moment(fromtime, this.format);
				const beforeTime = moment(totime, this.format);
				const afterTime = moment('23:59:00', this.format);
				/* 	let m=0;
					let n=0;
				let c=false; */
				if (time.isBetween(beforeTime, afterTime))
				{
					//this.card_enabled=true;
					//this.card_enabled=true;
					/* if(checkshift=="morning")
					{ */
					//this.card_enabled=true;
					/* m=this.mst;
						n = this.met;
					c=this.card_enabled; */
					//	}
					/* else if(checkshift=="afternoon")
						{
						this.card_enabled1=true;
						m=this.ast;
						n = this.aet;
						c=this.card_enabled1;
						}
						else if(checkshift=="evening")
						{
						this.card_enabled2=true;
						m=this.est;
						n = this.eet;
						c=this.card_enabled2;
					} */
					//this.saveTiming(checkshift,m,n,side,c);
					return true;
					
				} 
				else 
				{
					return false;
				}
				
				
			},
			isValidTime1(checkshift,fromtime,totime,side) 
			{
				side
				var beforeTime;
				var afterTime;
				var time;
				if(this.card_enabled==true && side=="L")
				{
					beforeTime = moment(this.met, "HH:mm"); //proper
					//	console.log('Moring Enabled Proper condiiton');
					afterTime = moment('23:59', "HH:mm");
					time= moment(totime, "HH:mm");
				}
				else if(side=="L")
				{
					beforeTime = moment(totime, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(totime, "HH:mm");
					//console.log('Moring Dibled Proper condiiton');  //proper
				}
				if(side=="R")
				{
					beforeTime = moment(this.ast, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.aet, "HH:mm");
					console.log('Right side Enabled Proper condiiton');
				}
				
				
				
				
				
				
				
				if(time.isSameOrAfter(beforeTime) && time.isSameOrBefore(afterTime))
				{
					return true;
				}
				/* if(moment(fromtime,'HH:mm:ss').isSameOrAfter(moment(totime,'HH:mm:ss')) && 
					moment(fromtime,'HH:mm:ss').isSameOrAfter(moment('23:59:00','HH:mm:ss')))
					{
					return true;
				} */
				/* 	if(side=="L")
					{
					console.log(fromtime);
					console.log(totime);
					console.log(side);
					}
					const time1 = moment(fromtime, this.format);
					const beforeTime1 = moment(totime, this.format);
					const afterTime1 = moment('23:59:00', this.format);
					if (time1.isBetween(beforeTime1, afterTime1))
					{
					return true;
					} 
					else 
					{
					return false;
				} */
				
				/* side
					var time1=null;
					
					if(this.card_enabled==true && side=="L")
					{
					time1 = moment(fromtime, this.format);
					}
					else if(this.card_enabled==false && side=="L")
					{
					time1 = moment('00:00:00', this.format);
					}
					if(side=="R")
					{
					time1 = moment(this.aet, this.format);
				}  */
				
				//time1 = moment(fromtime, this.format);
				
				//const beforeTime1 = moment(totime, this.format);
				//const afterTime1 = moment('23:59:00', this.format);
				
				
				
				
			},
			isValidTime2(checkshift,fromtime,totime,side) 
			{
				var beforeTime;
				var afterTime;
				var time;
				if(this.card_enabled==true && this.card_enabled1==true && side=="L")
				{
					beforeTime = moment(this.aet, "HH:mm"); //proper
					//	console.log('Moring Enabled Proper condiiton');
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.est, "HH:mm");
				}
				else if(this.card_enabled==true && this.card_enabled1==false && side=="L")
				{
					beforeTime = moment(this.met, "HH:mm"); //proper
					//	console.log('Moring Enabled Proper condiiton');
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.est, "HH:mm");
				}
				else if(this.card_enabled==false && this.card_enabled1==true && side=="L")
				{
					beforeTime = moment(this.aet, "HH:mm"); //proper
					//	console.log('Moring Enabled Proper condiiton');
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.est, "HH:mm");
				}
				else if(side=="L")
				{
					beforeTime = moment(totime, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(totime, "HH:mm");
					//console.log('Moring Dibled Proper condiiton');  //proper
				}
				if(side=="R")
				{
					beforeTime = moment(this.est, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.eet, "HH:mm");
					console.log('Right side Enabled Proper condiiton');
				}
				
				
				
				
				
				
				
				if(time.isSameOrAfter(beforeTime) && time.isSameOrBefore(afterTime))
				{
					return true;
				}
				/* side
					
					var  time2;
					if(fromtime=="")
					{
					time2 = moment('00:00:00', this.format);
					}
					else{
					time2 = moment(fromtime, this.format);
					}
					//const time2 = moment(fromtime, this.format);
					
					const beforeTime2 = moment(totime, this.format);
					const afterTime2 = moment('23:59:00', this.format);
					
					if (time2.isBetween(beforeTime2, afterTime2))
					{
					//this.card_enabled2=true;
					
					return true;
					} 
					else 
					{
					return false;
				} */
				
				
			}, 
			/* saveTiming(checkshift,time,beforeTime,side,status)
				{
				if(time!=null && beforeTime!=null)
				{
				console.log(checkshift+' -- '+time +' --- '+beforeTime+' --- '+side);
				axios.post("/api/calender_time_update",{shift:checkshift,time:time,beforeTime:beforeTime,status:status,vid:this.child_venue_id }).then((resp) => {
				resp;
				});
				}
				
			}, */
			get_toggle_data(checkshift,status)
			{
				axios.post("/api/Enable_public",{shift:checkshift,status:status,vid:this.child_venue_id }).then((resp) => {
					resp;
					this.year_calender();
					this.allHeader();
				});
			},
			today_date()
			{
				var myDate = new Date();
				var d = new Date(myDate),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();
				
				if (month.length < 2) 
				month = '0' + month;
				if (day.length < 2) 
				day = '0' + day;
				return [year,month,day].join('-');
			},
			checkvalidation(a,b,c)
			{
				return [c,b,a].join('-');
				
			},
			get_data(shift)
			{
				if(shift=='Morning')
				{
					this.morningsave=true;
					
					if(this.mst =="" || this.met =="")
					{
						this.$root.$refs.app.showToast("danger", 'Please select Time');
						
						//this.card_enabled='false';
						return  false;
					}
					if(this.card_enabled==true)
					{    //Same time			 //morning afternoon
                        if(this.mst==this.met)
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							
							return false;
						}
					}
					if(this.card_enabled1==true)
					{
						if(this.mst==this.ast || this.mst==this.aet || this.met==this.ast || this.met==this.aet   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					if(this.card_enabled2==true)
					{
						if(this.mst==this.est || this.mst==this.eet || this.met==this.est || this.met==this.eet   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					
					axios.post("/api/calender_time_update",{shift_name:this.mshift_name,shift:shift,time:this.mst,beforeTime:this.met,status:this.card_enabled,vid:this.child_venue_id }).then((resp) => {
						resp;
						this.$root.$refs.app.showToast("success", 'Morning Time Updated');
						this.popoverShow = false
						this.morningsave=false
						this.year_calender();
						this.allHeader();
						
					});
					
				}
				else if(shift=='Afternoon')
				{
					this.afternoonsave=true;
					if(this.ast =="" || this.aet =="")
					{
						//this.card_enabled1=false;
						this.$root.$refs.app.showToast("danger", 'Please select Time');
						return  false;
					}
					if(this.card_enabled1==true)
					{    //Same time			 //morning afternoon
                        if(this.ast==this.aet)
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					if(this.card_enabled==true)
					{
						if(this.ast==this.mst || this.ast==this.met || this.aet==this.mst || this.aet==this.met   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					if(this.card_enabled2==true)
					{
						if(this.ast==this.est || this.ast==this.eet || this.aet==this.est || this.aet==this.eet   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					axios.post("/api/calender_time_update",{shift_name:this.ashift_name,shift:shift,time:this.ast,beforeTime:this.aet,status:this.card_enabled1,vid:this.child_venue_id }).then((resp) => {
						resp;
						this.$root.$refs.app.showToast("success", 'Afternoon Time Updated');
						this.popoverShow1 = false
						this.afternoonsave=false;
						this.year_calender();
						this.allHeader();
						
					});
				}
				else if(shift=='Evening')
				{
					this.eveningsave=true
					if(this.est =="" || this.eet =="")
					{
						this.$root.$refs.app.showToast("danger", 'Please select Time');
						//this.card_enabled2='false';
						return  false;
						
					}
					if(this.card_enabled3==true)
					{    //Same time			 //morning afternoon
                        if(this.est==this.eet)
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					if(this.card_enabled==true)
					{
						if(this.est==this.mst || this.est==this.met || this.eet==this.mst || this.eet==this.met   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					if(this.card_enabled1==true)
					{
						if(this.est==this.ast || this.est==this.aet || this.eet==this.ast || this.eet==this.aet   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					axios.post("/api/calender_time_update",{shift_name:this.eshift_name,shift:shift,time:this.est,beforeTime:this.eet,status:this.card_enabled2,vid:this.child_venue_id }).then((resp) => {
						resp;
						this.$root.$refs.app.showToast("success", 'Evening Time Updated');
						this.popoverShow3 = false
						this.eveningsave=false
						this.year_calender();
						this.allHeader();
					});
				}
			},
			allHeader()
			{
				axios.post("/api/all_publish_header",{vid:this.child_venue_id} ).then((resp) => {
					this.headers=resp.data.data;
					
				});
			},
			updateDescription()
			{
				this.year_calender();
				this.check_shift_header();
				this.allHeader();
				this.new_shift_enable=false;
			},
			numberFormatter(num) {
				if (num >= 10000000) {
					return (num / 10000000).toFixed(1).replace(/\.0$/, '') + 'M';
				}
				if (num >= 100000) {
					return (num / 100000).toFixed(1).replace(/\.0$/, '') + 'L';
				}
				if (num >= 1000) {
					return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
				}
				return num;
			},
			
			convert_24_12hour(timeString)
			{
				const [hourString, minute] = timeString.split(":");
				const hour = +hourString % 24;
				return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
			},
			clicktochange(month,year)
			{
				var dats=year+'-'+month+'-01';
				var seldate = new Date(new Date(dats).getTime()+(1*24*60*60*1000));
				this.year_val=seldate;
			},
			checkBookOrBlock(array,shift,datenumber)
			{
				return array && array[datenumber] &&  array[datenumber][shift] ? array[datenumber][shift]  : "";
			},
			checkrefresh(c) {
				if(c=='yes')
				{
					this.year_calender();
				}
			}
			
		},
		created() {
			
			
			this.min = this.formatDate(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
			this.max = this.formatDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
			this.$emit('created');
		},
		
		mounted() {
			
			localStorage.removeItem('child_venue_id');
			this.year_calender();
			this.allHeader();
			
		},
		watch: {
			childName: function(newOne){
			
				if(newOne=='yes')
				{
					this.year_calender();
					this.allHeader();
					this.check_shift_header();
				}
				this.$emit('onHappy')
			}
		}
		
	}
    //loadMonthSetting
</script>

<style>
	
	.custom-venue-table td, .custom-venue-table th, .custom-venue-table td, .custom-venue-table th {
    vertical-align: middle !important;
    padding: 0.15rem 0.5rem !important;
	}
	.custom-venue-table td
	{
    font-size: 8px;
	}
	.vdp-datepicker input {
    border: none !important;
	background:unset;
	}
	.custom-card-header
	{
    background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%) !important;
    color:white;
	}
    .venue-custom-table
    {
	width:20%;
    } 
    .venue-custom-scroll
    {
	height: 27rem;
    overflow: scroll;;
    }
    .venue-custom-toggle
    {
	background-color: white;
	color:black;
    }
    .btn-venuecolor
    {
	background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%) !important;
    color:white;
    }
    .savebutton-fixed
    {
	position: fixed;
    z-index: 999;
    /* bottom: 10%; */
    right: 63.5%;
    top: 25%;
    }
    .select_amount
    {
	display: flex;
    font-size: 9px;
    color: #494752;
    }
	
	
	label.custom-venue-checkbox {
	display: block;
	position: relative;
	cursor: pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-bottom:0 !important;
	}
	
	label.custom-venue-checkbox::before {
    /* background-color: #cb9df3; */
    color: white;
    content: " ";
    display: block;
    /* border-radius: 50%; */
	border: 1px solid #A44BF3; 
    /* border-radius: 50%; */
    /* border: 1px solid grey; */
    position: absolute;
    
    top: 0;
	width: calc(3rem);
    height: calc(2rem);
    left: -8px;
	
    text-align: center;
    line-height: 30px;
    transition-duration: 0.4s;
    transform: scale(0);
	}
	
	label.custom-venue-checkbox img {
	height: 100px;
	width: 100px;
	transition-duration: 0.2s;
	transform-origin: 50% 50%;
	}
	
	.morning:checked+  label.custom-venue-checkbox {
	border-color: #ddd;
	}
	
	.morning:checked+  label.custom-venue-checkbox::before {
	/*  content: "✓"; */
	/*  background-color: #cb9df3; */
	transform: scale(1);
	opacity: 0.7;
	}
	
	
	/*  AFternoon Check box */
	
	
	label.custom-venue-checkbox-afternoon {
	display: block;
	position: relative;
	cursor: pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-bottom:0 !important;
	}
	
	label.custom-venue-checkbox-afternoon::before {
	color: white;
    content: " ";
    display: block;
    /* border-radius: 50%; */
	border: 1px solid #A44BF3; 
    /* border-radius: 50%; */
    /* border: 1px solid grey; */
    position: absolute;
    
    top: 0;
	width: calc(3rem);
    height: calc(2rem);
    left: -8px;
	
    text-align: center;
    line-height: 30px;
    transition-duration: 0.4s;
    transform: scale(0);
	}
	
	label.custom-venue-checkbox-afternoon  img {
	height: 100px;
	width: 100px;
	transition-duration: 0.2s;
	transform-origin: 50% 50%;
	}
	
	.afternoon:checked+  label.custom-venue-checkbox-afternoon  {
	border-color: #ddd;
	}
	
	.afternoon:checked+  label.custom-venue-checkbox-afternoon::before {
	transform: scale(1);
	opacity: 0.7;
	}
	
	/*  AFternoon Check box */
	.afternoon:checked+  label.custom-venue-checkbox-afternoon  img {
	transform: scale(0.9);
	box-shadow: 0 0 5px #333;
	z-index: -1;
	}
	
	/*  evening Check box */
	
	
	label.custom-venue-checkbox-evening {
	display: block;
	position: relative;
	cursor: pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-bottom:0 !important;
	}
	
	label.custom-venue-checkbox-evening::before {
	color: white;
    content: " ";
    display: block;
    /* border-radius: 50%; */
	border: 1px solid #A44BF3; 
    /* border-radius: 50%; */
    /* border: 1px solid grey; */
    position: absolute;
    
    top: 0;
	width: calc(3rem);
    height: calc(2rem);
    left: -8px;
	
    text-align: center;
    line-height: 30px;
    transition-duration: 0.4s;
    transform: scale(0);
	}
	
	label.custom-venue-checkbox-evening  img {
	height: 100px;
	width: 100px;
	transition-duration: 0.2s;
	transform-origin: 50% 50%;
	}
	
	.evening:checked+  label.custom-venue-checkbox-evening  {
	border-color: #ddd;
	}
	
	.evening:checked+  label.custom-venue-checkbox-evening::before {
	/* content: "✓";  */
	/* background-color: #cb9df3;  */
	transform: scale(1);
	opacity: 0.7;
	}
	
	/*  AFternoon Check box */
	.evening:checked+  label.custom-venue-checkbox-evening  img {
	transform: scale(0.9);
	box-shadow: 0 0 5px #333;
	z-index: -1;
	}
	.fs-23
	{
    font-size: 23px;
	}
	.custom-venue-table td.Sun, .custom-venue-table td.Sat /* ,th.Sun, th.Sat */
	{
	background-color: #ede4fd !important;
	color: rgb(8, 8, 8);
	}
	
	.list-custom-table-all td.Saturday, .list-custom-table-all td.Sunday
	{
	background-color:#ede4fd !important;
	color: rgb(8, 8, 8);
	}
	.custom-card-body
	{
    padding: 0.25rem !important;
    height:400px !important;
    overflow-y: auto;
	}
	.custom-list-calender
	{
    font-size:12px;
	}
	.hovering
	{
    position: absolute;
    z-index: 999;
    background-color: white;
    border: 10px solid pink;
    border-bottom-left-radius: 36px;
    border-top-right-radius: 36px;
    width: 150%;
    animation: linear .3s slide-out;
	}
	@keyframes slide-out {
	from {
    transform: translateY(30px);
    opacity: 0;
	}
	
	to {
    transform: translateY(0);
    opacity: 1;
	}
	}
	.custom-list-calender td,.custom-list-calender th
	{
    padding: 2px !important;
	}
	
	.custom-list-calender thead {
	position: sticky;
	top: 0;
	background-color: #fff;
    }
	
    .custom-list-calender thead th {
	background-color: #f5f5f5;
	text-align: left;
	padding: 0.5em 1em;
	border-bottom: 1px solid #ddd;
    }
	
    .custom-list-calender tbody tr:nth-child(even) {
	background-color: #f9f9f9;
    }
	
    .custom-list-calender td {
	padding: 0.5em 1em;
	border-bottom: 1px solid #ddd;
    }
	
	.custom-list-calender td:first-child {
	font-weight: bold;
    }
	
    .display_view
    {
	display: block;
    }
	.custom_date_picker
	{  
	background: unset;
	}
	.fs-10
	{
	font-size:10px;
	}
	.fs-12
	{
	font-size:12px;
	}
	.card_disabled
	{
	background-color: lightgray;
    pointer-events: none;
	}
	.card_disabled1
	{
    pointer-events: none;
	}
	
	/*  table color */
	.custom-list-calender tr:nth-child(odd) {
    background-color: unset;
    }
	
	.custom-list-calender tr:nth-child(even) {
    background-color:unset !important;
    }
	
	#example-input-1
	{
	color: white !important;
	}
/* 	.b-form-btn-label-control.form-control > .form-control
	{
	color: white !important;
	padding-right:0 !important;
	padding-left:0 !important;
	border:none;
	word-break: normal !important
	} */
	.DisabledClass
	{
	pointer-events: none;
	background-color: #f5f5f5 !important;
	}
	td.Sun.DisabledClass, td.Sat.DisabledClass /* ,th.Sun, th.Sat */
	{
	pointer-events: none;
	background-color: #f5f5f5 !important;
	}
	
	tr.DisabledClass:nth-child(odd) {
	background-color: #f5f5f5 !important;
    }
	
	tr.DisabledClass:nth-child(even) {
	background-color: #f5f5f5 !important;
    }
	tr.DisabledClass td.Saturday, tr.DisabledClass td.Sunday
	{
	background-color: #f5f5f5 !important;
	}
	.custom-time-picker
	{
	border: 0.8px solid #f64e60;
	padding: 2px;
	border-radius: 6px;
	width: 112px;
	background: none;
	color: white;
	}
	.valid-custom-time-picker
	{
	border: 0.8px solid #0bb7af;
	padding: 2px;
	border-radius: 6px;
	width: 112px;
	background: none;
	color: white;
	}
	.popover-valid-custom-time-picker
	{
	border: 0.8px solid #0bb7af;
	padding: 2px;
	border-radius: 6px;
	width: 112px;
	background: none;
	}
	.popover-custom-time-picker
	{
	border: 0.8px solid #f64e60;
	padding: 2px;
	border-radius: 6px;
	width: 112px;
	background: none;
	}
	input[type="time"]::-webkit-calendar-picker-indicator {
    border: red;
	}
	input[type="time"]::-webkit-calendar-picker-indicator{
	/*filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);*/
	color-scheme: light;
	}
	.mini-calender-width
	{
	width: calc(100% / 7);
	height:33px;
	}
	/* .custom-time-picker::before
	{
	
	} */
	#__bv_popover_225__
	{
	will-change: unset !important;
	}
	#__bv_popover_227__
	{
	will-change: unset !important;
	}
	.valid-text-color
	{
	color:#c3f7f5;
	}
	.invalid-text-color
	{
	color:#ffc9cf;
	}
	.custom-table-tbody-sticky
	{
	position: sticky;
    top: 25px;
    background: white;
    overflow-y: hidden;
	transition-delay: 1s;
	}
	

</style>
