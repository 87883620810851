<template>
	<div>
		<b-row>
			<b-col lg="12" md="12" sm="12" class="heading-title">
				
				<h4>Edit Child Venue</h4>
				
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item to="/venues">Parent Venue</b-breadcrumb-item>
					<b-breadcrumb-item :to="'/venues/child-venue/' + parent_venue_id" >Child Venue</b-breadcrumb-item>
					<b-breadcrumb-item active> Edit Child Venue <i class="mdi mdi-arrow-right"></i>{{ overview_form.v_name }} </b-breadcrumb-item>
				</b-breadcrumb>
				
				
				
				
			</b-col>
			<!--  <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col> -->
		</b-row>
		<br />
		<b-cards class="mb-4 w-100">
			<div>
				<form-wizard
				@on-complete="save_child_venue"
				color="#1e88e5"
				:title="null"
				:subtitle="null"
				shape="tab"
				ref="wizard"
				>
					
					<tab-content
					title="Overview"
					icon="mdi mdi-stackoverflow"
					:before-change="next_overview"
					>
						<b-form>
							<h6 class="custom-text"><span>Venue Details</span></h6>
							<div class="p-2">
								
								
								<b-row>
									<b-col md="6">
										<b-row class="py-3 align-items-center">
											<b-col sm="4" class="text-left">
												<label
												class="fw-medium mb-0 mtt-2"
												for="lname2"
												style="font-size: 13px"
												>Child Venue Name<span class="text-danger">*</span>
													:</label
												>
											</b-col>
											<b-col sm="8">
												<b-form-group>
													<b-form-input :class="{'venue-custom-input form-control':true, [overview_form.v_name!='' ? 'is-valid':'' ]:true}"
													id="v_name"
													type="text"
													placeholder="Enter your child venue name"
													v-model="$v.overview_form.v_name.$model"
													:state="validateStateOverview('v_name')"
													></b-form-input>
													<b-form-invalid-feedback
													id="v_name"
													v-if="$v.overview_form.v_name.$error"
													>
														<div v-if="!$v.overview_form.v_name.required">
															Please enter venue name
														</div>
													</b-form-invalid-feedback>
												</b-form-group>
											</b-col>
										</b-row>
									</b-col>
									
									<b-col md="6">
										<b-row class="py-3 align-items-center">
											<b-col sm="4" class="text-left">
												<label class="fw-medium mb-0 mt-2" for="nname2"
												>Category<span class="text-danger">*</span> :</label
												>
											</b-col>
											<b-col sm="8">
												<b-form-group>
													<b-form-select :class="{'venue-custom-input form-control':true}"
													id="category"
													v-model="$v.overview_form.category.$model"
													:options="category_options"
													:state="validateStateOverview('category')"
													></b-form-select>
													<b-form-invalid-feedback
													id="category"
													v-if="$v.overview_form.category.$error"
													>
														<div v-if="!$v.overview_form.category.required">
															Please select category
														</div>
													</b-form-invalid-feedback>
												</b-form-group>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
								<b-row>
									<b-col md="6">
										<b-row class="py-3 align-items-center">
											<b-col sm="4" class="text-left">
												<label class="fw-medium mb-0 mt-2" for="lname2"
												>Built year:</label
												>
											</b-col>
											<b-col sm="8" class="built_year">
												<b-form-group>
													<datepicker :class="{'venue-custom-input form-control':true, [overview_form.renovated!='' ? 'is-valid':'' ]:true}"
													minimum-view="year"
													id="renovated"
													format="yyyy"
													placeholder="Enter built year"
													v-model="overview_form.renovated"
													>
													</datepicker>
												</b-form-group>
											</b-col>
										</b-row>
									</b-col>
									<b-col md="6">
										<b-row class="py-3 align-items-center">
											<b-col sm="4" class="text-left">
												<label class="fw-medium mb-0 mt-2" for="uname2">
													Guest Capacity:</label
												>
											</b-col>
											<b-col sm="8">
												<b-form-group>
													<b-form-input :class="{'venue-custom-input form-control':true, [overview_form.guest_room!='' ? 'is-valid':'' ]:true}"
													id="guest_room" 
													type="text"
													placeholder="Enter guest guest capacity"
													v-model="overview_form.guest_room"
													></b-form-input>
												</b-form-group>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
								<b-row>
									<!-- <b-col md="6">
										<b-row class="py-3 align-items-center">
										<b-col sm="5" class="text-left">
										<label class="fw-medium mb-0 mt-2" for="uname2">
										Metting Space:</label
										>
										</b-col>
										<b-col sm="7">
										<b-form-group>
										<b-form-input class="venue-custom-input"
										id="metting_space"
										type="text"
										placeholder="Enter metting space"
										v-model="$v.overview_form.metting_space.$model"
										:state="validateStateOverview('metting_space')"
										></b-form-input>
										<b-form-invalid-feedback
										id="metting_space"
										v-if="$v.overview_form.metting_space.$error"
										>
										<div v-if="!$v.overview_form.metting_space.numeric">
										Please enter valid metting space
										</div>
										</b-form-invalid-feedback>
										</b-form-group>
										</b-col>
										</b-row>
									</b-col>-->
									<b-col md="6">
										<b-row class="py-3 align-items-center">
											<b-col sm="4" class="text-left">
												<label class="fw-medium mb-0 mt-2" for="lname2"
												>Contact<span class="text-danger">*</span> :</label
												>
											</b-col>
											<b-col sm="8">
												<b-form-group>
													<b-form-input :class="{'venue-custom-input form-control':true, [overview_form.contact!='' ? 'is-valid':'' ]:true}"
													placeholder="Enter venue contact"
													v-model="$v.overview_form.contact.$model"
													:state="validateStateOverview('contact')"
													></b-form-input>
													<b-form-invalid-feedback
													id="contact"
													v-if="$v.overview_form.contact.$error"
													>
														<div v-if="!$v.overview_form.contact.required">
															Please enter venue contact
														</div>
														<div
														v-if="
														!$v.overview_form.contact.numeric ||
														!$v.overview_form.contact.minLength ||
														!$v.overview_form.contact.maxLength
														"
														>
															Please valid enter venue contact
														</div>
													</b-form-invalid-feedback>
												</b-form-group>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
								<!-- <b-row>
									<b-col md="6">
									<b-row class="py-3 align-items-center">
									<b-col sm="5" class="text-left">
									<label class="fw-medium mb-0 mt-2" for="lname2">Wing:</label>
									</b-col>
									<b-col sm="7">
									<b-form-group>
									<b-form-input :class="{'venue-custom-input form-control':true, [overview_form.wing!='' ? 'is-valid':'' ]:true}"
									id="wing"
									type="text"
									placeholder="Enter wing"
									v-model="overview_form.wing"
									></b-form-input>
									</b-form-group>
									</b-col>
									</b-row>
									</b-col>
								</b-row> -->
							</div>
							<h6 class="custom-text"><span>Location details</span></h6>
							<div class="p-2">
								
								<b-row>
									<b-col md="6">
										<b-row class="py-3 align-items-center">
											<b-col sm="4" class="text-left">
												<label class="fw-medium mb-0 mt-2" for="lname2"
												>Pincode<span class="text-danger">*</span> :</label
												>
											</b-col>
											<b-col sm="8">
												<b-form-group>
													<b-form-input :class="{'venue-custom-input form-control':true, [overview_form.pincode!='' ? 'is-valid':'' ]:true}"
													name="pincode"
													id="pincode"
													placeholder="Enter venue pincode"
													v-model="$v.overview_form.pincode.$model"
													:state="validateStateOverview('pincode')"
													@keyup="pincodeCheck()"
													></b-form-input>
													<b-form-invalid-feedback
													id="pincode"
													v-if="$v.overview_form.pincode.$error"
													>
														<div v-if="!$v.overview_form.pincode.required">
															Please enter venue pincode
														</div>
														<div
														v-if="
														!$v.overview_form.pincode.numeric ||
														!$v.overview_form.pincode.minLength ||
														!$v.overview_form.pincode.maxLength
														"
														>
															Please valid enter venue pincode
														</div>
													</b-form-invalid-feedback>
												</b-form-group>
											</b-col>
										</b-row>
									</b-col>
									<b-col md="6">
										<b-row class="py-3 align-items-center">
											<b-col sm="4" class="text-left">
												<label class="fw-medium mb-0 mt-2" for="nname2"
												>Location<span class="text-danger">*</span> :</label
												>
											</b-col>
											<b-col sm="8">
												<b-form-group>
													<input :class="{'venue-custom-input form-control':true, [overview_form.v_location!='' ? 'is-valid':'' ]:true}"
													id="v_locations1"
													type="text"
													placeholder="Enter venue location"
													v-model="overview_form.v_location"
													
													/>
												</b-form-group>
												<span class="text-danger" v-if="IsplaceChange"
												>Please select venue location</span
												>
											</b-col>
										</b-row>
									</b-col> 
									
									
									<!-- <b-col md="6">
										<b-row class="py-3 align-items-center">
										<b-col sm="4" class="text-left">
										<label class="fw-medium mb-0" for="nname2"
										>Location<span class="text-danger">*</span> :</label
										>
										</b-col>
										<b-col sm="8">
										<b-form-group>
										<input
										id="v_location"
										type="text"
										placeholder="Enter venue location"
										@keydown="change_location_update()"
										/>
										</b-form-group>
										<span class="text-danger" v-if="IsplaceChange"
										>Please select venue location</span
										>
										</b-col>
										</b-row>
									</b-col> -->
								</b-row>
								<b-row>
									<b-col md="6">
										<b-row class="py-3 align-items-center">
											<b-col sm="4" class="text-left">
												<label class="fw-medium mb-0 mt-2" for="lname2"
												>City<span class="text-danger">*</span> :</label
												>
											</b-col>
											<b-col sm="8">
												<b-form-group>
													<b-form-input :class="{'venue-custom-input form-control':true, [overview_form.city!='' ? 'is-valid':'' ]:true}"
													name="city"
													id="city"
													type="text"
													placeholder="Enter venue city"
													v-model="$v.overview_form.city.$model"
													:state="validateStateOverview('city')"
													></b-form-input>
													<b-form-invalid-feedback
													id="city"
													v-if="$v.overview_form.city.$error"
													>
														<div v-if="!$v.overview_form.city.required">
															Please enter venue city
														</div>
													</b-form-invalid-feedback>
												</b-form-group>
											</b-col>
										</b-row>
									</b-col>
									<!--  <b-col md="6">
										<b-row class="py-3 align-items-center">
										<b-col sm="4" class="text-left">
										<label class="fw-medium mb-0 mt-2" for="nname2"
										>Address<span class="text-danger">*</span> :</label
										>
										</b-col>
										<b-col sm="8">
										<b-form-group>
										<input :class="{'venue-custom-input form-control':true, [overview_form.address!='' ? 'is-valid':'' ]:true}"
										id="v_locations1"
										type="text"
										placeholder="Enter venue address"
										v-model="overview_form.address"
										
										/>
										</b-form-group>
										<span class="text-danger" v-if="IsplaceChange"
										>Please select venue location</span
										>
										</b-col>
										</b-row>
									</b-col> -->
								</b-row>
							</div>
							<h6 class="custom-text"><span>More Information</span></h6>
							<div class="p-2">
								
								
								<b-row>
									<b-col md="12">
										<b-row class="py-3 align-items-center">
											<b-col sm="2" class="text-left mt-2">
												<label class="fw-medium mb-0" for="uname2">
													Distance From Airport:</label
												>
											</b-col>
											<b-col sm="10">
												<b-form-group>
													<b-form-input :class="{'venue-custom-input form-control':true, [overview_form.dist_airport!=null ? 'is-valid':'' ]:true}"
													id="dist_airport"
													type="text"
													placeholder="Enter distance from airport"
													v-model="overview_form.dist_airport"
													>
													</b-form-input>
												</b-form-group>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
								<b-row>
									<b-col md="12">
										<b-row class="py-3 align-items-center">
											<b-col sm="2" class="text-left">
												<label class="fw-medium mb-0 mt-2" for="uname2">
													NearBy Attraction :</label
												>
											</b-col>
											<b-col sm="10">
												<b-form-group>
													<b-form-textarea :class="{'venue-custom-input form-control':true, [overview_form.near_by!=null ? 'is-valid':'' ]:true}"
													id="near_by"
													placeholder="Enter nearby attration"
													v-model="overview_form.near_by"
													></b-form-textarea>
												</b-form-group>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
								
								
								
								<b-row>
									<b-col md="6">
										
										<label class="fw-medium mb-0 mt-2" for="uname2">
											About:</label
										>
										<vue-editor v-model="overview_form.details_bio" class="venue-custom-input"></vue-editor>
										
										<!--  <b-row class="py-3 align-items-center">
											<b-col sm="2" class="text-left">
											<label class="fw-medium mb-0 mt-2" for="uname2">
											More:</label
											>
											</b-col>
											<b-col sm="10">
											<b-form-group>
											<b-form-textarea :class="{'venue-custom-input form-control':true, [overview_form.details_bio!=null ? 'is-valid':'' ]:true}"
											id="details_bio"
											placeholder="Enter More Details"
											v-model="overview_form.details_bio"
											></b-form-textarea>
											</b-form-group>
											</b-col>
										</b-row>-->
									</b-col>
									
									<!-- New -->
									<b-col md="6">
										<label class="fw-medium mb-0 mt-2" for="uname2">
											Cancellation Policy:</label
										>
										<vue-editor v-model="overview_form.cancellation_policy" class="venue-custom-input"></vue-editor>
										
										<!--<b-row class="py-3 align-items-center">
											<b-col sm="2" class="text-left">
											<label class="fw-medium mb-0 mt-2" for="uname2">
											Cancellation Policy :</label
											>
											</b-col>
											<b-col sm="10">
											<b-form-group>
											<b-form-textarea  class="venue-custom-input"
											id="cancellation_policy"
											type="text"
											placeholder="Enter Cancellation Policy"
											v-model="overview_form.cancellation_policy"
											>
											</b-form-textarea>
											</b-form-group>
											</b-col>
										</b-row>-->
									</b-col>
									<b-col md="6">
										<label class="fw-medium mb-0 mt-2" for="uname2">
											Facility Restrictions:</label
										>
										<vue-editor v-model="overview_form.facility_restrictions" class="venue-custom-input"></vue-editor>
										<!--<b-row class="py-3 align-items-center">
											<b-col sm="2" class="text-left">
											<label class="fw-medium mb-0 mt-2" for="uname2">
											Facility Restrictions :</label
											>
											</b-col>
											<b-col sm="10">
											<b-form-group>
											<b-form-textarea  class="venue-custom-input"
											id="facility_restrictions"
											type="text"
											placeholder="Enter Facility Restrictions"
											v-model="overview_form.facility_restrictions"
											>
											</b-form-textarea>
											</b-form-group>
											</b-col>
										</b-row>-->
									</b-col>
									
									<b-col md="6">
										<label class="fw-medium mb-0 mt-2" for="uname2">
											Additional Information:</label
										>
										<vue-editor v-model="overview_form.more_info" class="venue-custom-input"></vue-editor>
										<!-- <b-row class="py-3 align-items-center">
											<b-col sm="2" class="text-left">
											<label class="fw-medium mb-0 mt-2" for="uname2">
											Additional Information:</label
											>
											</b-col>
											<b-col sm="10">
											<b-form-group>
											<b-form-textarea class="venue-custom-input"
											id="more_info"
											type="text"
											placeholder="Enter Additional Information"
											v-model="overview_form.more_info"
											>
											</b-form-textarea>
											</b-form-group>
											</b-col>
										</b-row>-->
									</b-col>
									
									<!--  <b-col md="12">
										<b-row class="py-3 align-items-center">
										<b-col sm="2" class="text-left">
										<label class="fw-medium mb-0 mt-2" for="uname2">
										Other Details:</label
										>
										</b-col>
										<b-col sm="10">
										<b-form-group>
										<b-form-textarea :class="{'venue-custom-input form-control':true, [overview_form.more_info!=null ? 'is-valid':'' ]:true}"
										id="more_info"
										type="text"
										placeholder="Enter more details"
										v-model="overview_form.more_info"
										>
										</b-form-textarea>
										</b-form-group>
										</b-col>
										</b-row>
									</b-col> -->
								</b-row>
							</div>
						</b-form>
					</tab-content>


					<tab-content
					title="Time Shift"
					icon="mdi mdi-timetable"
					:before-change="newShiftUpdate"
					
					>
					<b-row>
			<b-col md="4" sm="12" >
				<div class="card" >
					<div class="card-header custom-card-header fixed">
						<div class="d-flex justify-content-between mb-2">
							<div> 
								{{ mshift_name ? mshift_name :"Morning Shift"  }}
							</div>
							<div>
								
							</div>
						</div>
					</div>
					<div class="card-body " >
						<!-- <div> 
							<input type="text" class="popover-valid-custom-time-picker mb-2" v-model="mshift_name" placeholder="Enter Shift name" >
						</div> -->
                        <div class="d-flex justify-content-between ">
						<div> 
							<input type="time" :class="{[isValidTime('morning','11:59:00',mst,'L')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" :state="isValidTime('morning','11:59:00',mst,'L')" v-model="mst"  >
							<!-- <b-form-timepicker  name="example-input-1" id="example-input-1"    size="sm" v-model="mst" placeholder="Time From" locale="en"></b-form-timepicker>  -->
						</div>
						<div>TO</div>
						<div> 
							<input type="time" :class="{[isValidTime('morning',met,mst,'L')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" :state="isValidTime('morning',met,mst,'L')" v-model="met"  >
							<!-- 	<b-form-timepicker size="sm" v-model="met" :state="isValidTime('morning',met,mst,'R')" @input="get_data('morning')"  placeholder="Time To" locale="en" ></b-form-timepicker> -->
							
						</div>
						
                    </div>
                    <b-form-checkbox v-model="card_enabled" name="check-button" switch class="mt-3">
     Publish
    </b-form-checkbox>
                  
						<!-- <div class="mt-3">
							<b-button @click="onClose" size="sm" variant="danger" class="mr-2">Cancel</b-button>
							<b-button  size="sm" variant="primary" @click="get_data('Morning')"> 
							<b-spinner small v-if="morningsave"></b-spinner> Save</b-button>
						</div> -->
					</div>
				</div>
			</b-col>		
			<b-col md="4" sm="12" >
				<div class="card" >
					<div class="card-header custom-card-header fixed">
						<div class="d-flex justify-content-between mb-2">
							<div> 
								{{ ashift_name ? ashift_name :"Afternoon Shift"  }}
							</div>
						</div>
					</div>
					<div class="card-body " >
                        <div class="d-flex justify-content-between ">
						<div> 
                            <input type="time" :class="{[isValidTime1('afternoon',card_enabled==true ? met : '23:59',ast,'L')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }"  v-model="ast" :state="isValidTime1('afternoon',card_enabled==true ? met : '23:59',ast,'L')" >
								
						</div>
						<div>TO</div>
						<div> 
							<input type="time" :class="{[isValidTime1('afternoon',aet,ast,'R')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" v-model="aet" :state="isValidTime1('afternoon',aet,ast,'R')" >
							
						</div>
						
                    </div>
                    <b-form-checkbox v-model="card_enabled1" name="check-button" switch class="mt-3">
     Publish
    </b-form-checkbox>
					</div>
				</div>
			</b-col>
			<b-col md="4" sm="12" >
				<div class="card" >
					<div class="card-header custom-card-header fixed">
						<div class="d-flex justify-content-between mb-2">
							<div> 
								{{ eshift_name ? eshift_name :"Evening Shift"  }}
							</div>
							
						</div>
					</div>
					<div class="card-body " >
                        <div class="d-flex justify-content-between ">
						<div> 
                            <input type="time" 	:class="{[isValidTime2('evening',est,card_enabled1==true ? aet : '00:00:00','L')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" v-model="est" :state="isValidTime2('evening',est,card_enabled1==true ? aet : '00:00:00','L')">
								
						</div>
						<div>TO</div>
						<div> 
							<input type="time" :class="{[isValidTime2('evening',eet,est,'R')== true ? 'popover-valid-custom-time-picker' : 'popover-custom-time-picker']:true }" v-model="eet" :state="isValidTime2('evening',eet,est,'R')">
									
						</div>
						
                    </div>
                    <b-form-checkbox v-model="card_enabled2" name="check-button" switch class="mt-3">
     Publish
    </b-form-checkbox>
					</div>
				</div>
			</b-col>
		</b-row>

<!--<newShift v-on:child-method="updateParent"/>--->

					</tab-content>


					<!-- Timseshift Top Selection   :before-change="next_shift_timing" -->
					<tab-content
					title="Price Shift"
					icon="mdi mdi-timetable"
					:before-change="next_shift_timing"
					>
						
						<!-- <b-row class="mx-auto align-items-center mb-2">
							<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="uname2"> Shift Type: </label>
							</b-col>
							<b-col sm="3">
							<b-form-group>
							<b-form-select class="venue-custom-input"
							placeholder="Select Shift"
							:options="shift_types"
							v-model="shift_types_selected"
							@change="set_shift_hour(shift_types_selected)"
							style="background: unset"
							></b-form-select>
							
							</b-form-group>
							</b-col>
						</b-row>-->
						
						<TimeShift :name="child_venue_id" :todoParent="todo"  :childName="checkparent" @onHappy="doSomething"/>
						
						<!--<b-row>
							<b-col
							class="d-flex align-items-stretch"
							cols="12"
							lg="3"
							md="12"
							sm="3"
							></b-col>
							<b-col
							class="d-flex align-items-stretch"
							cols="12"
							lg="2"
							md="12"
							sm="2"
							>
							<div class="w-100 p-1 align-self-center">
							<div class="p-2">
							<label class="fw-medium" for="lname2">Shift Type:</label>
							</div>
							</div>
							</b-col>
							<b-col
							class="d-flex align-items-stretch"
							cols="12"
							lg="3"
							md="12"
							sm="4"
							>
							<div class="w-100 p-1">
							<div class="p-2">
							<b-form-select
							placeholder="Select Shift"
							:options="shift_types"
							v-model="shift_types_selected"
							@change="set_shift_hour(shift_types_selected)"
							>
							</b-form-select>
							<br /><br />
							<span
							v-if="shift_types_selected_status === true"
							id="e_message"
							>Please select shift type.</span
							>
							</div>
							</div>
							</b-col>
							</b-row>
							<div
							class="d-flex flex-column-reverse"
							v-if="shift_types_selected !== null && shift_types_selected == 1"
							>
							<div v-for="(shift_times, index) in shift_time" :key="index">
							<b-row id="pd">
							<b-col
							class="d-flex align-items-stretch"
							cols="12"
							lg="1"
							md="12"
							sm="2"
							>
							<div class="w-100 p-1 d-flex align-self-center">
							<div class="p-2">
							<label class="fw-medium" for="lname2">Date:</label>
							</div>
							</div>
							</b-col>
							<b-col
							class="d-flex align-items-stretch"
							cols="12"
							lg="3"
							md="12"
							sm="4"
							>
							<div class="w-100 p-1">
							<div class="p-2">
							<b-form-datepicker
							placeholder="Start Date"
							v-model="shift_times.shift_sdate"
							:min="today_date"
							:max="shift_times.shift_edate"
							:date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
							}"
							></b-form-datepicker>
							</div></div
							></b-col>
							<b-col
							class="d-flex align-items-stretch"
							cols="12"
							lg="3"
							md="12"
							sm="4"
							>
							<div class="w-100 p-1">
							<div class="p-2">
							<b-form-datepicker
							placeholder="End Date"
							v-model="shift_times.shift_edate"
							:min="shift_times.shift_sdate"
							:date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
							}"
							@input="
                            change_end_date(index, shift_times.shift_edate)
							"
							></b-form-datepicker>
							</div>
							</div>
							</b-col>
							<b-col
							class="d-flex align-items-stretch"
							cols="12"
							lg="1"
							md="12"
							sm="2"
							>
							<div class="w-100 p-1 align-self-center">
							<div class="p-2">
							<label class="fw-medium" for="lname2">Shift:</label>
							</div>
							</div>
							</b-col>
							<b-col
							class="d-flex align-items-stretch"
							cols="12"
							lg="3"
							md="12"
							sm="4"
							>
							<div class="w-100 p-1">
							<div class="p-2">
							<b-form-select
							placeholder="Select Shift"
							v-model="shift_times.shift"
							:options="shift_options"
							@change="show_times(index, shift_times.shift)"
							>
							</b-form-select>
							</div>
							</div>
							</b-col>
							<b-col
							class="d-flex align-items-stretch"
							cols="12"
							lg="1"
							md="12"
							sm="2"
							>
							<div class="w-100 p-1">
							<div class="p-2">
							<b-button
							id="add_r"
							@click="remove(index)"
							v-show="index != 0"
							>
							x
							</b-button>
							<b-button
							id="add_r"
							v-show="index == 0"
							variant="primary"
							@click="addMore()"
							>
							+
							</b-button>
							</div>
							</div>
							</b-col>
							</b-row>
							<b-row id="pd">
							<b-col
							class="d-flex align-items-stretch"
							cols="12"
							lg="3"
							md="12"
							sm="12"
							>
							<div
							class="
							w-100
							p-1
							d-flex
							justify-content-lg-end
							justify-content-sm-center
							justify-content-xl-end
							justify-content-md-center
							"
							>
							<div class="p-2">
							<b>Per shift price</b><br />
							<b-input-group prepend="₹" append=".00">
							<b-form-input
                            id="price"
                            placeholder="Enter Price"
                            v-on:keyup="priceState(index)"
                            v-model="shift_times.price"
							></b-form-input>
							</b-input-group>
							</div>
							</div>
							</b-col>
							
							<b-col
							class="d-flex align-items-stretch"
							cols="12"
							lg="3"
							md="12"
							sm="12"
							>
							<div class="w-100 align-self-center">
							<div class="">
							<span id="e_message" v-if="shift_times.error_m">
							{{ shift_times.error_s }}</span
							>
							<br v-if="shift_times.error_m" />
							<span id="e_message" v-if="shift_times.shift_edate_m">
							{{ shift_times.error_end_date }}</span
							>
							<br v-if="shift_times.shift_edate_m" />
							<span
							id="e_message"
							v-if="shift_times.error_shift_select"
							>
							{{ shift_times.error_shift_m }}</span
							>
							<br v-if="shift_times.error_shift_select" />
							<span id="e_message" v-if="shift_times.price_status">
							{{ shift_times.price_message }}</span
							>
							</div>
							</div>
							</b-col>
							
							<b-col
							class="d-flex align-items-stretch justify-content-end"
							cols="12"
							lg="6"
							md="12"
							sm="12"
							>
							<div
							class="d-flex align-items-center justify-content-end"
							v-if="shift_times.shift !== null"
							>
							<div class="mt-2">
							<label class="fw-medium" for="st">Start:&nbsp;</label>
							</div>
							<div class="p-2">
							<b-form-select
							id="st"
							v-model="shift_times.shift_stime"
							:options="shift_times.timelist_from"
							@change="check_set_time(index)"
							></b-form-select>
							</div>
							
							<div class="mt-2">
							<label class="fw-medium" for="et">End:&nbsp;</label>
							</div>
							<div class="p-2">
							<b-form-select
							id="et"
							v-model="shift_times.shift_etime"
							:options="shift_times.timelist_end"
							@change="check_set_time(index)"
							></b-form-select>
							</div>
							</div>
							</b-col>
							</b-row>
							<hr v-show="index != 0" />
							</div>
							</div>
							<div class="time-selector" v-if="shift_types_selected == 2">
							<b-card-group deck class="shift-card">
							<b-card
							border-variant="secondary"
							header="Morning Shift"
							header-bg-variant="secondary"
							header-text-variant="white"
							align="center"
							class="border border-secondary morning_shift_card"
							>
							<div class="d-flex align-items-center">
							<div class="mt-2" v-if="this.shift_wise_new.morning_publish_status == '1'">
							<label class="fw-medium" for="st">Start:&nbsp;</label>
							</div>
							<div class="p-2" v-if="this.shift_wise_new.morning_publish_status == '1'">
							<b-form-select
							id="st"
							v-model="$v.shift_wise_new.morning_start_time.$model"
							:options="shift_wise_new.morning_timelist_from"
							@change="updateShiftsTimeMorning()"
							></b-form-select>
							<div class="error" v-if="!$v.shift_wise_new.morning_start_time.required">{{ shift_wise_new.start_time_required_message }}</div>
							</div>
							
							<div class="mt-2" v-if="this.shift_wise_new.morning_publish_status == '1'">
							<label class="fw-medium" for="et">End:&nbsp;</label>
							</div>
							<div class="p-2" v-if="this.shift_wise_new.morning_publish_status == '1'">
							<b-form-select
							id="et"
							v-model="$v.shift_wise_new.morning_end_time.$model"
							:options="shift_wise_new.morning_timelist_end"
							@change="updateShiftsTimeMorning()"
							></b-form-select>
							<div class="error" v-if="!$v.shift_wise_new.morning_start_time.required">{{ shift_wise_new.end_time_required_message }}</div>
							</div>
							<div class="mt-2 ml-4">
							<label class="fw-medium" for="morning_publish">Publish:&nbsp;</label>
							</div>
							<div class="p-2">
							<b-form-checkbox
							id="morning_publish"
							v-model="shift_wise_new.morning_publish_status"
							unchecked-value="0"
							@input="morningPublishChange()"
							value="1">
							</b-form-checkbox>
							</div>
							</div>
							<hr>
							<br>
							<div class="border border-secondary shift-box mb-4" v-for="(shift,index) in $v.shift_wise_new.morning_shifts_arr.$each.$iter" :key="index">
							<b-row v-bind:class="((shift.end_date != '') && (shift.end_date < today_date)) ? 'disable_past_shifts' : '' ">
							<b-col md="4" class="col">
							<b-form-datepicker
							class="date-picker"
							placeholder="Start Date"
							:min="today_date"
							:max="shift.end_date.$model"
							v-model="shift.start_date.$model"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							:date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
							}"
							></b-form-datepicker>
							<div class="error" v-if="!shift.start_date.required">{{ shift_wise_new.start_date_required_message }}</div>
							</b-col>
							<b-col md="4" class="col">
							<b-form-datepicker
							class="date-picker"
							placeholder="End Date"
							v-model="shift.end_date.$model"
							:min="shift.start_date.$model"
							@input="sortShiftsDatesMorning"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							:date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
							}"
							></b-form-datepicker>
							<div class="error" v-if="!shift.end_date.required">{{ shift_wise_new.end_date_required_message }}</div>
							</b-col>
							<b-col md="4" class="col">
							<b-form-input
							placeholder="Rs. Price"
							v-model="shift.price.$model"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))" 
							></b-form-input>
							<div class="error" v-if="!shift.price.required">{{ shift_wise_new.price_required_message }}</div>
							<div class="error" v-else-if="!shift.price.numeric">{{ shift_wise_new.price_numeric_message }}</div>
							</b-col>
							<b-col md="12" class="col text-right">
							<b-button
							id="add_r"
							@click="removeShiftMorning(index)"
							>x
							</b-button>
							</b-col>
							</b-row>
							</div>
							
							<div class="border border-secondary shift-box mb-4" v-for="(shift,index) in shift_wise_new.morning_shifts_disabled_arr" :key="'d_' + index">
							<b-row v-bind:class="((shift.end_date != '') && (shift.end_date < today_date)) ? 'disable_past_shifts' : '' ">
							<b-col md="4" class="col">
							<b-form-datepicker
							class="date-picker"
							placeholder="Start Date"
							:min="today_date"
							:max="shift.end_date"
							v-model="shift.start_date"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							:date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
							}"
							></b-form-datepicker>
							</b-col>
							<b-col md="4" class="col">
							<b-form-datepicker
							class="date-picker"
							placeholder="End Date"
							v-model="shift.end_date"
							:min="shift.start_date"
							@input="sortShiftsDatesMorning"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							:date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
							}"
							></b-form-datepicker>
							</b-col>
							<b-col md="4" class="col">
							<b-form-input
							placeholder="Rs. Price"
							v-model="shift.price"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))" 
							></b-form-input>
							</b-col>
							<b-col md="12" class="col text-right">
							<b-button
							id="add_r"
							@click="removeShiftMorning('d_' + index)"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							>x
							</b-button>
							</b-col>
							</b-row>
							</div>
							
							<br>
							<b-row>
							<b-col cols="10"></b-col>
							<b-col cols="2">
							<b-button
							id="add_r"
							variant="primary"
							@click="addMoreShiftMorning()">
							+
							</b-button>
							</b-col>
							</b-row>
							</b-card>
							
							<b-card
							border-variant="secondary"
							header="Afternoon Shift"
							header-bg-variant="secondary"
							header-text-variant="white"
							align="center"
							class="border border-secondary "
							>
							<div class="d-flex align-items-center">
							<div class="mt-2" v-if="this.shift_wise_new.afternoon_publish_status == '1'">
							<label class="fw-medium" for="st">Start:&nbsp;</label>
							</div>
							<div class="p-2" v-if="this.shift_wise_new.afternoon_publish_status == '1'">
							<b-form-select
							id="st"
							v-model="$v.shift_wise_new.afternoon_start_time.$model"
							:options="shift_wise_new.afternoon_timelist_from"
							@change="updateShiftsTimeAfternoon()"
							></b-form-select>
							<div class="error" v-if="!$v.shift_wise_new.afternoon_start_time.required">{{ shift_wise_new.start_time_required_message }}</div>
							</div>
							
							<div class="mt-2" v-if="this.shift_wise_new.afternoon_publish_status == '1'">
							<label class="fw-medium" for="et">End:&nbsp;</label>
							</div>
							<div class="p-2" v-if="this.shift_wise_new.afternoon_publish_status == '1'">
							<b-form-select
							id="et"
							v-model="$v.shift_wise_new.afternoon_end_time.$model"
							:options="shift_wise_new.afternoon_timelist_end"
							@change="updateShiftsTimeAfternoon()"
							></b-form-select>
							<div class="error" v-if="!$v.shift_wise_new.afternoon_end_time.required">{{ shift_wise_new.start_time_required_message }}</div>
							</div>
							<div class="mt-2 ml-4">
							<label class="fw-medium" for="afternoon_publish">Publish:&nbsp;</label>
							</div>
							<div class="p-2">
							<b-form-checkbox
							id="afternoon_publish"
							v-model="shift_wise_new.afternoon_publish_status"
							unchecked-value="0"
							@input="afternoonPublishChange()"
							value="1">
							</b-form-checkbox>
							</div>
							</div>
							<hr>
							<br>
							<div class="border border-secondary shift-box mb-4" v-for="(shift,index) in $v.shift_wise_new.afternoon_shifts_arr.$each.$iter" :key="index">
							<b-row v-bind:class="((shift.end_date != '') && (shift.end_date < today_date)) ? 'disable_past_shifts' : '' ">
							<b-col md="4" class="col">
							<b-form-datepicker
							class="date-picker"
							placeholder="Start Date"
							:min="today_date"
							:max="shift.end_date.$model"
							v-model="shift.start_date.$model"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							:date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
							}"
							></b-form-datepicker>
							<div class="error" v-if="!shift.start_date.required">{{ shift_wise_new.start_date_required_message }}</div>
							</b-col>
							<b-col md="4" class="col">
							<b-form-datepicker
							class="date-picker"
							placeholder="End Date"
							:min="shift.start_date.$model"
							v-model="shift.end_date.$model"
							@input="sortShiftsDatesAfternoon"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							:date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
							}"
							></b-form-datepicker>
							<div class="error" v-if="!shift.end_date.required">{{ shift_wise_new.end_date_required_message }}</div>
							</b-col>
							<b-col md="4" class="col">
							<b-form-input
							placeholder="Rs. Price"
							v-model="shift.price.$model"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							></b-form-input>
							<div class="error" v-if="!shift.price.required">{{ shift_wise_new.price_required_message }}</div>
							<div class="error" v-else-if="!shift.price.numeric">{{ shift_wise_new.price_numeric_message }}</div>
							</b-col>
							<b-col md="12" class="col text-right">
							<b-button
							id="add_r"
							@click="removeShiftAfternoon(index)"
							>x
							</b-button>
							</b-col>
							</b-row>
							</div>
							
							<div class="border border-secondary shift-box mb-4" v-for="(shift,index) in shift_wise_new.afternoon_shifts_disabled_arr" :key="'d_' + index">
							<b-row v-bind:class="((shift.end_date != '') && (shift.end_date < today_date)) ? 'disable_past_shifts' : '' ">
							<b-col md="4" class="col">
							<b-form-datepicker
							class="date-picker"
							placeholder="Start Date"
							:min="today_date"
							:max="shift.end_date"
							v-model="shift.start_date"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							:date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
							}"
							></b-form-datepicker>
							</b-col>
							<b-col md="4" class="col">
							<b-form-datepicker
							class="date-picker"
							placeholder="End Date"
							:min="shift.start_date"
							v-model="shift.end_date"
							@input="sortShiftsDatesAfternoon"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							:date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
							}"
							></b-form-datepicker>
							</b-col>
							<b-col md="4" class="col">
							<b-form-input
							placeholder="Rs. Price"
							v-model="shift.price"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							></b-form-input>
							</b-col>
							<b-col md="12" class="col text-right">
							<b-button
							id="add_r"
							@click="removeShiftAfternoon('d_' + index)"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							>x
							</b-button>
							</b-col>
							</b-row>
							</div>
							
							<br>
							<b-row>
							<b-col cols="10"></b-col>
							<b-col cols="2">
							<b-button
							id="add_r"
							variant="primary"
							@click="addMoreShiftAfternoon()"
							>
							+
							</b-button>
							</b-col>
							</b-row>
							</b-card>
							
							<b-card
							border-variant="secondary"
							header="Evening Shift"
							header-bg-variant="secondary"
							header-text-variant="white"
							align="center"
							class="border border-secondary "
							>
							<div class="d-flex align-items-center">
							<div class="mt-2" v-if="this.shift_wise_new.evening_publish_status == '1'">
							<label class="fw-medium" for="st">Start:&nbsp;</label>
							</div>
							<div class="p-2" v-if="this.shift_wise_new.evening_publish_status == '1'">
							<b-form-select
							id="st"
							v-model="$v.shift_wise_new.evening_start_time.$model"
							:options="shift_wise_new.evening_timelist_from"
							@change="updateShiftsTimeEvening()"
							></b-form-select>
							<div class="error" v-if="!$v.shift_wise_new.evening_start_time.required">{{ shift_wise_new.start_time_required_message }}</div>
							</div>
							
							<div class="mt-2" v-if="this.shift_wise_new.evening_publish_status == '1'">
							<label class="fw-medium" for="et">End:&nbsp;</label>
							</div>
							<div class="p-2" v-if="this.shift_wise_new.evening_publish_status == '1'">
							<b-form-select
							id="et"
							v-model="$v.shift_wise_new.evening_end_time.$model"
							:options="shift_wise_new.evening_timelist_end"
							@change="updateShiftsTimeEvening()"
							></b-form-select>
							<div class="error" v-if="!$v.shift_wise_new.evening_end_time.required">{{ shift_wise_new.start_time_required_message }}</div>
							</div>
							<div class="mt-2 ml-4">
							<label class="fw-medium" for="evening_publish">Publish:&nbsp;</label>
							</div>
							<div class="p-2">
							<b-form-checkbox
							id="evening_publish"
							v-model="shift_wise_new.evening_publish_status"
							unchecked-value="0"
							@input="eveningPublishChange()"
							value="1">
							</b-form-checkbox>
							</div>
							</div>
							<hr>
							<br>
							<div class="border border-secondary shift-box mb-4" v-for="(shift,index) in $v.shift_wise_new.evening_shifts_arr.$each.$iter" :key="index">
							<b-row v-bind:class="((shift.end_date != '') && (shift.end_date < today_date)) ? 'disable_past_shifts' : '' ">
							<b-col md="4" class="col">
							<b-form-datepicker
							class="date-picker"
							placeholder="Start Date"
							v-model="shift.start_date.$model"
							:min="today_date"
							:max="shift.end_date.$model"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							:date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
							}"
							></b-form-datepicker>
							<div class="error" v-if="!shift.start_date.required">{{ shift_wise_new.start_date_required_message }}</div>
							</b-col>
							<b-col md="4" class="col">
							<b-form-datepicker
							class="date-picker"
							placeholder="End Date"
							:min="shift.start_date.$model"
							v-model="shift.end_date.$model"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							@input="sortShiftsDatesEvening"
							:date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
							}"
							></b-form-datepicker>
							<div class="error" v-if="!shift.end_date.required">{{ shift_wise_new.end_date_required_message }}</div>
							</b-col>
							<b-col md="4" class="col">
							<b-form-input
							placeholder="Rs. Price"
							v-model="shift.price.$model"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							></b-form-input>
							<div class="error" v-if="!shift.price.required">{{ shift_wise_new.price_required_message }}</div>
							<div class="error" v-else-if="!shift.price.numeric">{{ shift_wise_new.price_numeric_message }}</div>
							</b-col>
							<b-col md="12" class="col text-right">
							<b-button
							id="add_r"
							@click="removeShiftEvening(index)"
							>x
							</b-button>
							</b-col>
							</b-row>
							</div>
							
							<div class="border border-secondary shift-box mb-4" v-for="(shift,index) in shift_wise_new.evening_shifts_disabled_arr" :key="'d_' + index">
							<b-row v-bind:class="((shift.end_date != '') && (shift.end_date < today_date)) ? 'disable_past_shifts' : '' ">
							<b-col md="4" class="col">
							<b-form-datepicker
							class="date-picker"
							placeholder="Start Date"
							v-model="shift.start_date"
							:min="today_date"
							:max="shift.end_date"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							:date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
							}"
							></b-form-datepicker>
							</b-col>
							<b-col md="4" class="col">
							<b-form-datepicker
							class="date-picker"
							placeholder="End Date"
							:min="shift.start_date"
							v-model="shift.end_date"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							@input="sortShiftsDatesEvening"
							:date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
							}"
							></b-form-datepicker>
							</b-col>
							<b-col md="4" class="col">
							<b-form-input
							placeholder="Rs. Price"
							v-model="shift.price"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							></b-form-input>
							</b-col>
							<b-col md="12" class="col text-right">
							<b-button
							id="add_r"
							@click="removeShiftEvening('d_' + index)"
							:disabled="((shift.end_date != '') && (shift.end_date < today_date))"
							>x
							</b-button>
							</b-col>
							</b-row>
							</div>
							
							<br>
							<b-row>
							<b-col cols="10"></b-col>
							<b-col cols="2">
							<b-button
							id="add_r"
							variant="primary"
							@click="addMoreShiftEvening()">
							+
							</b-button>
							</b-col>
							</b-row>
							</b-card>
							</b-card-group>
						</div>-->
					</tab-content>
					<!-- More Option Top selection  :before-change="next_moredetails" -->
					<tab-content
					title="More details"
					icon="mdi mdi-file-document-box"
					:before-change="next_moredetails"
					>
						<b-form>
							<div class="mb-3">
								<div class="p-2">
									<!--  <div class="text-center">
										<h4 class="">
										<u> Meeting Space </u>
										</h4>
									</div> -->
									
									<b-row>
										<b-col lg="6" md="6" sm="12">
											<h6 class="custom-text"><span>Meeting Space </span></h6>
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Meeting Space<span class="text-danger">*</span>
														:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														id="metting_space"
														placeholder="Enter meeting space"
														v-model="$v.more_detail.metting_space.$model"
														:state="validateStateMoreD('metting_space')"
														></b-form-input>
														<b-form-invalid-feedback
														id="metting_space"
														v-if="$v.more_detail.metting_space.$error"
														>
															<div
															v-if="!$v.more_detail.metting_space.required"
															>
																Please enter metting space
															</div>
															<div v-if="!$v.more_detail.metting_space.numeric">
																Please enter valid metting space
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<h6 class="custom-text"><span>Room Details  </span></h6>
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Type of room<span class="text-danger">*</span>
														:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-select class="venue-custom-input"
														id="type_of"
														:options="type_of_data"
														v-model="$v.more_detail.type_of.$model"
														:state="validateStateMoreD('type_of')"
														></b-form-select>
														<b-form-invalid-feedback
														id="type_of"
														v-if="$v.more_detail.type_of.$error"
														>
															<div v-if="!$v.more_detail.type_of.required">
																Please select room type
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
									</b-row>
								</div>
								<div class="p-2">
									<!--  <div class="text-center">
										<h4 class="">
										<u> Room Details </u>
										</h4>
									</div> -->
									
								</div>
								<div class="p-2">
									<!-- <div class="text-center">
										<h4 class="">
										<u> Size of room </u>
										</h4>
									</div> -->
									<h6 class="custom-text"><span>Size of room   </span></h6>
									<b-row>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Room Size<span class="text-danger">*</span> :</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														id="room_size"
														placeholder="Enter room size"
														v-model="$v.more_detail.room_size.$model"
														:state="validateStateMoreD('room_size')"
														></b-form-input>
														<b-form-invalid-feedback
														id="room_size"
														v-if="$v.more_detail.room_size.$error"
														>
															<div v-if="!$v.more_detail.room_size.required">
																Please enter room size
															</div>
															<div v-if="!$v.more_detail.room_size.numeric">
																Please enter valid room size
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Ceiling Heignt<span class="text-danger">*</span>
														:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														id="ceiling_heignt"
														placeholder="Enter ceiling heignt"
														v-model="$v.more_detail.ceiling_heignt.$model"
														:state="validateStateMoreD('ceiling_heignt')"
														></b-form-input>
														<b-form-invalid-feedback
														id="ceiling_heignt"
														v-if="$v.more_detail.ceiling_heignt.$error"
														>
															<div
															v-if="!$v.more_detail.ceiling_heignt.required"
															>
																Please enter ceiling heignt
															</div>
															<div
															v-if="!$v.more_detail.ceiling_heignt.numeric"
															>
																Please enter valid ceiling heignt
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Room Dimension<span class="text-danger">*</span>
														:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														id="room_dimension"
														placeholder="Enter room dimension"
														v-model="$v.more_detail.room_dimension.$model"
														:state="validateStateMoreD('room_dimension')"
														></b-form-input>
														<b-form-invalid-feedback
														id="room_dimension"
														v-if="$v.more_detail.room_dimension.$error"
														>
															<div
															v-if="!$v.more_detail.room_dimension.required"
															>
																Please enter room dimension
															</div>
															<div
															v-if="!$v.more_detail.room_dimension.numeric"
															>
																Please enter valid room dimension
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
									</b-row>
								</div>
								<div class="p-2">
									<!-- div class="text-center">
										<h4 class="">
										<u>Set up type</u>
										</h4>
									</div> -->
									<h6 class="custom-text"><span>Set up type  </span></h6>
									<b-row>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														U-shape:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="u_shape"
														v-model="$v.more_detail.u_shape.$model"
														:state="validateStateMoreD('u_shape')"
														></b-form-input>
														<b-form-invalid-feedback
														id="u_shape"
														v-if="$v.more_detail.u_shape.$error"
														>
															<div v-if="!$v.more_detail.u_shape.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Banquate Round:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="banquate_round"
														v-model="$v.more_detail.banquate_round.$model"
														:state="validateStateMoreD('banquate_round')"
														></b-form-input>
														<b-form-invalid-feedback
														id="banquate_round"
														v-if="$v.more_detail.banquate_round.$error"
														>
															<div
															v-if="!$v.more_detail.banquate_round.numeric"
															>
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Cocktail Round:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="cocktail_round"
														v-model="$v.more_detail.cocktail_round.$model"
														:state="validateStateMoreD('cocktail_round')"
														></b-form-input>
														<b-form-invalid-feedback
														id="cocktail_round"
														v-if="$v.more_detail.cocktail_round.$error"
														>
															<div
															v-if="!$v.more_detail.cocktail_round.numeric"
															>
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Theater:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="theater"
														v-model="$v.more_detail.theater.$model"
														:state="validateStateMoreD('theater')"
														></b-form-input>
														<b-form-invalid-feedback
														id="theater"
														v-if="$v.more_detail.theater.$error"
														>
															<div v-if="!$v.more_detail.theater.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Classroom:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="classroom"
														v-model="$v.more_detail.classroom.$model"
														:state="validateStateMoreD('classroom')"
														></b-form-input>
														<b-form-invalid-feedback
														id="classroom"
														v-if="$v.more_detail.classroom.$error"
														>
															<div v-if="!$v.more_detail.classroom.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Boardroom:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="boardroom"
														v-model="$v.more_detail.boardroom.$model"
														:state="validateStateMoreD('boardroom')"
														></b-form-input>
														<b-form-invalid-feedback
														id="boardroom"
														v-if="$v.more_detail.boardroom.$error"
														>
															<div v-if="!$v.more_detail.boardroom.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														E-shape:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="e_shape"
														v-model="$v.more_detail.e_shape.$model"
														:state="validateStateMoreD('e_shape')"
														></b-form-input>
														<b-form-invalid-feedback
														id="e_shape"
														v-if="$v.more_detail.e_shape.$error"
														>
															<div v-if="!$v.more_detail.e_shape.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Hollow square:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="hollow_square"
														v-model="$v.more_detail.hollow_square.$model"
														:state="validateStateMoreD('hollow_square')"
														></b-form-input>
														<b-form-invalid-feedback
														id="hollow_square"
														v-if="$v.more_detail.hollow_square.$error"
														>
															<div v-if="!$v.more_detail.hollow_square.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Perimiter Seating:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="perimiter_seating"
														v-model="$v.more_detail.perimiter_seating.$model"
														:state="validateStateMoreD('perimiter_seating')"
														></b-form-input>
														<b-form-invalid-feedback
														id="perimiter_seating"
														v-if="$v.more_detail.perimiter_seating.$error"
														>
															<div
															v-if="!$v.more_detail.perimiter_seating.numeric"
															>
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Royal Conference:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="royal_conference"
														v-model="$v.more_detail.royal_conference.$model"
														:state="validateStateMoreD('royal_conference')"
														></b-form-input>
														<b-form-invalid-feedback
														id="royal_conference"
														v-if="$v.more_detail.royal_conference.$error"
														>
															<div
															v-if="!$v.more_detail.royal_conference.numeric"
															>
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														T-Shape:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="t_shape"
														v-model="$v.more_detail.t_shape.$model"
														:state="validateStateMoreD('t_shape')"
														></b-form-input>
														<b-form-invalid-feedback
														id="t_shape"
														v-if="$v.more_detail.t_shape.$error"
														>
															<div v-if="!$v.more_detail.t_shape.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Talk Show:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="talk_show"
														v-model="$v.more_detail.talk_show.$model"
														:state="validateStateMoreD('talk_show')"
														></b-form-input>
														<b-form-invalid-feedback
														id="talk_show"
														v-if="$v.more_detail.talk_show.$error"
														>
															<div v-if="!$v.more_detail.talk_show.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
									</b-row>
								</div>
							</div>
						</b-form>
					</tab-content>
					<!-- GAllery top option :before-change="next_gallery_view" -->
					<tab-content
					title="Gallery View"
					icon="mdi mdi-google-photos"
					:before-change="next_gallery_view"
					>
						
						<div class="event-tab">
							<b-tabs card>
								<b-tab title="Thumbnail" active>
									<b-row>
										<b-col cols="3" v-for="(gallary_view_days, index) in gallary_view_day"
										:key="index">
										<div class="product-box">
											
												<b-link
												class="delete-btn"
												@click="remove_images('day_light', index)"
												>
													<i class="mdi mdi-delete-circle"></i>
												</b-link>
												<div class="product-image">
													<video
													v-if="
													gallary_view_days.file_extension === 'mp4' ||
													gallary_view_days.file_extension === 'mkv'
													"
													class="embed-responsive-item"
													controls
													:src="gallary_view_days.attachment"
													:type="'video/' + gallary_view_days.file_extension"
													></video>
													<img
													:src="gallary_view_days.attachment"
													v-if="
													gallary_view_days.file_extension === 'png' ||
													gallary_view_days.file_extension === 'jpeg' ||
													gallary_view_days.file_extension === 'jpg' ||
													gallary_view_days.file_extension === 'svg'
													"
													/>
												</div>
												<div class="img-overlay">
													<i class="mdi mdi-pencil editoption" id="popover-button-variant" ></i>
													
      <div class="text"> <textarea  v-model="gallerydesc[index]" >gallary_view_days.description</textarea>
		
		<!-- <i class="mdi mdi-pencil cursor-pointer"></i>  {{ gallary_view_days.description || " Add description"}} -->
		
		</div>
		<button class="btn btn-sm btn-primary btn-extra" @click="saveGallerydesc(gallary_view_days.id,index)">Save</button>
    </div>
											</div>
										</b-col>
									</b-row>


									

								<!-- <carousel
									v-if="gallary_view_day && gallary_view_day.length"
									class="slider_event"
									:nav="true"
									:items="3"
									:dots="false"
									:mouseDrag="true"
									:touchDrag="true"
									:smartSpeed="800"
									:margin="10"
									:responsive="{
									0: { items: 1 },
									575: { items: 2 },
									991: { items: 3 },
									1199: { items: 3 },
									}"
									:key="day_light"
									>
									<div
										v-for="(gallary_view_days, index) in gallary_view_day"
										:key="index"
										>
											<div class="product-box">
												<b-link
												class="delete-btn"
												@click="remove_images('day_light', index)"
												>
													<i class="mdi mdi-delete-circle"></i>
												</b-link>
												<div class="product-image">
													<video
													v-if="
													gallary_view_days.file_extension === 'mp4' ||
													gallary_view_days.file_extension === 'mkv'
													"
													class="embed-responsive-item"
													controls
													:src="gallary_view_days.attachment"
													:type="'video/' + gallary_view_days.file_extension"
													></video>
													<img
													:src="gallary_view_days.attachment"
													v-if="
													gallary_view_days.file_extension === 'png' ||
													gallary_view_days.file_extension === 'jpeg' ||
													gallary_view_days.file_extension === 'jpg' ||
													gallary_view_days.file_extension === 'svg'
													"
													/>
												</div>
											</div>
										</div>
									</carousel> -->
									<div class="no-data" v-if="gallary_view_day.length === 0">
										<p>No images and video are here.</p>
									</div>
								</b-tab>
								<b-tab title="Banner">
									<b-row>
										<b-col cols="3" v-for="(gallary_view_nights, index) in gallary_view_night"
										:key="index">
										<div class="product-box">
												<b-link
												class="delete-btn"
												@click="remove_images('day_night', index)"
												>
													<i class="mdi mdi-delete-circle"></i>
												</b-link>
												<div class="product-image">
													<video
													v-if="
													gallary_view_nights.file_extension === 'mp4' ||
													gallary_view_nights.file_extension === 'mkv'
													"
													class="embed-responsive-item"
													controls
													:src="gallary_view_nights.attachment"
													:type="'video/' + gallary_view_nights.file_extension"
													></video>
													<img
													:src="gallary_view_nights.attachment"
													v-if="
													gallary_view_nights.file_extension === 'png' ||
													gallary_view_nights.file_extension === 'jpeg' ||
													gallary_view_nights.file_extension === 'jpg' ||
													gallary_view_nights.file_extension === 'svg'
													"
													/>
												</div>
												<div class="img-overlay">
													<i class="mdi mdi-delete-circle editoption"></i>
      <div class="text">
		<textarea  v-model="gallerydesc[index]" ></textarea>
		<!--{{ gallary_view_nights.description || " Add description"}} --> </div>

		<button class="btn btn-sm btn-primary btn-extra" @click="saveGallerydesc(gallary_view_nights.id,index)">Save</button>
  
		
		<!-- <i class="mdi mdi-pencil cursor-pointer"></i>  {{ gallary_view_days.description || " Add description"}} -->
		</div>
											</div>
										</b-col>
									</b-row>
									<!--<carousel
									v-if="gallary_view_night && gallary_view_night.length"
									class="slider_event"
									:nav="true"
									:items="3"
									:dots="false"
									:mouseDrag="true"
									:touchDrag="true"
									:smartSpeed="800"
									:margin="10"
									:responsive="{
									0: { items: 1 },
									575: { items: 2 },
									991: { items: 3 },
									1199: { items: 3 },
									}"
									:key="day_night"
									>
										<div
										v-for="(gallary_view_nights, index) in gallary_view_night"
										:key="index"
										>
											<div class="product-box">
												<b-link
												class="delete-btn"
												@click="remove_images('day_night', index)"
												>
													<i class="mdi mdi-delete-circle"></i>
												</b-link>
												<div class="product-image">
													<video
													v-if="
													gallary_view_nights.file_extension === 'mp4' ||
													gallary_view_nights.file_extension === 'mkv'
													"
													class="embed-responsive-item"
													controls
													:src="gallary_view_nights.attachment"
													:type="'video/' + gallary_view_nights.file_extension"
													></video>
													<img
													:src="gallary_view_nights.attachment"
													v-if="
													gallary_view_nights.file_extension === 'png' ||
													gallary_view_nights.file_extension === 'jpeg' ||
													gallary_view_nights.file_extension === 'jpg' ||
													gallary_view_nights.file_extension === 'svg'
													"
													/>
												</div>
											</div>
										</div>
									</carousel>-->
									<div class="no-data" v-if="gallary_view_night.length === 0">
										<p>No images and video are here.</p>
									</div>
								</b-tab>
								<b-tab title="Images  ">

									<b-row>
										<b-col cols="3" v-for="(gallary_view_fronts, index) in gallary_view_front"
										:key="index">
										<div class="product-box">
												<b-link
												class="delete-btn"
												@click="remove_images('front_view', index)"
												>
													<i class="mdi mdi-delete-circle"></i>
												</b-link>
												<div class="product-image">
													<video
													v-if="
													gallary_view_fronts.file_extension === 'mp4' ||
													gallary_view_fronts.file_extension === 'mkv'
													"
													class="embed-responsive-item"
													controls
													:src="gallary_view_fronts.attachment"
													:type="'video/' + gallary_view_fronts.file_extension"
													></video>
													<img
													:src="gallary_view_fronts.attachment"
													v-if="
													gallary_view_fronts.file_extension === 'png' ||
													gallary_view_fronts.file_extension === 'jpeg' ||
													gallary_view_fronts.file_extension === 'jpg' ||
													gallary_view_fronts.file_extension === 'svg'
													"
													/>
												</div>
												<div class="img-overlay">
													<i class="mdi mdi-pencil editoption"></i>
      <div class="text">
		<!--{{ gallary_view_fronts.description || " Add description"}} -->
		<textarea  v-model="gallerydesc[index]" ></textarea>
		<button class="btn btn-sm btn-primary btn-extras" @click="saveGallerydesc(gallary_view_fronts.id,index)">Save</button></div>
      </div>
											</div>
										</b-col>
									</b-row>

									<!--<carousel
									v-if="gallary_view_front && gallary_view_front.length"
									class="slider_event"
									:nav="true"
									:items="3"
									:dots="false"
									:mouseDrag="true"
									:touchDrag="true"
									:smartSpeed="800"
									:margin="10"
									:responsive="{
									0: { items: 1 },
									575: { items: 2 },
									991: { items: 3 },
									1199: { items: 3 },
									}"
									:key="front_view"
									>
										
										<div
										v-for="(gallary_view_fronts, index) in gallary_view_front"
										:key="index"
										>
											<div class="product-box">
												<b-link
												class="delete-btn"
												@click="remove_images('front_view', index)"
												>
													<i class="mdi mdi-delete-circle"></i>
												</b-link>
												<div class="product-image">
													<video
													v-if="
													gallary_view_fronts.file_extension === 'mp4' ||
													gallary_view_fronts.file_extension === 'mkv'
													"
													class="embed-responsive-item"
													controls
													:src="gallary_view_fronts.attachment"
													:type="'video/' + gallary_view_fronts.file_extension"
													></video>
													<img
													:src="gallary_view_fronts.attachment"
													v-if="
													gallary_view_fronts.file_extension === 'png' ||
													gallary_view_fronts.file_extension === 'jpeg' ||
													gallary_view_fronts.file_extension === 'jpg' ||
													gallary_view_fronts.file_extension === 'svg'
													"
													/>
												</div>
												<div class="img-overlay">
      <div class="text">
		<input type="text">
		</div>
    </div>
											</div>
										</div>
									</carousel>-->
									<div class="no-data" v-if="gallary_view_front.length === 0">
										<p>No images and video are here.</p>
									</div>
								</b-tab>
								<b-tab title="videos">
									<carousel
									v-if="gallary_view_back && gallary_view_back.length"
									class="slider_event"
									:nav="true"
									:items="3"
									:dots="false"
									:mouseDrag="true"
									:touchDrag="true"
									:smartSpeed="800"
									:margin="10"
									:responsive="{
									0: { items: 1 },
									575: { items: 2 },
									991: { items: 3 },
									1199: { items: 3 },
									}"
									:key="back_view"
									>
										<div
										v-for="(gallary_view_backs, index) in gallary_view_back"
										:key="index"
										>
											<div class="product-box">
												<b-link
												class="delete-btn"
												@click="remove_images('back_view', index)"
												>
													<i class="mdi mdi-delete-circle"></i>
												</b-link>
												<div class="product-image">
													<video
													v-if="
													gallary_view_backs.file_extension === 'mp4' ||
													gallary_view_backs.file_extension === 'mkv'
													"
													class="embed-responsive-item"
													controls
													:src="gallary_view_backs.attachment"
													:type="'video/' + gallary_view_backs.file_extension"
													></video>
													<img
													:src="gallary_view_backs.attachment"
													v-if="
													gallary_view_backs.file_extension === 'png' ||
													gallary_view_backs.file_extension === 'jpeg' ||
													gallary_view_backs.file_extension === 'jpg' ||
													gallary_view_backs.file_extension === 'svg'
													"
													/>
												</div>
											</div>
										</div>
									</carousel>
									<div class="no-data" v-if="gallary_view_back.length === 0">
										<p>No video are here.</p>
									</div>
								</b-tab>
							</b-tabs>
							<b-button size="sm" variant="primary" @click="add_gallary()"
							>Add Images/videos
							</b-button>
						</div>
						
						<b-modal
						:no-close-on-backdrop="true"
						title="Add images/videos for gallary view"
						ok-title="Save"
						@ok="saveGallery"
						@cancel="closeGallery"
						id="gallary_view"
						size = "xl"
						header-bg-variant="primary" 
						header-text-variant="light"
						>
							<b-row>
								<b-col
								class="d-flex align-items-stretch"
								cols="12"
								lg="6"
								md="12"
								sm="12"
								>
									<b-card class="mb-4 w-100" border-variant="primary">
										<div class="text-center">
											<h4 class="font-weight-light">
											Thumbnail Photos </h4>
											<b-form-file
											accept=".jpg, .png, .jpeg, .svg"
											multiple
											:file-name-formatter="file_day_light"
											v-model="files_day_light"
											@change="onThemeChange"
											></b-form-file>
										</div>
										<span v-if="meta && meta.size < 1024 * 1024 "> </span>
										<span class="text-danger" v-else> Sorry! file is large  </span>
										<ul class="mt-2">
											<li>Thumbnail Image	Should be 150 x 150 pixels	Image Aspect Ratio 1:1</li>
											<li>Max size is 2MB</li>
											<li>Allowed Only 5 images</li>
										</ul>
									</b-card>
								</b-col>
								<b-col
								class="d-flex align-items-stretch"
								cols="12"
								lg="6"
								md="12"
								sm="12"
								>
									<b-card class="mb-4 w-100">
										<div class="text-center">
											<h4 class="font-weight-light">
												Upload Photos 
											</h4>
											<b-form-file
											accept=".jpg, .png, .jpeg, .svg"
											multiple
											:file-name-formatter="file_front_view"
											v-model="files_front_view"
											@change="onThemeChange1"
											></b-form-file>
											<span v-if="meta1 && meta1.size < 1024 * 1024 "> </span>
											<span class="text-danger" v-else> Sorry! file is large  </span>
											<ul class="mt-2">
												<li>Image Max size is 2MB</li>
												<li>Allowed Only 10 images</li>
											</ul>
										</div>
									</b-card>
								</b-col>
							</b-row>
							<b-row>
								<b-col
								class="d-flex align-items-stretch"
								cols="12"
								lg="6"
								md="12"
								sm="12"
								>
									<b-card class="mb-4 w-100">
										<div class="text-center">
											<h4 class="font-weight-light">
												Upload banner
											</h4>
											<b-form-file
											accept=".jpg, .png, .jpeg, .svg"
											multiple
											:file-name-formatter="file_night_life"
											v-model="files_night_life"
											@change="onThemeChange2"
											></b-form-file>
											<span v-if="meta2 && meta2.size < 1024 * 1024 "> </span>
											<span class="text-danger" v-else> Sorry! file is large  </span>
											<ul class="mt-2">
												<li>Image Max size is 2MB</li>
												<li>Allowed Only 10 images</li>
											</ul>
										</div>
										
									</b-card>
								</b-col>
								<b-col
								class="d-flex align-items-stretch"
								cols="12"
								lg="6"
								md="12"
								sm="12"
								>
									<b-card class="mb-4 w-100">
										<div class="text-center">
											<h4 class="font-weight-light">
												Videos
											</h4>
											<b-form-file
											accept=".mp4, .mkv"
											multiple
											:file-name-formatter="file_back_view"
											v-model="files_back_view"
											@change="onThemeChange3"
											></b-form-file>
											<span v-if="meta3 && meta3.size < 1024 * 1024 "> </span>
											<span class="text-danger" v-else> Sorry! file is large  </span>
											<ul class="mt-2">
												<li>Image Max size is 10MB</li>
												<li>Allowed Only 2 Videos</li>
											</ul>
										</div>
									</b-card>
								</b-col>
							</b-row>
						</b-modal>
					</tab-content>
					<tab-content
					title="Amenities"
					icon="mdi mdi-box-shadow"
					:before-change="next_amenities"
					>
						<b-col
						class="d-flex align-items-stretch"
						cols="12"
						lg="12"
						md="12"
						sm="12"
						>
							<div class="w-100 d-flex justify-content-end">
								<div class="p-2">
									<b-button size="sm"
									id="button_width1"
									variant="primary"
									@click="CreateAmetc()"
									>
										<i class="mdi mdi-plus"></i>Add New</b-button
									>
								</div>
								
							</div>
						</b-col>
						<div
						v-for="(amenities_detail, index) in amenities_details"
						:key="index"
						>
							<div class="mb-3">
								<div class="border p-2">
									<div class="text-center">
										<h6 class="custom-text"><span> {{ amenities_detail.category }} </span></h6>
										
									</div>
									<br />
									<b-row cols="1" cols-sm="1" cols-md="2" cols-lg="4">
										<b-col
										class="d-flex align-items-stretch"
										v-for="(option, index_c) in amenities_detail.amenities"
										:key="index_c"
										>
											<div class="w-100">
												<b-form-checkbox
												@change="add_amenities(option.id)"
												:key="option.id"
												value="1"
												unchecked-value="0"
												v-model="option.status"
												name="flavour-4a"
												inline
												>
													{{ option.name }}
												</b-form-checkbox>
											</div>
										</b-col>
									</b-row>
								</div>
							</div>
						</div>
						<!--  Add Category -->
						<b-modal
						:no-close-on-backdrop="true"
						title="Create Category and add details"
						ok-title="Save"
						@ok="handleOkAddOnCQ"
						@cancel="closeAddOnCQ"
						id="add_on_amtec"
						:ok-disabled="buttonok"
						>
							<b-form
							id="addon-input-group-1"
							@submit.stop.prevent="handleSubmitAddOnCAme"
							>
								
								<b-form-group label-for="name-group" id="name-group">
									<b-row class="py-3 align-items-center" v-if="!newCategory" >
										
										<b-col sm="3" class="text-left">
											<label class="fw-medium mb-0" for="lname2"
											>Category<span class="text-danger">*</span> :</label
											>
										</b-col>
										
										<b-col sm="8">
											<select class="form-control" v-model="amenities.ThCategory">
												<option value="">Select Category</option>
												
												<option v-for="(sc, indx) in amCategory" :value="sc.id" :key="indx">{{ sc.category }}</option>
											</select>
											
										</b-col>
										<b-col sm="1">
											<button class="btn btn-info btn-sm" v-if="!newCategory" @click="selectnew()"><i class="mdi mdi-plus"></i></button>
											
										</b-col>
									</b-row>
									
									
									<b-row class="py-3 align-items-center" v-if="newCategory">
										
										<b-col sm="3" class="text-left">
											<label class="fw-medium mb-0" for="lname2"
											>New Category<span class="text-danger">*</span> </label
											>
										</b-col>
										
										<b-col sm="8">
											<input type="text" class="form-control" v-model="amenities.NCategory" @input="checkvalidation(amenities.NCategory)" @keypress="isLetter($event)">
											
										</b-col>
										<b-col sm="1">
											<button class="btn btn-danger btn-sm" v-if="newCategory" @click="selectRemove()"><i class="mdi mdi-delete"></i></button>
											
										</b-col>
										
									</b-row>
									<b-row class="py-3 align-items-center">
										
										<b-col sm="3" class="text-left">
											<label class="fw-medium mb-0" for="lname2"
											> Amenities<span class="text-danger">*</span> :</label
											>
										</b-col>
										
										<b-col sm="9">
											<input type="text" class="form-control" v-model="amenities.new_amenities" placeholder="Enter the Amenities" @input="checkvalidation(amenities.new_amenities)"  @keypress="isLetter($event)">
											
										</b-col>
										
									</b-row>
								</b-form-group>
							</b-form>
						</b-modal>
						<!--  Add Category -->
						
 <b-popover target="popover-button-variant" variant="danger" triggers="click">
    <template #title>Danger!</template>
    Danger variant popover
  </b-popover>
						
					</tab-content>
					<tab-content title="Add-On" icon="mdi mdi-bookmark-plus">
						<b-modal
						:no-close-on-backdrop="true"
						title="Create Add On"
						ok-title="Save"
						@ok="handleOkAddOn"
						@cancel="closeAddOn"
						id="add_on"
						>
							<b-form
							id="addon-input-group-1"
							@submit.stop.prevent="handleSubmitAddOn"
							>
								<b-form-group label-for="name-group" id="name-group">
									<b-row class="py-3 align-items-center">
										<b-col sm="3" class="text-left">
											<label class="fw-medium mb-0" for="lname2"
											>Category<span class="text-danger">*</span> :</label
											>
										</b-col>
										<b-col sm="9">
											<b-form-select
											v-model="$v.add_on.category_id.$model"
											:options="add_on_category_options"
											:state="validateStateAddOn('category_id')"
											placeholder="Enter Add on name"
											name="name-group"
											id="category_id"
											aria-describedby="url-1-live-feedback"
											></b-form-select>
											
											<b-form-invalid-feedback
											id="category_id"
											v-if="$v.add_on.category_id.$error"
											>
												<div v-if="!$v.add_on.category_id.required">
													Please select category
												</div>
											</b-form-invalid-feedback>
										</b-col>
									</b-row>
								</b-form-group>
								<b-form-group label-for="name-group" id="name-group">
									<b-row class="py-3 align-items-center">
										<b-col sm="3" class="text-left">
											<label
											class="fw-medium mb-0"
											for="lname2"
											style="font-size: 13px"
											>Add On Name<span class="text-danger">*</span> :</label
											>
										</b-col>
										<b-col sm="9">
											<b-form-input
											v-model="$v.add_on.name.$model"
											:state="validateStateAddOn('name')"
											placeholder="Enter Add on name"
											name="name-group"
											id="name"
											aria-describedby="url-1-live-feedback"
											></b-form-input>
											<b-form-invalid-feedback
											id="name"
											v-if="$v.add_on.name.$error"
											>
												<div v-if="!$v.add_on.name.required">
													Please enter Add on name
												</div>
											</b-form-invalid-feedback>
										</b-col>
									</b-row>
								</b-form-group>
								
								
								
								<b-form-group label-for="name-group" id="name-group">
									<b-row class="py-3 align-items-center">
										<b-col sm="3" class="text-left">
											<label class="fw-medium mb-0" for="lname2"
											>Price<span class="text-danger">*</span> :</label
											>
										</b-col>
										<b-col sm="9">
											<b-form-input
											v-model="$v.add_on.price.$model"
											:state="validateStateAddOn('price')"
											placeholder="Enter price"
											name="name-group"
											id="price"
											aria-describedby="url-1-live-feedback"
											></b-form-input>
											<b-form-invalid-feedback
											id="price"
											v-if="$v.add_on.price.$error"
											>
												<div v-if="!$v.add_on.price.required">
													Please enter price
												</div>
												<div v-if="!$v.add_on.price.numeric">
													Please enter valid price
												</div>
											</b-form-invalid-feedback>
										</b-col>
									</b-row>
								</b-form-group>
								<b-form-group label-for="name-group" id="name-group">
									<b-row class="py-3 align-items-center">
										<b-col sm="3" class="text-left">
											<label class="fw-medium mb-0" for="lname2"
											>More details:</label
											>
										</b-col>
										<b-col sm="9">
											<b-form-input
											v-model="add_on.more_details"
											placeholder="Enter more details"
											name="name-group"
											id="more_details"
											aria-describedby="url-1-live-feedback"
											></b-form-input>
										</b-col>
									</b-row>
								</b-form-group>
								<b-form-group label-for="name-group" id="name-group">
									<b-row class="py-3 align-items-center">
										<b-col sm="3" class="text-left">
											<label class="fw-medium mb-0" for="lname2"
											>Photo/Video:</label
											>
										</b-col>
										<b-col sm="9">
											<b-form-file
											accept=".jpg, .png, .jpeg, .svg, .mp4, .mkv"
											:file-name-formatter="image_videos"
											v-model="add_on.image_video"
											aria-describedby="url-1-live-feedback"
											></b-form-file>
										</b-col>
									</b-row>
								</b-form-group>
							</b-form>
						</b-modal>
						<b-modal
						:no-close-on-backdrop="true"
						title="Create Category"
						ok-title="Save"
						@ok="handleOkAddOnC"
						@cancel="closeAddOnC"
						id="add_on_c"
						>
							<b-form
							id="addon-input-group-1"
							@submit.stop.prevent="handleSubmitAddOnC"
							>
								<b-form-group label-for="name-group" id="name-group">
									<b-row class="py-3 align-items-center">
										<b-col sm="3" class="text-left">
											<label class="fw-medium mb-0" for="lname2"
											>Category<span class="text-danger">*</span> :</label
											>
										</b-col>
										<b-col sm="9">
											<b-form-input
											v-model="$v.c_add.add_category_addon.$model"
											:state="validateStateAddOnC('add_category_addon')"
											placeholder="Enter Category"
											name="name-group"
											id="add_category_addon"
											aria-describedby="url-1-live-feedback"
											></b-form-input>
											<b-form-invalid-feedback
											id="name"
											v-if="$v.c_add.add_category_addon.$error"
											>
												<div v-if="!$v.c_add.add_category_addon.required">
													Please enter category
												</div>
											</b-form-invalid-feedback>
										</b-col>
									</b-row>
								</b-form-group>
							</b-form>
						</b-modal>
						<b-modal
						:no-close-on-backdrop="true"
						hide-header-close
						title="Edit Add On"
						ok-title="Save"
						@ok="handleOkAddOnEdit"
						@cancel="closeAddOnEdit"
						id="add_on_edit"
						>
							<b-form
							id="addon-input-group-1"
							@submit.stop.prevent="handleSubmitAddOnEdit"
							>
								<b-form-group label-for="name-group" id="name-group">
									<b-row class="py-3 align-items-center">
										<b-col sm="3" class="text-left">
											<label class="fw-medium mb-0" for="lname2"
											>Category<span class="text-danger">*</span> :</label
											>
										</b-col>
										<b-col sm="9">
											<b-form-select
											v-model="$v.add_on_edit.category.$model"
											:options="add_on_category_options"
											:state="validateStateAddOnEdit('category')"
											placeholder="Enter Add on name"
											name="name-group"
											id="category"
											aria-describedby="url-1-live-feedback"
											></b-form-select>
											<b-form-invalid-feedback
											id="category"
											v-if="$v.add_on_edit.category.$error"
											>
												<div v-if="!$v.add_on_edit.category.required">
													Please select category
												</div>
											</b-form-invalid-feedback>
										</b-col>
									</b-row>
								</b-form-group>
								<b-form-group label-for="name-group" id="name-group">
									<b-row class="py-3 align-items-center">
										<b-col sm="3" class="text-left">
											<label
											class="fw-medium mb-0"
											for="lname2"
											style="font-size: 13px"
											>Add On Name<span class="text-danger">*</span> :</label
											>
										</b-col>
										<b-col sm="9">
											<b-form-input
											v-model="$v.add_on_edit.name.$model"
											:state="validateStateAddOnEdit('name')"
											placeholder="Enter Add on name"
											name="name-group"
											id="name"
											aria-describedby="url-1-live-feedback"
											></b-form-input>
											<b-form-invalid-feedback
											id="name"
											v-if="$v.add_on_edit.name.$error"
											>
												<div v-if="!$v.add_on_edit.name.required">
													Please enter Add on name
												</div>
											</b-form-invalid-feedback>
										</b-col>
									</b-row>
								</b-form-group>
								
								
								
								<b-form-group label-for="name-group" id="name-group">
									<b-row class="py-3 align-items-center">
										<b-col sm="3" class="text-left">
											<label class="fw-medium mb-0" for="lname2"
											>Price<span class="text-danger">*</span> :</label
											>
										</b-col>
										<b-col sm="9">
											<b-form-input
											v-model="$v.add_on_edit.price.$model"
											:state="validateStateAddOnEdit('price')"
											placeholder="Enter price"
											name="name-group"
											id="price"
											aria-describedby="url-1-live-feedback"
											></b-form-input>
											<b-form-invalid-feedback
											id="price"
											v-if="$v.add_on_edit.price.$error"
											>
												<div v-if="!$v.add_on_edit.price.required">
													Please enter price
												</div>
												<div v-if="!$v.add_on_edit.price.numeric">
													Please enter valid price
												</div>
											</b-form-invalid-feedback>
										</b-col>
									</b-row>
								</b-form-group>
								<b-form-group label-for="name-group" id="name-group">
									<b-row class="py-3 align-items-center">
										<b-col sm="3" class="text-left">
											<label class="fw-medium mb-0" for="lname2"
											>More details:</label
											>
										</b-col>
										<b-col sm="9">
											<b-form-input
											v-model="add_on_edit.more_details"
											placeholder="Enter more details"
											name="name-group"
											id="more_details"
											aria-describedby="url-1-live-feedback"
											></b-form-input>
										</b-col>
									</b-row>
								</b-form-group>
								<b-form-group label-for="name-group" id="name-group">
									<b-row class="py-3 align-items-center">
										<b-col sm="3" class="text-left">
											<label class="fw-medium mb-0" for="lname2"
											>Photo/Video:</label
											>
										</b-col>
										<b-col sm="9">
											<b-form-file
											accept=".jpg, .png, .jpeg, .svg, .mp4, .mkv"
											:file-name-formatter="image_videos"
											v-model="add_on_edit.image_video"
											aria-describedby="url-1-live-feedback"
											></b-form-file>
										</b-col>
									</b-row>
								</b-form-group>
								<b-form-group
								label-for="name-group"
								id="name-group"
								v-if="add_on_edit.file_addon !== ''"
								>
									<b-row class="py-3 align-items-center">
										<b-col sm="3" class="text-left">
											<label class="fw-medium mb-0" for="lname2"
											>Download:</label
											>
										</b-col>
										<b-col sm="9">
											<span class="font-weight-light"
											>Click here to view your add on image/video.</span
											><br />
											<b-link :href="add_on_edit.file_addon" target="_blank">
												<span class="text-primary" style="font-size: 24px">
													<i class="mdi mdi-eye"></i> </span
												></b-link>
										</b-col>
									</b-row>
								</b-form-group>
							</b-form>
						</b-modal>
						<b-modal
						:no-close-on-backdrop="true"
						hide-header-close
						ok-only
						title="View Add On"
						ok-title="Close"
						@ok="closeAddOnView"
						id="add_on_view"
						>
							<b-form-group label-for="name-group" id="name-group">
								<b-row class="py-3 align-items-center">
									<b-col sm="3" class="text-left">
										<label
										class="fw-medium mb-0"
										for="lname2"
										style="font-size: 13px"
										>Add On Name:</label
										>
									</b-col>
									<b-col sm="9">
										<span> {{ add_on_edit.name }}</span>
									</b-col>
								</b-row>
							</b-form-group>
							
							<b-form-group label-for="name-group" id="name-group">
								<b-row class="py-3 align-items-center">
									<b-col sm="3" class="text-left">
										<label class="fw-medium mb-0" for="lname2">Category:</label>
									</b-col>
									<b-col sm="9">
										<span> {{ add_on_edit.category_name }}</span>
									</b-col>
								</b-row>
							</b-form-group>
							
							<b-form-group label-for="name-group" id="name-group">
								<b-row class="py-3 align-items-center">
									<b-col sm="3" class="text-left">
										<label class="fw-medium mb-0" for="lname2">Price:</label>
									</b-col>
									<b-col sm="9">
										<span> {{ add_on_edit.price }}</span>
									</b-col>
								</b-row>
							</b-form-group>
							<b-form-group
							label-for="name-group"
							id="name-group"
							v-if="
							add_on_edit.more_details !== '' ||
							add_on_edit.more_details !== null
							"
							>
								<b-row class="py-3 align-items-center">
									<b-col sm="3" class="text-left">
										<label class="fw-medium mb-0" for="lname2"
										>More details:</label
										>
									</b-col>
									<b-col sm="9">
										<span> {{ add_on_edit.more_details }}</span>
									</b-col>
								</b-row>
							</b-form-group>
							<b-form-group
							label-for="name-group"
							id="name-group"
							v-if="add_on_edit.file_addon !== ''"
							>
								<b-row class="py-3 align-items-center">
									<b-col sm="3" class="text-left">
										<label class="fw-medium mb-0" for="lname2">Download:</label>
									</b-col>
									<b-col sm="9">
										<span class="font-weight-light"
										>Click here to view your add on image/video.</span
										><br />
										<b-link :href="add_on_edit.file_addon" target="_blank">
											<span class="text-primary" style="font-size: 24px">
												<i class="mdi mdi-eye"></i> </span
											></b-link>
									</b-col>
								</b-row>
							</b-form-group>
						</b-modal>
						<b-cards class="mb-4 w-100">
							<div class="venue-heading">
								<b-row>
									<b-col
									class="d-flex align-items-stretch"
									cols="12"
									lg="6"
									md="12"
									sm="12"
									>
									</b-col>
									<b-col
									class="d-flex align-items-stretch"
									cols="12"
									lg="6"
									md="12"
									sm="12"
									>
										<div class="w-100 d-flex justify-content-end">
											
										</div>
									</b-col>
								</b-row>
							</div>
							<div class="venue-heading">
								<b-row>
									<b-col
									class="d-flex align-items-stretch"
									cols="12"
									lg="6"
									md="5"
									sm="12"
									>
										<div class="p-1">
											<b-button size="sm"
											id="button_width1"
											variant="primary"
											@click="CreateAddOnC()"
											>
												Add Category</b-button
											>
										</div>
										<div class="p-1">
											<b-button size="sm"
											id="button_width1"
											variant="primary"
											@click="CreateAddOn()"
											>
												Add on
												data</b-button
											>
										</div>
										<div class="p-1">
											<b-button size="sm"
											id="button_width"
											@click="export_add_on()"
											variant="primary"
											>
												Export</b-button
											>
										</div>
										
										
									</b-col>
									<b-col
									
									cols="12"
									lg="3"
									md="6"
									sm="12"
									>
										<div class="p-1">
											<multiselect
											v-model="addon_selected"
											:options="addon_option"
											placeholder="Select Add On"
											>
											</multiselect>
										</div>
									</b-col>
									<b-col
									
									cols="12"
									lg="3"
									md="6"
									sm="12"
									>
										<div class="w-100 p-1">
											<div>
												<multiselect
												v-model="category_selected"
												:options="category_option_add_on"
												placeholder="Select Category"
												>
												</multiselect>
											</div>
										</div>
									</b-col>
									
									<!-- <b-col
										class="d-flex align-items-stretch"
										cols="12"
										lg="6"
										md="12"
										sm="12"
										>
										<div class="w-100 d-flex justify-content-end">

										<div class="p-2">
										<b-button variant="primary" size="sm" @click="applyFilter()">
										Apply</b-button
										>
										</div>
										<div class="p-2">
										<b-button variant="primary" size="sm" @click="resetFilter()">
										Reset</b-button
										>
										</div>
										
										
										</div>
									</b-col> -->
									<b-col
									
									cols="12"
									lg="6"
									md="6"
									sm="12"
									>
										<div class="p-2 float-left">
											<b-form-input
											v-model="search"
											id="search"
											type="text"
											placeholder="Search here"
											v-on:keyup="key_search()"
											></b-form-input>
										</div>
										
									</b-col>
									<b-col
									
									cols="12"
									lg="6"
									md="6"
									sm="12"
									>	
										<div class="p-2 float-right">
											
										<b-form-select
										
										id="select_perpage"
										v-model="pageSize"
										:options="options"
										@change="handlePageSizeChange($event)"
										></b-form-select>
									
											<b-button variant="primary" size="sm" @click="applyFilter()" class="ml-2">
												Apply</b-button
											>
											<b-button variant="primary" size="sm" @click="resetFilter()" class="ml-2">
												Reset</b-button
											>
										</div>
									</b-col>
									
									
									
								</b-row>
							</div>
							
							<div>
								<b-row>
									<b-col
									class="d-flex align-items-stretch"
									cols="12"
									lg="4"
									md="6"
									sm="6"
									>
										<div
										class="
										w-100
										justify-content-sm-center
										justify-content-xl-start
										justify-content-md-start
										"
										>
											<!--<div class="p-2">
												<b-form-select
												id="select_perpage"
												v-model="pageSize"
												:options="options"
												@change="handlePageSizeChange($event)"
												></b-form-select>
											</div>-->
										</div>
									</b-col>
									
									<b-col
									class="d-flex align-items-stretch"
									cols="12"
									lg="8"
									md="12"
									sm="12"
									>
										
									</b-col>
								</b-row>
							</div>
							<br />
							<div>
								<b-table
								:busy="load"
								:fields="fields"
								:items="add_on_data"
								responsive
								hover
								bordered
								show-empty
								empty-text="No Data Available"
								class="text-center"
								>
									<template #table-busy>
										<b-skeleton-table
										v-if="load"
										:rows="10"
										:columns="6"
										:hide-header="true"
										:table-props="{ bordered: true, striped: true }"
										></b-skeleton-table>
									</template>
									
									<template #cell(publish_status)="data">
										<b-form-checkbox
										name="check-button"
										switch
										v-on:change="statusChange(data.item.id)"
										:checked="data.item.publish_status == 1"
										>
										</b-form-checkbox>
									</template>
									<template #cell(view)="data">
										<span>
											<i
											@click="viewAddon(data.item.id)"
											class="fas fa-eye fa-lg text-dark"
											:id="data.item.id"
											></i>
										</span>
									</template>
									<template #cell(edit)="data">
										<span>
											<i
											@click="updateAddon(data.item.id)"
											class="fas fa-edit fa-lg text-dark"
											:id="data.item.id"
											></i>
										</span>
									</template>
								</b-table>
								<b-row align-v="center">
									<b-col cols="4">
										<span
										>Showing <b>{{ totalRows }}</b> out of
											<b>{{ totalData }}</b> entries.</span
										>
									</b-col>
									<b-col cols="8">
										<b-pagination
										v-model="page"
										:total-rows="count"
										:per-page="pageSize"
										prev-text="Prev"
										next-text="Next"
										align="right"
										@change="handlePageChange"
										></b-pagination>
									</b-col>
								</b-row>
							</div>
						</b-cards>
					</tab-content>
					<b-button variant="danger" dark slot="prev">
						<!-- <i class="mdi mdi-chevron-left mr-1"></i>-->
						Back
					</b-button>
					<b-button variant="primary" slot="next">
						Update and Next
						<!-- <i class="mdi mdi-chevron-right ml-1"></i>-->
					</b-button>
					<b-button variant="success" slot="finish">
						Finish
						<!--  <i class="mdi mdi-check ml-1"></i>-->
					</b-button>
				</form-wizard>
			</div>
		</b-cards>
	</div>
</template>
<script>
	import moment from "moment";
	import { FormWizard, TabContent } from "vue-form-wizard";
	import "vue-form-wizard/dist/vue-form-wizard.min.css";
	import Multiselect from "vue-multiselect";
	import axios from "axios";
	import { validationMixin } from "vuelidate";
	import {
		required,
		minLength,
		maxLength,
		numeric,
		requiredIf
	} from "vuelidate/lib/validators";
	import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
	import Datepicker from "vuejs-datepicker";
	import carousel from "vue-owl-carousel";
	
	import TimeShift from '../newVenue/venue_setting.vue'
	import newShift from '../newVenue/TimeShift.vue'
	import { VueEditor } from "vue2-editor";
	
	/* eslint-disable */
	var pin = "";
	var country = "";
	var state = "";
	var city = "";
	var place;
	var place_name;
	var place_formatted_address;
	var autocomplete;
	var latitude;
	var longitude;
	var place_id;
	var shiftnew_convertedStartTime, shiftnew_curr_time, shiftnew_time24, shiftnew_convertedEndTime;
	export default {
		name: "child_venue_add",
		mixins: [validationMixin],
		data: () => ({
			title: "child_venue_add",
			props: ["parent_id"],
			category_options: [{ value: null, text: "Please select category" }],
			overview_form: {
				v_name: "",
				v_location: "",
				city: "",
				contact: "",
				category: null,
				wing: "",
				metting_space: "",
				renovated: "",
				guest_room: "",
				dist_airport: "",
				near_by: "",
				details_bio: "",
				more_info: "",
				cancellation_policy: "",
				facility_restrictions: "",
				address: "",
			},
			
			overview_next: "0",
			child_venue_id: "",
			parentmessage: '',
			buttonok:false,
			meta: null,
			meta1: null,
			meta2: null,
			meta3: null,
			checkparent: 'no',
			todo: {
        id:1,
        task:'todo 1',
        completed:false
      },
			shift_time: [
			{
				shift_sdate: new Date().toISOString().substr(0, 10),
				shift_edate: "",
				shift: null,
				show_status: false,
				shift_stime: null,
				shift_etime: null,
				hour_range: "",
				error_m: false,
				shift_edate_m: false,
				error_s: "",
				error_end_date: "",
				error_shift_select: false,
				error_shift_m: "",
				price: "",
				price_status: false,
				price_message: "",
				timelist_from: [{ value: null, text: "Start" }],
				timelist_end: [{ value: null, text: "End" }],
			},
			],
			shift_options: [],
			shift_types: [
			{ value: null, text: "Select shift type" },
			{ value: "1", text: "Hourly" },
			{ value: "2", text: "Shift Wise" },
			],
			shift_types_selected: null,
			shift_types_selected_status: false,
			today_date: new Date().toISOString().substr(0, 10),
			files_day_light: [],
			files_front_view: [],
			files_night_life: [],
			files_back_view: [],
			amenities_details: [],
			added_amenities: [],
			add_on: {
				name: "",
				category_id: null,
				price: "",
				more_details: "",
				image_video: [],
			},
			add_on_category_options: [{ value: null, text: "Please select category" }],
			
			fields: [
			//  'index',
			{
				key: "name",
				label: "Add On Name",
				sortable: false,
			},
			{
				key: "category",
				label: "Category",
				sortable: false,
			},
			{
				key: "price",
				label: "Price",
				sortable: false,
			},
			{
				key: "publish_status",
				label: "Publish Status",
			},
			{
				key: "view",
				label: "View",
			},
			{
				key: "edit",
				label: "Edit",
			},
			],
			item: [],
			value: "",
			addon_selected: "",
			category_selected: "",
			add_on_data: [],
			add_onCounts: 0,
			options: [10, 20, 30, 50],
			
			pageSize: 10,
			show: false,
			load: true,
			filter: "",
			search: "",
			CurrentPage: 1,
			sortBy: "created_at",
			
			page: 1,
			count: 0,
			totalData: "",
			totalRows: "",
			
			PerPage: "50",
			
			addon_option: [],
			category_option: [],
			category_option_add_on: [],
			c_add: {
				add_category_addon: "",
			},
			type_of_data: [{ value: null, text: "Please select room type" }],
			more_detail: {
				metting_space: "",
				type_of: null,
				room_size: "",
				ceiling_heignt: "",
				u_shape: "",
				banquate_round: "",
				cocktail_round: "",
				theater: "",
				classroom: "",
				boardroom: "",
				e_shape: "",
				hollow_square: "",
				perimiter_seating: "",
				royal_conference: "",
				t_shape: "",
				talk_show: "",
				room_dimension: "",
			},
			// IsplaceChange: true,
			add_on_edit: {
				add_on_id: "",
				name: "",
				category: null,
				price: "",
				more_details: "",
				file_addon: "",
				image_video: [],
				category_name: "",
			},
			shift_wise_new:{
				shift_error_status:false,
				morning_start_time: null,
				morning_end_time:null,
				afternoon_start_time: null,
				afternoon_end_time:null,
				evening_start_time:null,
				evening_end_time:null,
				morning_publish_status:'0',
				afternoon_publish_status:'0',
				evening_publish_status:'0',
				morning_timelist_from: [],
				morning_timelist_end: [],
				afternoon_timelist_from: [],
				afternoon_timelist_end: [],
				evening_timelist_from: [],
				evening_timelist_end: [],
				shifts_interval:30, //minutes interval
				shifts_start_time:'06:00 AM',
				shifts_end_time:'12:00 AM',
				morning_shifts_arr:[],
				morning_shifts_disabled_arr:[],
				afternoon_shifts_arr:[],
				afternoon_shifts_disabled_arr:[],
				evening_shifts_arr:[],
				evening_shifts_disabled_arr:[],
				sort_shifts_timeout:600, //miliseconds for settimeout
				start_time_required_message:"Required",
				end_time_required_message:"Required",
				start_date_required_message:"Start date required",
				end_date_required_message:"End date required",
				price_required_message:"Price required",
				price_numeric_message:"Invalid price",
			},
			parent_venue_id: "",
			amenities_get_datas: [],
			final_amenities: [],
			gallary_view_data: [],
			gallary_view_day: [],
			gallary_view_night: [],
			gallary_view_front: [],
			gallary_view_back: [],
			day_light: 0,
			day_night: 0,
			front_view : 0,
			back_view : 0,
			amCategory:[],
			newCategory:false,
			amenities:{
				ThCategory:"",
				NCategory:"",
				new_amenities:"",
			},
			/* TEMP */
			card_enabled:false,
				card_enabled1:false,
				card_enabled2:false,
				new_shift_enable:false,
				
				child_venue_id:"",
				timevalue:"",
				timevalue1:"",
				notes: '',
				/* time  */
				
				mshift_name:'Morning',
				ashift_name:'Afternoon',
				eshift_name:'Evening',
				met:"",
				mst:"",
				msd:"",
				med:"",
				msa:"",
				ast:"",
				aet:"",
				asd:"",
				aed:"",
				aea:"",
				est:"",
				eet:"",
				esd:"",
				eed:"",
				eea:"",
				formatted: '',
				format : 'hh:mm:ss',
				current_year:new Date().getFullYear(),
				morningsave:false,
				afternoonsave:false,
				eveningsave:false,
				headers:[],
				gallerydesc:{},
			/* TEMP */
		}),
		components: {
			FormWizard,
			TabContent,
			VueTimepicker,
			Multiselect,
			Datepicker,
			carousel,
			TimeShift,
			VueEditor,
			newShift
		},
		validations: {
			overview_form: {
				v_name: {
					required,
				},
				v_location: {
					required,
				},
				city: {
					required,
				},
				contact: {
					required,
					numeric,
					minLength: minLength(10),
					maxLength: maxLength(10),
				},
				category: {
					required,
				},
				pincode: {
					required,
					numeric,
					minLength: minLength(6),
					maxLength: maxLength(6),
				},
				metting_space: {
					numeric,
				},
			},
			add_on: {
				name: {
					required,
				},
				category_id: {
					required,
				},
				price: {
					required,
					numeric,
				},
			},
			add_on_edit: {
				name: {
					required,
				},
				category: {
					required,
				},
				price: {
					required,
					numeric,
				},
			},
			c_add: {
				add_category_addon: {
					required,
				},
			},
			more_detail: {
				metting_space: {
					required,
					numeric,
				},
				type_of: {
					required,
				},
				room_size: {
					required,
					numeric,
				},
				ceiling_heignt: {
					required,
					numeric,
				},
				room_dimension: {
					required,
					numeric,
				},
				u_shape: {
					numeric,
				},
				banquate_round: {
					numeric,
				},
				cocktail_round: {
					numeric,
				},
				theater: {
					numeric,
				},
				classroom: {
					numeric,
				},
				boardroom: {
					numeric,
				},
				e_shape: {
					numeric,
				},
				hollow_square: {
					numeric,
				},
				perimiter_seating: {
					numeric,
				},
				royal_conference: {
					numeric,
				},
				t_shape: {
					numeric,
				},
				talk_show: {
					numeric,
				},
			},
			shift_wise_new:{
				morning_start_time:{ 
					required: requiredIf(function () {
						return this.shift_wise_new.morning_publish_status == '1' ? true : false
					}) 
				},
				morning_end_time:{ 
					required: requiredIf(function () {
						return this.shift_wise_new.morning_publish_status == '1' ? true : false
					})
				},
				afternoon_start_time:{ 
					required: requiredIf(function () {
						return this.shift_wise_new.afternoon_publish_status == '1' ? true : false
					})
				},
				afternoon_end_time:{ 
					required: requiredIf(function () {
						return this.shift_wise_new.afternoon_publish_status == '1' ? true : false
					})
				},
				evening_start_time:{ 
					required: requiredIf(function () {
						return this.shift_wise_new.evening_publish_status == '1' ? true : false
					})
				},
				evening_end_time:{ 
					required: requiredIf(function () {
						return this.shift_wise_new.evening_publish_status == '1' ? true : false
					})
				},
				morning_shifts_arr:{
					$each: {
						start_date:{ required },
						end_date:{ required },
						price:{ required, numeric },
					}
				},
				afternoon_shifts_arr:{
					$each: {
						start_date:{ required },
						end_date:{ required },
						price:{ required, numeric },
					}
				},
				evening_shifts_arr:{
					$each: {
						start_date:{ required },
						end_date:{ required },
						price:{ required, numeric },
					}
				},
			},
		},
		methods: {
			onThemeChange(event) {
				const file = event.target.files[0];
				
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (evt) => {
					let img = new Image();
					const meta = (img.onload = () => {
						return {
							size: event.target.files[0].size,
							width: img.width,
							height: img.height,
						};
					});
					img.src = evt.target.result;
					this.meta = meta();
				};
			},
			onThemeChange1(event) {
				const file = event.target.files[0];
				
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (evt) => {
					let img = new Image();
					const meta1 = (img.onload = () => {
						return {
							size: event.target.files[0].size,
							width: img.width,
							height: img.height,
						};
					});
					img.src = evt.target.result;
					this.meta1 = meta1();
				};
			},
			onThemeChange2(event) {
				const file = event.target.files[0];
				
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (evt) => {
					let img = new Image();
					const meta2 = (img.onload = () => {
						return {
							size: event.target.files[0].size,
							width: img.width,
							height: img.height,
						};
					});
					img.src = evt.target.result;
					this.meta2 = meta2();
				};
			},
			onThemeChange3(event) {
				const file = event.target.files[0];
				
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (evt) => {
					let img = new Image();
					const meta3 = (img.onload = () => {
						return {
							size: event.target.files[0].size,
							width: img.width,
							height: img.height,
						};
					});
					img.src = evt.target.result;
					this.meta3 = meta3();
				};
			},
			set_shift_hour(type) {
				this.shift_time = [
				{
					shift_sdate: new Date().toISOString().substr(0, 10),
					shift_edate: "",
					shift: null,
					show_status: false,
					shift_stime: null,
					shift_etime: null,
					hour_range: "",
					error_m: false,
					shift_edate_m: false,
					error_s: "",
					error_end_date: "",
					error_shift_select: false,
					error_shift_m: "",
					price: "",
					price_status: false,
					price_message: "",
					timelist_from: [{ value: null, text: "Start" }],
					timelist_end: [{ value: null, text: "End" }],
				},
				];
				if (type === "1") {
					this.shift_types_selected_status = false;
					this.shift_options = [
					{ value: null, text: "Select" },
					{ value: "4", text: "Hourly" },
					];
				}
				if (type === "2") {
					this.shift_types_selected_status = false;
					this.shift_options = [
					{ value: null, text: "Select" },
					{ value: "1", text: "Morning" },
					{ value: "2", text: "Afternoon" },
					{ value: "3", text: "Evening" },
					];
				}
			},
			
			priceState(index) {
				if (/^\d+$/.test(this.shift_time[index].price)) {
					this.shift_time[index].price_status = false;
					} else {
					this.shift_time[index].price_status = true;
					this.shift_time[index].price_message = "Please enter valid price..!";
				}
				
				//  else
				//  {
				//    this.shift_time[index].price_status = true;
				//    this.shift_time[index].price_message = "Please enter price..!";
				//  }
			},
			change_end_date(index, end_date) {
				if (end_date === "") {
					this.shift_time[index].shift_edate_m = true;
					this.shift_time[index].error_end_date = "Please Select end date..!";
					} else {
					this.shift_time[index].shift_edate_m = false;
				}
			},
			check_set_time(index) {
				//var shift = this.shift_time[index].shift;
				// if (shift == "1") {
				//   this.updateShiftsTimingShiftWise(6, 12, index);
				// } else if (shift == "2") {
				//   this.updateShiftsTimingShiftWise(12, 17, index);
				// } else if (shift == "3") {
				//   this.updateShiftsTimingShiftWise(18, 24, index);
				// } else { }
				this.updateShiftsTimingShiftWise(6, 24, index);
			},
			updateShiftsTimingShiftWise(start_time, end_time, index) {
				if (this.shift_time[index].shift_stime != null) {
					this.updateShiftsEndTime(end_time, index);
				}
				if (this.shift_time[index].shift_etime != null) {
					this.updateShiftsStartTime(start_time, index);
				}
			},
			updateShiftsEndTime(end_time, index) {
				var convertedStartTime = parseInt(
				this.convertTime12to24(this.shift_time[index].shift_stime)
				);
				var curr_time, time24;
				this.shift_time[index].timelist_end = [];
				this.shift_time[index].timelist_end.push({
					value: null,
					text: "Select time",
				});
				
				//update end time to selcted starttime
				curr_time = convertedStartTime;
				while (curr_time <= end_time) {
					time24 = this.convertTime24to12(curr_time);
					this.shift_time[index].timelist_end.push({
						value: time24,
						text: time24,
					});
					curr_time = parseInt(curr_time) + 1;
				}
			},
			updateShiftsStartTime(start_time, index) {
				var convertedEndTime = parseInt(
				this.convertTime12to24(this.shift_time[index].shift_etime)
				);
				var curr_time, time24;
				this.shift_time[index].timelist_from = [];
				this.shift_time[index].timelist_from.push({
					value: null,
					text: "Select time",
				});
				
				//update start time to selcted Endtime
				curr_time = start_time;
				while (curr_time <= convertedEndTime) {
					time24 = this.convertTime24to12(curr_time);
					this.shift_time[index].timelist_from.push({
						value: time24,
						text: time24,
					});
					curr_time = parseInt(curr_time) + 1;
				}
			},
			convertTime12to24(time12h) {
				
				const [time, modifier] = time12h.split(" ");
				let [hours] = time.split(":");
				if (modifier === "PM" && hours < 12) {
					hours = parseInt(hours, 10) + 12;
				}
				if (modifier === "AM" && hours == 12) {
					hours = 24;
				}
				return `${hours}`;
			},
			convertTime24to12(hours) {
				if (hours == 24) {
					hours = 0;
				}
				let minutes = 0;
				const ampm = hours >= 12 ? "PM" : "AM";
				hours %= 12;
				hours = hours || 12;
				hours = hours < 10 ? `0${hours}` : hours;
				minutes = minutes < 10 ? `0${minutes}` : minutes;
				const strTime = `${hours}:${minutes} ${ampm}`;
				return strTime;
			},
			validateStateOverview(v_name) {
				const { $dirty, $error } = this.$v.overview_form[v_name];
				return $dirty ? !$error : null;
			},
			validateStateAddOn(name) {
				const { $dirty, $error } = this.$v.add_on[name];
				return $dirty ? !$error : null;
			},
			validateStateAddOnEdit(name) {
				const { $dirty, $error } = this.$v.add_on_edit[name];
				return $dirty ? !$error : null;
			},
			validateStateAddOnC(add_category_addon) {
				const { $dirty, $error } = this.$v.c_add[add_category_addon];
				return $dirty ? !$error : null;
			},
			validateStateMoreD(metting_space) {
				const { $dirty, $error } = this.$v.more_detail[metting_space];
				return $dirty ? !$error : null;
			},
			save_child_venue: function () {
				const id = this.$route.params.id;
				this.$router.push("/venues/child-venue/" + id, () => {
					this.$root.$refs.app.showToast(
					"success",
					"Child venue created successfully"
					);
				});
			},
			next_shift_timing: function () {
				return new Promise((resolve) => {
        resolve(true);
      });
				/*return new Promise((resolve, reject) => {
					
					if (this.shift_types_selected === null) {
						this.shift_types_selected_status = true;
						reject(false);
					}
					else if(this.shift_types_selected == 1){
						this.next_shift_timing_hourly();
					}
					else if(this.shift_types_selected == 2){
						this.next_shift_timing_shift_wise();
					}
					
					if(this.shift_wise_new.shift_error_status == true){
						resolve(true);
						}else{
						reject(false);
					}
				});*/
			},
			next_shift_timing_hourly(){
				var check_error = this.shift_time.some(
				(shift) => shift.error_m === true
				);
				var check_empty = this.shift_time.some(
				(shifts) => shifts.shift_edate === ""
				);
				var check_price = this.shift_time.some(
				(shifts) => shifts.price_status === true
				);
				var check_price_empty = this.shift_time.some(
				(shifts) => shifts.price === ""
				);
				var check_shift = this.shift_time.some((shift) => shift.shift === null);
				if (
				check_error ||
				check_empty ||
				check_shift ||
				check_price ||
				check_price_empty
				) {
					for (let i = 0, l = this.shift_time.length; i < l; i++) {
						if (this.shift_time[i].shift_edate === "") {
							this.shift_time[i].shift_edate_m = true;
							this.shift_time[i].error_end_date = "Please select end date..!";
						}
						
						if (this.shift_time[i].shift === null) {
							this.shift_time[i].error_shift_select = true;
							this.shift_time[i].error_shift_m = "Please select shift..!";
						}
						
						if (this.shift_time[i].error_m === true) {
							this.shift_time[i].error_s = "Please select valid time..!";
						}
						
						if (this.shift_time[i].price_status === true) {
							this.shift_time[i].price_message = "Please enter valid price..!";
						}
						if (this.shift_time[i].price === "") {
							this.shift_time[i].price_status = true;
							this.shift_time[i].price_message = "Please enter price..!";
						}
					}
					
					this.shift_wise_new.shift_error_status = false;
					// reject(false);
					} else {
					const final_shift_time = this.shift_time;
					final_shift_time.forEach((object) => {
						delete object["show_status"];
						delete object["hour_range"];
						delete object["error_m"];
						delete object["shift_edate_m"];
						delete object["error_s"];
						delete object["error_end_date"];
						delete object["error_shift_select"];
						delete object["error_shift_m"];
					});
					for (let c = 0; c < final_shift_time.length; c++) {
						var s_time = moment(this.shift_time[c].shift_stime, "hh A").format(
						"HH:mm:ss"
						);
						var e_time = moment(this.shift_time[c].shift_etime, "hh A").format(
						"HH:mm:ss"
						);
						this.shift_time[c].shift_stime = s_time;
						this.shift_time[c].shift_etime = e_time;
					}
					
					const tab_type = "shift_timing";
					const tabs = JSON.parse(
					'{"overview":1, "shift": 1, "more_detais":0, "gallery":0 , "amenities":0, "add_on":0}'
					);
					axios
					.post("/api/add_child_venue", {
						shift_timing_arr: JSON.stringify(final_shift_time),
						filled_tab: tabs,
						tab_type: tab_type,
						child_venue_id: this.child_venue_id,
						shift_types_selected: this.shift_types_selected,
					})
					.then((resp) => {
						if (resp.data.status_code == 200) {
							if (resp.data.status) {
								this.set_as_time();
								this.get_moreDetails();
								// resolve(true);
								this.shift_wise_new.shift_error_status = true;
							}
							} else {
							// reject(false);
							this.shift_wise_new.shift_error_status = false;
						}
					});
				}
			},
			next_shift_timing_shift_wise(){
				
				this.$v.shift_wise_new.$touch();
				// this.$v.shift_wise_new.morning_shifts_arr.$touch();
				// this.$v.shift_wise_new.afternoon_shifts_arr.$touch();
				// this.$v.shift_wise_new.evening_shifts_arr.$touch();
				
				// if (!this.$v.shift_wise_new.$anyError && !this.$v.shift_wise_new.morning_shifts_arr.$anyError && !this.$v.shift_wise_new.afternoon_shifts_arr.$anyError && !this.$v.shift_wise_new.evening_shifts_arr.$anyError) {
				if (!this.$v.shift_wise_new.$anyError) {
					
					var morning_selected_shifts = [], afternoon_selected_shifts = [], evening_selected_shifts = [], final_shifts, saved_shifts, shift;
					
					if(this.shift_wise_new.morning_shifts_disabled_arr.length > 0){
						saved_shifts = this.shift_wise_new.morning_shifts_disabled_arr;
						for(shift=0; shift<saved_shifts.length; shift++)
						{
							this.shift_wise_new.morning_shifts_arr.push({
								start_date:saved_shifts[shift].start_date,
								end_date:saved_shifts[shift].end_date,
								price:saved_shifts[shift].price,
							});
						}
						this.shift_wise_new.morning_shifts_disabled_arr = [];
					}
					
					if(this.shift_wise_new.afternoon_shifts_disabled_arr.length > 0){
						saved_shifts = this.shift_wise_new.afternoon_shifts_disabled_arr;
						for(shift=0; shift<saved_shifts.length; shift++)
						{
							this.shift_wise_new.afternoon_shifts_arr.push({
								start_date:saved_shifts[shift].start_date,
								end_date:saved_shifts[shift].end_date,
								price:saved_shifts[shift].price,
							});
						}
						this.shift_wise_new.afternoon_shifts_disabled_arr = [];
					}
					
					if(this.shift_wise_new.evening_shifts_disabled_arr.length > 0){
						saved_shifts = this.shift_wise_new.evening_shifts_disabled_arr;
						for(shift=0; shift<saved_shifts.length; shift++)
						{
							this.shift_wise_new.evening_shifts_arr.push({
								start_date:saved_shifts[shift].start_date,
								end_date:saved_shifts[shift].end_date,
								price:saved_shifts[shift].price,
							});
						}
						this.shift_wise_new.evening_shifts_disabled_arr = [];
					}
					
					morning_selected_shifts = {
						'start_time' : this.shift_wise_new.morning_start_time ? moment(this.shift_wise_new.morning_start_time, "hh:mm A").format("HH:mm:ss") : null,
						'end_time' : this.shift_wise_new.morning_end_time ? moment(this.shift_wise_new.morning_end_time, "hh:mm A").format("HH:mm:ss") : null,
						'publish_status' : this.shift_wise_new.morning_publish_status,
						'shifts' : this.shift_wise_new.morning_shifts_arr.length > 0 ? this.shift_wise_new.morning_shifts_arr : [],
					};
					afternoon_selected_shifts = {
						'start_time' : this.shift_wise_new.afternoon_start_time ? moment(this.shift_wise_new.afternoon_start_time, "hh:mm A").format("HH:mm:ss") : null,
						'end_time' : this.shift_wise_new.afternoon_end_time ? moment(this.shift_wise_new.afternoon_end_time, "hh:mm A").format("HH:mm:ss") : null,
						'publish_status' : this.shift_wise_new.afternoon_publish_status,
						'shifts' : this.shift_wise_new.afternoon_shifts_arr.length > 0 ? this.shift_wise_new.afternoon_shifts_arr : [],
					};
					evening_selected_shifts = {
						'start_time' : this.shift_wise_new.evening_start_time ? moment(this.shift_wise_new.evening_start_time, "hh:mm A").format("HH:mm:ss") : null,
						'end_time' : this.shift_wise_new.evening_end_time ? moment(this.shift_wise_new.evening_end_time, "hh:mm A").format("HH:mm:ss") : null,
						'publish_status' : this.shift_wise_new.evening_publish_status,
						'shifts' : this.shift_wise_new.evening_shifts_arr.length > 0 ? this.shift_wise_new.evening_shifts_arr : [],
					};
					
					final_shifts = {
						'morning_selected_shifts' : morning_selected_shifts,
						'afternoon_selected_shifts' : afternoon_selected_shifts,
						'evening_selected_shifts' : evening_selected_shifts,
					};
					
					const tab_type = "shift_timing";
					const tabs = JSON.parse(
					'{"overview":1, "shift": 1, "more_detais":0, "gallery":0 , "amenities":0, "add_on":0}'
					);
					axios.post("/api/add_child_venue", {
						shift_timing_arr: final_shifts,
						filled_tab: tabs,
						tab_type: tab_type,
						child_venue_id: this.child_venue_id,
						shift_types_selected: this.shift_types_selected,
					})
					.then((resp) => {
						if (resp.data.status_code == 200) {
							if (resp.data.status) {
								this.set_as_time();
								this.get_moreDetails();
								this.shift_wise_new.shift_error_status = true;
								// resolve(true);
							}
							} else {
							this.shift_wise_new.shift_error_status = false;
							// reject(false);
						}
					});
					}else{
					this.shift_wise_new.shift_error_status = false;
				}
				
			},
			next_overview: function () {
				this.checkparent='no';
				return new Promise((resolve, reject) => {
					this.$v.overview_form.$touch();
					// if (!this.$v.overview_form.$anyError && this.IsplaceChange === false) {
					/*  if (this.overview_form.renovated != "")
						this.overview_form.renovated = moment(
						this.overview_form.renovated,
						"yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
					).format("yyyy"); */
					const tab_type = "overview";
					const tabs = JSON.parse(
					'{"overview":1, "shift": 0, "more_detais":0, "gallery":0 , "amenities":0, "add_on":0}'
					);
					axios
					.post("/api/add_child_venue", {
						tab_type: tab_type,
						child_venue_id: this.child_venue_id,
						child_venue_name: this.overview_form.v_name,
						child_venue_location: this.overview_form.v_location,
						child_venue_city: this.overview_form.city,
						child_venue_pincode: this.overview_form.pincode,
						child_venue_contact: this.overview_form.contact,
						venue_category_id: this.overview_form.category,
						parent_venue_id: this.parent_venue_id,
						wing: this.overview_form.wing,
						renovated: this.overview_form.renovated,
						guest_rooms: this.overview_form.guest_room,
						total_meeting_space: this.overview_form.metting_space,
						distance_from_airport: this.overview_form.dist_airport,
						//child_venue_details: this.overview_form.details_bio,
						nearby_attraction: this.overview_form.near_by,
						filled_tab: tabs,
						place_name: place_name,
						place_formatted_address: place_formatted_address,
						city: this.overview_form.city,
						state: this.overview_form.state,
						country: 'india',
						pin: this.overview_form.pincode,
						latitude: latitude,
						longitude: longitude,
						place_id: this.overview_form.city,
						cancellation: this.overview_form.cancellation_policy,
						facility: this.overview_form.facility_restrictions,
						more: this.overview_form.details_bio,
						aditional: this.overview_form.more_info,
						address: this.overview_form.address,
					})
					.then((resp) => {
						if (resp.data.status_code == 200) {
							if (resp.data.status) {
								this.child_venue_id = resp.data.child_venue_id;
								this.$v.overview_form.$reset();
								this.get_shift_time();
								localStorage.setItem('create_type', 'Edit'); //temporary
								resolve(true);
								} else {
								this.$root.$refs.app.showToast("danger", resp.data.message);
								reject(false);
							}
						}
					});
					/*  } else {
						reject(false);
					} */
				});
			},
			get_overview: function () {
				this.child_venue_id = this.$route.params.id;
				const tab_type = "overview";
				axios
				.post("/api/fetch_details", {
					tab_type: tab_type,
					child_venue_id: this.child_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.parent_venue_id =
							resp.data.child_venue_details[0].parent_venue_id;
							this.overview_form.v_name =
							resp.data.child_venue_details[0].child_venue_name;
							this.overview_form.pincode =
							resp.data.child_venue_details[0].child_venue_pincode;
							this.overview_form.city =
							resp.data.child_venue_details[0].child_venue_city;
							this.overview_form.contact =
							resp.data.child_venue_details[0].child_venue_contact;
							this.overview_form.category =
							resp.data.child_venue_details[0].venue_category_id;
							this.overview_form.wing = resp.data.child_venue_details[0].wing;
							this.overview_form.renovated =
							resp.data.child_venue_details[0].renovated;
							this.overview_form.guest_room =
							resp.data.child_venue_details[0].guest_rooms;
							this.overview_form.metting_space =
							resp.data.child_venue_details[0].total_meeting_space;
							this.overview_form.dist_airport =
							resp.data.child_venue_details[0].distance_from_airport;
							this.overview_form.more_info =
							resp.data.child_venue_details[0].more_info;
							this.overview_form.details_bio =
							resp.data.child_venue_details[0].child_venue_details;
							this.overview_form.near_by =
							resp.data.child_venue_details[0].nearby_attraction;
							this.overview_form.location =
							resp.data.child_venue_details[0].child_venue_city; 
							this.overview_form.v_location =
							resp.data.child_venue_details[0].child_venue_city;
							
							this.overview_form.cancellation_policy =
							resp.data.child_venue_details[0].cancellation;
							
							this.overview_form.facility_restrictions =
							resp.data.child_venue_details[0].aditional_info;
							
							this.overview_form.address =
							resp.data.child_venue_details[0].address;
							
							/*   place_formatted_address =
							resp.data.child_venue_details[0].place_formatted_address; */
							/* document.getElementById("v_location").value =
								resp.data.child_venue_details[0].place_name;
							this.IsplaceChange = false; */
							// document.getElementById("v_location").focus();
							/* place_name = resp.data.child_venue_details[0].place_name;
								state = resp.data.child_venue_details[0].child_venue_state;
								city = resp.data.child_venue_details[0].child_venue_city;
								country = resp.data.child_venue_details[0].child_venue_country;
								pin = resp.data.child_venue_details[0].child_venue_pincode;
								latitude = resp.data.child_venue_details[0].latitude;
								longitude = resp.data.child_venue_details[0].longitude;
							place_id = resp.data.child_venue_details[0].place_id; */
							} else {
							// const parent_id = this.$route.params.id;
							this.$router.push("/venues/");
						}
					}
				});
			},
			set_as_time() {
				var stime = "";
				var etime = "";
				for (var i = 0; i < this.shift_time.length; i++) {
					stime = new Date(
					"1970-01-01T" + this.shift_time[i].shift_stime + "Z"
					).toLocaleTimeString("en-US", {
						timeZone: "UTC",
						hour12: true,
						hour: "numeric",
						minute: "numeric",
					});
					etime = new Date(
					"1970-01-01T" + this.shift_time[i].shift_etime + "Z"
					).toLocaleTimeString("en-US", {
						timeZone: "UTC",
						hour12: true,
						hour: "numeric",
						minute: "numeric",
					});
					if (etime === "11:59 PM") {
						etime = "12:00 AM";
					}
					if (stime === "11:59 PM") {
						stime = "12:00 AM";
					}
					if (stime.length !== 8) {
						stime = "0" + stime;
					}
					if (etime.length !== 8) {
						etime = "0" + etime;
					}
					
					this.shift_time[i].shift_stime = stime;
					this.shift_time[i].shift_etime = etime;
				}
			},
			overview_category_list() {
				axios.post("/api/fetch_categories").then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							for (var i = 0; i < resp.data.venue_categories.length; i++) {
								this.category_options.push({
									value: resp.data.venue_categories[i].id,
									text: resp.data.venue_categories[i].name,
								});
							}
						}
					}
				});
			},
			remove(index) {
				//
				this.shift_time.splice(index, 1);
			},
			addMore() {
				this.shift_time.push({
					shift_sdate: new Date().toISOString().substr(0, 10),
					shift_edate: "",
					shift: null,
					show_status: false,
					shift_stime: null,
					shift_etime: null,
					hour_range: "",
					error_m: false,
					shift_edate_m: false,
					error_s: "",
					error_end_date: "",
					error_shift_select: false,
					error_shift_m: "",
					price: "",
					price_status: false,
					price_message: "",
					timelist_from: [{ value: null, text: "Start" }],
					timelist_end: [{ value: null, text: "End" }],
				});
			},
			show_times(index, type) {
				if (type === null) {
					this.shift_time[index].hour_range = "";
					this.shift_time[index].show_status = false;
					this.shift_time[index].error_shift_select = true;
					this.shift_time[index].error_m = false;
					this.shift_time[index].error_shift_m = "Please select shift..!";
				} 
				else if (type == 4 || type == 3 || type == 2 || type == 1) {
					this.shift_time[index].error_shift_select = false;
					this.shift_time[index].timelist_from = this.shift_time[
					index
					].timelist_end = [
					{ value: null, text: "Select time" },
					{ value: "06:00 AM", text: "06:00 AM" },
					{ value: "07:00 AM", text: "07:00 AM" },
					{ value: "08:00 AM", text: "08:00 AM" },
					{ value: "09:00 AM", text: "09:00 AM" },
					{ value: "10:00 AM", text: "10:00 AM" },
					{ value: "11:00 AM", text: "11:00 AM" },
					{ value: "12:00 PM", text: "12:00 PM" },
					{ value: "01:00 PM", text: "01:00 PM" },
					{ value: "02:00 PM", text: "02:00 PM" },
					{ value: "03:00 PM", text: "03:00 PM" },
					{ value: "04:00 PM", text: "04:00 AM" },
					{ value: "05:00 PM", text: "05:00 PM" },
					{ value: "06:00 PM", text: "06:00 PM" },
					{ value: "07:00 PM", text: "07:00 PM" },
					{ value: "08:00 PM", text: "08:00 PM" },
					{ value: "09:00 PM", text: "09:00 AM" },
					{ value: "10:00 PM", text: "10:00 PM" },
					{ value: "11:00 PM", text: "11:00 PM" },
					{ value: "12:00 AM", text: "12:00 AM" },
					];
					this.shift_time[index].error_m = false;
					} else {
					this.shift_time[index].error_shift_select = false;
					this.shift_time[index].error_m = false;
				}
			},
			file_day_light(files) {
				return files.length === 1
				? files[0].name
				: `${files.length} files are selected`;
			},
			file_front_view(files) {
				return files.length === 1
				? files[0].name
				: `${files.length} files are selected`;
			},
			file_night_life(files) {
				return files.length === 1
				? files[0].name
				: `${files.length} files are selected`;
			},
			file_back_view(files) {
				return files.length === 1
				? files[0].name
				: `${files.length} files are selected`;
			},
			next_gallery_view() {
				return true;
			},
			get_amenities() {
				//
				axios.post("/api/get_amenities").then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.amenities_details = resp.data.amenities_details;
							this.get_amenities_data();
						}
					}
				});
			},
			add_amenities(id) {
				if (this.amenities_get_datas.includes(id)) {
					this.amenities_get_datas.splice(
					this.amenities_get_datas.indexOf(id),
					1
					);
					} else {
					this.amenities_get_datas.push(id);
				}
				this.added_amenities = this.amenities_get_datas;
			},
			next_amenities() {
				return new Promise((resolve, reject) => {
					const tab_type = "amenities";
					const tabs = JSON.parse(
					'{"overview":1, "shift": 1, "more_detais":1, "gallery":1, "amenities":1, "add_on":0}'
					);
					axios
					.post("/api/add_child_venue", {
						filled_tab: tabs,
						tab_type: tab_type,
						child_venue_id: this.child_venue_id,
						amenities_arr: this.added_amenities,
					})
					.then((resp) => {
						if (resp.data.status_code == 200) {
							if (resp.data.status) {
								this.add_on_Listing();
								resolve(true);
							}
							} else {
							reject(false);
						}
					});
				});
			},
			image_videos(files) {
				return files.length === 1
				? files[0].name
				: `${files.length} files are selected`;
			},
			CreateAddOn() {
				this.$v.add_on.$reset();
				this.add_on.category_id = null;
				this.add_on.image_video = [];
				this.$bvModal.show("add_on");
				this.add_on_category_list();
			},
			handleOkAddOn(bvModalEvent) {
				//
				bvModalEvent.preventDefault();
				
				this.$v.add_on.$touch();
				if (this.$v.add_on.$anyError) {
					return;
					} else {
					this.handleSubmitAddOn();
				}
			},
			handleSubmitAddOn() {
				const tab_type = "add_on";
				const tabs = JSON.stringify(
				'{"overview":1, "shift": 1, "more_detais":1, "gallery":1 , "amenities":1, "add_on":1}'
				);
				var formDataAdd = new FormData();
				formDataAdd.append("tab_type", tab_type);
				formDataAdd.append("tabs", tabs);
				formDataAdd.append("child_venue_id", this.child_venue_id);
				formDataAdd.append("category_id", this.add_on.category_id);
				formDataAdd.append("add_on_name", this.add_on.name);
				formDataAdd.append("price", this.add_on.price);
				formDataAdd.append("more_details", this.add_on.more_details);
				if (this.add_on.image_video.length != 0) {
					formDataAdd.append("photo_video[]", this.add_on.image_video);
				}
				// if (this.add_on.image_video.length != 0) {
				//   for (var i = 0; i < this.add_on.image_video.length; i++) {
				//     formDataAdd.append("photo_video[]", this.add_on.image_video[i]);
				//   }
				// }
				
				axios
				.post("/api/add_child_venue", formDataAdd, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.$root.$refs.app.showToast("success", resp.data.message);
							this.closeAddOn();
							this.add_on_listing_data();
							this.add_on_Listing();
						}
						else {
							this.$root.$refs.app.showToast("danger", resp.data.message);
						}
					}
				});
			},
			closeAddOn() {
				this.$nextTick(() => {
					this.$bvModal.hide("add_on");
					this.add_on.name = "";
					
					this.add_on.category_id = null;
					(this.add_on.price = ""), (this.add_on.more_details = "");
					//  this.add_on.image_video = [];
					this.$v.add_on.$reset();
				});
			},
			add_on_category_list() {
				axios
				.post("/api/get_categories", { child_venue_id: this.child_venue_id })
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.add_on_category_options = [
							{ value: null, text: "Please select category" },
							];
							for (var i = 0; i < resp.data.category.length; i++) {
								this.add_on_category_options.push({
									value: resp.data.category[i].id,
									text: resp.data.category[i].name,
								});
							}
						}
					}
				});
			},
			handlePageChange(value) {
				this.page = value;
				this.load = true;
				this.add_on_listing_data();
			},
			handlePageSizeChange(event) {
				
				this.pageSize = event;
				this.load = true;
				this.page = 1;
				this.add_on_listing_data();
			},
			sortChanged(e) {
				this.sortBy = e.sortBy;
				this.load = true;
				this.add_on_listing_data();
			},
			add_on_listing_data() {
				this.load = true;
				this.show = true;
				// this.venue = [];
				var keyword = this.search;
				const params = this.getRequestParams(
				this.page,
				this.pageSize,
				this.sortBy
				);
				axios
				.post("/api/fetch_add_on", {
					params,
					search_keyword: keyword,
					addon_name: this.addon_selected,
					category: this.category_selected,
					child_venue_id: this.child_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						this.add_on_data = [];
						if (resp.data.status) {
							this.count = this.totalData = resp.data.total_records;
							this.totalRows = resp.data.datalist.length;
							
							for (var i = 0; i < resp.data.datalist.length; i++) {
								this.add_on_data.push({
									id: resp.data.datalist[i].add_on_id,
									name: resp.data.datalist[i].add_on_name,
									price: resp.data.datalist[i].price,
									category: resp.data.datalist[i].has_category.name,
									publish_status: resp.data.datalist[i].publish_status,
								});
							}
							
							
							this.show = false;
							this.load = false;
							} else {
							this.count = this.totalData = resp.data.total_records;
							this.totalRows = resp.data.datalist.length;
							
							this.show = false;
							this.load = false;
						}
					}
				});
			},
			getRequestParams(page, pageSize, sortBy = "") {
				let params = {};
				if (page) {
					params["page"] = page - 1;
				}
				if (pageSize) {
					params["size"] = pageSize;
				}
				params["sortBy"] = sortBy;
				return params;
			},
			key_search() {
				const value = "1";
				this.handlePageChange(value);
			},
			applyFilter() {
				const value = "1";
				this.handlePageChange(value);
			},
			resetFilter() {
				(this.addon_selected = ""),
				(this.category_selected = ""),
				(this.search = "");
				this.add_on_listing_data();
			},
			add_on_Listing() {
				axios
				.post("/api/get_add_on_name", { child_venue_id: this.child_venue_id })
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.addon_option = resp.data.add_name;
							
							this.category_option_add_on = resp.data.category;
						}
					}
				});
			},
			CreateAddOnC() {
				this.$bvModal.show("add_on_c");
			},
			
			handleOkAddOnC(bvModalEvent) {
				bvModalEvent.preventDefault();
				
				this.$v.c_add.$touch();
				if (this.$v.c_add.$anyError) {
					return;
					} else {
					this.handleSubmitAddOnC();
				}
			},
			handleSubmitAddOnC() {
				axios
				.post("/api/add_category", {
					category_name: this.c_add.add_category_addon,
					child_venue_id: this.child_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.$root.$refs.app.showToast("success", resp.data.message);
							this.closeAddOnC();
							this.add_on_Listing();
						}
						else {
							this.$root.$refs.app.showToast("danger", resp.data.message[0]);
						}
					}
				});
			},
			closeAddOnC() {
				this.$nextTick(() => {
					this.$bvModal.hide("add_on_c");
					this.c_add.add_category_addon = "";
					this.$v.c_add.$reset();
				});
			},
			statusChange(add_id) {
				
				axios.post("/api/add_on_publish_status", { id: add_id }).then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.$root.$refs.app.showToast("success", resp.data.message);
							//this.venuelListing();
							} else {
							this.$root.$refs.app.showToast("danger", resp.data.message);
						}
					}
				});
			},
			next_moredetails() {
				return new Promise((resolve, reject) => {
					this.$v.more_detail.$touch();
					if (!this.$v.more_detail.$anyError) {
						const tab_type = "more_details";
						const tabs = JSON.parse(
						'{"overview":1, "shift": 1, "more_detais":1, "gallery":0, "amenities":0, "add_on":0}'
						);
						axios
						.post("/api/add_child_venue", {
							filled_tab: tabs,
							tab_type: tab_type,
							child_venue_id: this.child_venue_id,
							setup_type_id: this.more_detail.type_of,
							meeting_space: this.more_detail.metting_space,
							room_size: this.more_detail.room_size,
							ceiling_height: this.more_detail.ceiling_heignt,
							room_dimention: this.more_detail.room_dimension,
							u_shape: this.more_detail.u_shape,
							banquet_round: this.more_detail.banquate_round,
							cocktail_round: this.more_detail.cocktail_round,
							theater: this.more_detail.theater,
							classroom: this.more_detail.classroom,
							boardroom: this.more_detail.boardroom,
							e_shape: this.more_detail.e_shape,
							hollow_square: this.more_detail.hollow_square,
							perimeter_seating: this.more_detail.perimiter_seating,
							royal_conf: this.more_detail.royal_conference,
							t_shape: this.more_detail.t_shape,
							talk_show: this.more_detail.talk_show,
						})
						.then((resp) => {
							if (resp.data.status_code == 200) {
								if (resp.data.status) {
									this.get_gallery_data();
									resolve(true);
									} else {
									reject(false);
								}
							}
						});
						} else {
						reject(false);
					}
				});
			},
			get_typeofRoom() {
				axios.get("/api/get_setup_type").then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							for (var i = 0; i < resp.data.setup_type.length; i++) {
								this.type_of_data.push({
									value: resp.data.setup_type[i].id,
									text: resp.data.setup_type[i].type_name,
								});
							}
						}
					}
				});
			},
			go_to_child() {
				const id = this.$route.params.id;
				this.$router.push("/venues/child-venue/" + id);
			},
			export_add_on() {
				var keyword = this.search;
				const params = this.getRequestParams(
				this.page,
				this.pageSize,
				this.sortBy
				);
				axios
				.post(
				"/api/export_addon_pdf",
				{
					params,
					search_keyword: keyword,
					addon_name: this.addon_selected,
					category: this.category_selected,
					child_venue_id: this.child_venue_id,
				},
				{
					responseType: "blob",
				}
				)
				.then((resp) => {
					const url = window.URL.createObjectURL(new Blob([resp.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "add_on_lists.pdf");
					document.body.appendChild(link);
					link.click();
				});
			},
			get_location() {
				/* eslint-disable */
				var self_var = this;
				var address1Field = document.getElementById("v_location");
				autocomplete = new google.maps.places.Autocomplete(address1Field, {
					componentRestrictions: { country: ["in"] },
				});
				google.maps.event.addListener(autocomplete, "place_changed", function () {
					place = autocomplete.getPlace();
					place_name = place.name;
					place_formatted_address = place.formatted_address;
					place_id = place.place_id;
					//  var address = place.formatted_address;
					
					self_var.IsplaceChange = false;
					
					
					latitude = place.geometry.location.lat();
					longitude = place.geometry.location.lng();
					
					var latlng = new google.maps.LatLng(latitude, longitude);
					
					var geocoder = (geocoder = new google.maps.Geocoder());
					
					geocoder.geocode({ latLng: latlng }, function (results, status) {
						if (status == google.maps.GeocoderStatus.OK) {
							if (results[0]) {
								// var address = results[0].formatted_address;
								
								pin =
								results[0].address_components[
								results[0].address_components.length - 1
								].long_name;
								
								country =
								results[0].address_components[
								results[0].address_components.length - 2
								].long_name;
								
								state =
								results[0].address_components[
								results[0].address_components.length - 3
								].long_name;
								
								city =
								results[0].address_components[
								results[0].address_components.length - 4
								].long_name;
								
								document.getElementById("pincode").value = pin;
								document.getElementById("pincode").focus();
								document.getElementById("pincode").blur();
								document.getElementById("city").value = city;
								
								document.getElementById("city").focus();
								document.getElementById("city").blur();
							}
						}
					});
				});
			},
			change_location_update() {
				//this.IsplaceChange = true;
			},
			updateAddon(id) {
				var attachment;
				this.add_on_category_list();
				axios
				.post("api/edit_addon", {
					addon_id: id,
					parent_id: this.parent_venue_id,
					child_id: this.child_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.add_on_edit.add_on_id = resp.data.add_on[0].add_on_id;
							this.add_on_edit.name = resp.data.add_on[0].add_on_name;
							this.add_on_edit.more_details = resp.data.add_on[0].more_details;
							this.add_on_edit.price = resp.data.add_on[0].price;
							attachment = resp.data.add_on[0].attachment;
							this.add_on_edit.category =
							resp.data.add_on[0].add_on_category_id;
							if (attachment !== null) {
								this.add_on_edit.file_addon =
								resp.data.path + JSON.parse(attachment)[0];
							}
							
							this.$bvModal.show("add_on_edit");
							
						}
					}
				});
			},
			viewAddon(id) {
				// var parent_id = this.$route.params.id;
				var attachment;
				this.add_on_category_list();
				axios
				.post("api/edit_addon", {
					addon_id: id,
					parent_id: this.parent_venue_id,
					child_id: this.child_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.add_on_edit.add_on_id = resp.data.add_on[0].add_on_id;
							this.add_on_edit.name = resp.data.add_on[0].add_on_name;
							this.add_on_edit.more_details = resp.data.add_on[0].more_details;
							
							this.add_on_edit.price = resp.data.add_on[0].price;
							attachment = resp.data.add_on[0].attachment;
							this.add_on_edit.category =
							resp.data.add_on[0].add_on_category_id;
							if (attachment !== null) {
								this.add_on_edit.file_addon =
								resp.data.path + JSON.parse(attachment)[0];
							}
							for (var i = 0; i < this.add_on_category_options.length; i++) {
								if (
								this.add_on_edit.category ===
								this.add_on_category_options[i].value
								) {
									this.add_on_edit.category_name =
									this.add_on_category_options[i].text;
								}
							}
							this.$bvModal.show("add_on_view");
							
						}
					}
				});
			},
			closeAddOnView() {
				this.add_on_edit.add_on_id = "";
				this.add_on_edit.name = "";
				this.add_on_edit.more_details = "";
				this.add_on_edit.price = "";
				this.add_on_edit.file_addon = "";
				this.add_on_edit.category_name = "";
				this.add_on_edit.category = null;
				this.add_on_edit.image_video = [];
				this.$bvModal.hide("add_on_edit");
			},
			handleOkAddOnEdit(bvModalEvent) {
				//
				bvModalEvent.preventDefault();
				
				this.$v.add_on_edit.$touch();
				if (this.$v.add_on_edit.$anyError) {
					return;
					} else {
					this.handleSubmitAddOnEdit();
				}
			},
			handleSubmitAddOnEdit() {
				var formDataAdd = new FormData();
				formDataAdd.append("add_on_id", this.add_on_edit.add_on_id);
				formDataAdd.append("child_venue_id", this.child_venue_id);
				formDataAdd.append("category_id", this.add_on_edit.category);
				formDataAdd.append("add_on_name", this.add_on_edit.name);
				formDataAdd.append("price", this.add_on_edit.price);
				formDataAdd.append("more_details", this.add_on_edit.more_details);
				if (this.add_on_edit.image_video.length != 0) {
					formDataAdd.append("photo_video[]", this.add_on_edit.image_video);
				}
				
				axios
				.post("/api/update_addon", formDataAdd, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.$root.$refs.app.showToast("success", resp.data.message);
							// this.$bvModal.hide("add_on_edit");
							this.closeAddOnEdit();
						}
						else {
							this.$root.$refs.app.showToast("danger", resp.data.message);
						}
					}
				});
			},
			closeAddOnEdit() {
				this.add_on_listing_data();
				this.add_on_edit.add_on_id = "";
				this.add_on_edit.name = "";
				this.add_on_edit.more_details = "";
				this.add_on_edit.price = "";
				this.add_on_edit.file_addon = "";
				this.add_on_edit.category_name = "";
				this.add_on_edit.category = null;
				this.add_on_edit.image_video = [];
				this.$v.add_on_edit.$reset();
				this.$bvModal.hide("add_on_edit");
			},
			get_shift_time() {
				const tab_type = "shift_timing";
				axios
				.post("/api/fetch_details", {
					tab_type: tab_type,
					child_venue_id: this.child_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							
							if (resp.data.shift_types_selected == "2") 
							{
								//selected shift - shift wise
								this.shift_types_selected = "2";
								this.set_shift_hour("2");
								var saved_shifts;
								
								//morning shift
								this.shift_wise_new.morning_publish_status = resp.data.child_venue_details.morning_selected_shifts.publish_status;
								this.shift_wise_new.morning_start_time = resp.data.child_venue_details.morning_selected_shifts.start_time == null ? null : this.convertTime24to12APITime(resp.data.child_venue_details.morning_selected_shifts.start_time);
								this.shift_wise_new.morning_end_time = resp.data.child_venue_details.morning_selected_shifts.end_time == null ? null : this.convertTime24to12APITime(resp.data.child_venue_details.morning_selected_shifts.end_time);
								saved_shifts = resp.data.child_venue_details.morning_selected_shifts.shifts;
								this.shift_wise_new.morning_shifts_arr = [];
								for(var shift=0; shift<saved_shifts.length; shift++)
								{
									this.shift_wise_new.morning_shifts_arr.push({
										start_date:saved_shifts[shift].from_date,
										end_date:saved_shifts[shift].to_date,
										price:saved_shifts[shift].price,
									});
								}
								this.updateShiftsTimeMorning();
								saved_shifts = resp.data.child_venue_details.morning_selected_disabled_shifts;
								this.shift_wise_new.morning_shifts_disabled_arr = [];
								for(var shift=0; shift<saved_shifts.length; shift++)
								{
									this.shift_wise_new.morning_shifts_disabled_arr.push({
										start_date:saved_shifts[shift].from_date,
										end_date:saved_shifts[shift].to_date,
										price:saved_shifts[shift].price,
									});
								}
								
								//afternoon shift
								this.shift_wise_new.afternoon_publish_status = resp.data.child_venue_details.afternoon_selected_shifts.publish_status;
								this.shift_wise_new.afternoon_start_time = resp.data.child_venue_details.afternoon_selected_shifts.start_time == null ? null : this.convertTime24to12APITime(resp.data.child_venue_details.afternoon_selected_shifts.start_time);
								this.shift_wise_new.afternoon_end_time = resp.data.child_venue_details.afternoon_selected_shifts.end_time == null ? null : this.convertTime24to12APITime(resp.data.child_venue_details.afternoon_selected_shifts.end_time);
								saved_shifts = resp.data.child_venue_details.afternoon_selected_shifts.shifts;
								this.shift_wise_new.afternoon_shifts_arr = [];
								for(var shift=0; shift<saved_shifts.length; shift++)
								{
									this.shift_wise_new.afternoon_shifts_arr.push({
										start_date:saved_shifts[shift].from_date,
										end_date:saved_shifts[shift].to_date,
										price:saved_shifts[shift].price,
									});
								}
								this.updateShiftsTimeAfternoon();
								saved_shifts = resp.data.child_venue_details.afternoon_selected_disabled_shifts;
								this.shift_wise_new.afternoon_shifts_disabled_arr = [];
								for(var shift=0; shift<saved_shifts.length; shift++)
								{
									this.shift_wise_new.afternoon_shifts_disabled_arr.push({
										start_date:saved_shifts[shift].from_date,
										end_date:saved_shifts[shift].to_date,
										price:saved_shifts[shift].price,
									});
								}
								
								//evening shift
								this.shift_wise_new.evening_publish_status = resp.data.child_venue_details.evening_selected_shifts.publish_status;
								this.shift_wise_new.evening_start_time = resp.data.child_venue_details.evening_selected_shifts.start_time == null ? null : this.convertTime24to12APITime(resp.data.child_venue_details.evening_selected_shifts.start_time);
								this.shift_wise_new.evening_end_time = resp.data.child_venue_details.evening_selected_shifts.end_time == null ? null : this.convertTime24to12APITime(resp.data.child_venue_details.evening_selected_shifts.end_time);
								saved_shifts = resp.data.child_venue_details.evening_selected_shifts.shifts;
								this.shift_wise_new.evening_shifts_arr = [];
								for(var shift=0; shift<saved_shifts.length; shift++)
								{
									this.shift_wise_new.evening_shifts_arr.push({
										start_date:saved_shifts[shift].from_date,
										end_date:saved_shifts[shift].to_date,
										price:saved_shifts[shift].price,
									});
								}
								this.updateShiftsTimeEvening();
								saved_shifts = resp.data.child_venue_details.evening_selected_disabled_shifts;
								this.shift_wise_new.evening_shifts_disabled_arr = [];
								for(var shift=0; shift<saved_shifts.length; shift++)
								{
									this.shift_wise_new.evening_shifts_disabled_arr.push({
										start_date:saved_shifts[shift].from_date,
										end_date:saved_shifts[shift].to_date,
										price:saved_shifts[shift].price,
									});
								}
								
							} 
							else 
							{
								//selected shift hourly wise
								this.shift_types_selected = "1";
								this.set_shift_hour("1");
								
								this.shift_time = [];
								var time_range = [];
								var stime = "";
								var etime = "";
								for (var i = 0; i < resp.data.child_venue_details.length; i++) {
									time_range = [
									{ value: null, text: "Select time" },
									{ value: "06:00 AM", text: "06:00 AM" },
									{ value: "07:00 AM", text: "07:00 AM" },
									{ value: "08:00 AM", text: "08:00 AM" },
									{ value: "09:00 AM", text: "09:00 AM" },
									{ value: "10:00 AM", text: "10:00 AM" },
									{ value: "11:00 AM", text: "11:00 AM" },
									{ value: "12:00 PM", text: "12:00 PM" },
									{ value: "01:00 PM", text: "01:00 PM" },
									{ value: "02:00 PM", text: "02:00 PM" },
									{ value: "03:00 PM", text: "03:00 PM" },
									{ value: "04:00 PM", text: "04:00 AM" },
									{ value: "05:00 PM", text: "05:00 PM" },
									{ value: "06:00 PM", text: "06:00 PM" },
									{ value: "07:00 PM", text: "07:00 PM" },
									{ value: "08:00 PM", text: "08:00 PM" },
									{ value: "09:00 PM", text: "09:00 AM" },
									{ value: "10:00 PM", text: "10:00 PM" },
									{ value: "11:00 PM", text: "11:00 PM" },
									{ value: "12:00 AM", text: "12:00 AM" },
									];
									
									stime = new Date(
									"1970-01-01T" +
									resp.data.child_venue_details[i].from_time +
									"Z"
									).toLocaleTimeString("en-US", {
										timeZone: "UTC",
										hour12: true,
										hour: "numeric",
										minute: "numeric",
									});
									etime = new Date(
									"1970-01-01T" + resp.data.child_venue_details[i].to_time + "Z"
									).toLocaleTimeString("en-US", {
										timeZone: "UTC",
										hour12: true,
										hour: "numeric",
										minute: "numeric",
									});
									if (etime === "11:59 PM") {
										etime = "12:00 AM";
									}
									if (stime === "11:59 PM") {
										stime = "12:00 AM";
									}
									if (stime.length !== 8) {
										stime = "0" + stime;
									}
									if (etime.length !== 8) {
										etime = "0" + etime;
									}
									this.shift_time.push({
										shift_sdate: resp.data.child_venue_details[i].from_date,
										shift_edate: resp.data.child_venue_details[i].to_date,
										shift: resp.data.child_venue_details[i].shift_type,
										show_status: false,
										shift_stime: stime,
										shift_etime: etime,
										hour_range: time_range,
										error_m: false,
										shift_edate_m: false,
										error_s: "",
										error_end_date: "",
										error_shift_select: false,
										error_shift_m: "",
										price: resp.data.child_venue_details[i].price,
										price_status: false,
										price_message: "",
										timelist_from: time_range,
										timelist_end: time_range,
									});
									if(stime !== "")
									{
										this.check_set_time(i);
									}
									if(etime !== "")
									{
										this.check_set_time(i);
									}
								}
							}
						}
					}
				});
			},
			convertTime24to12APITime(curr_time){
				var new_time = new Date(
				"1970-01-01T" + curr_time + "Z"
				).toLocaleTimeString("en-US", {
					timeZone: "UTC",
					hour12: true,
					hour: "numeric",
					minute: "numeric",
				});
				if (new_time === "11:59 PM") {
					new_time = "12:00 AM";
				}
				if (new_time.length !== 8) {
					new_time = "0" + new_time;
				}
				return new_time;
			},
			get_moreDetails() {
				const tab_type = "more_details";
				axios
				.post("/api/fetch_details", {
					tab_type: tab_type,
					child_venue_id: this.child_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.more_detail.type_of =
							resp.data.more_details[0].setup_type_id;
							this.more_detail.metting_space =
							resp.data.more_details[0].meeting_space;
							this.more_detail.room_size = resp.data.more_details[0].room_size;
							this.more_detail.ceiling_heignt =
							resp.data.more_details[0].ceiling_height;
							this.more_detail.room_dimension =
							resp.data.more_details[0].room_dimention;
							this.more_detail.u_shape = resp.data.more_details[0].u_shape;
							this.more_detail.banquate_round =
							resp.data.more_details[0].banquet_round;
							this.more_detail.cocktail_round =
							resp.data.more_details[0].cocktail_round;
							this.more_detail.theater = resp.data.more_details[0].theater;
							this.more_detail.classroom = resp.data.more_details[0].classroom;
							this.more_detail.boardroom = resp.data.more_details[0].boardroom;
							this.more_detail.e_shape = resp.data.more_details[0].e_shape;
							this.more_detail.hollow_square =
							resp.data.more_details[0].hollow_square;
							this.more_detail.perimiter_seating =
							resp.data.more_details[0].perimeter_seating;
							this.more_detail.royal_conference =
							resp.data.more_details[0].royal_conf;
							this.more_detail.t_shape = resp.data.more_details[0].t_shape;
							this.more_detail.talk_show = resp.data.more_details[0].talk_show;
						}
					}
				});
			},
			get_amenities_data() {
				const tab_type = "amenities";
				axios
				.post("/api/fetch_details", {
					tab_type: tab_type,
					child_venue_id: this.child_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						for (var a = 0; a < resp.data.get_amenities.length; a++) {
							this.amenities_get_datas.push(
							resp.data.get_amenities[a].amenities_id
							);
						}
						
						for (var b = 0; b < this.amenities_details.length; b++) {
							for (
							var c = 0;
							c < this.amenities_details[b].amenities.length;
							c++
							) {
								if (
								this.amenities_get_datas.includes(
								this.amenities_details[b].amenities[c].id
								)
								) {
									
									Object.assign(this.amenities_details[b].amenities[c], {
										status: 1,
									});
									} else {
									Object.assign(this.amenities_details[b].amenities[c], {
										status: 0,
									});
								}
							}
							continue;
						}
						
						this.added_amenities = this.amenities_get_datas;
					}
				});
			},
			get_gallery_data() {
				const tab_type = "gallery_view";
				axios
				.post("/api/fetch_details", {
					tab_type: tab_type,
					child_venue_id: this.child_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							var path = resp.data.path;
							this.gallary_view_data = resp.data.gallery_view_details;
							this.gallary_view_day = [];
							this.gallary_view_night = [];
							this.gallary_view_front = [];
							this.gallary_view_back = [];
							
							for (var a = 0; a < this.gallary_view_data.length; a++) {
								this.gallary_view_data[a].attachment =
								path + this.gallary_view_data[a].attachment;
								if (this.gallary_view_data[a].image_type === "1") {
									this.gallary_view_day.push(this.gallary_view_data[a]);
								}
								if (this.gallary_view_data[a].image_type === "2") {
									this.gallary_view_night.push(this.gallary_view_data[a]);
								}
								if (this.gallary_view_data[a].image_type === "3") {
									this.gallary_view_front.push(this.gallary_view_data[a]);
								}
								if (this.gallary_view_data[a].image_type === "4") {
									this.gallary_view_back.push(this.gallary_view_data[a]);
								}
							}
							this.day_light++;
							this.day_night++;
							this.front_view++;
							this.back_view++;
						}
					}
				});
			},
			remove_images(type, index) {
				if (type === "day_light") {
					this.delete_gallery_view(type, this.gallary_view_day[index], index);
				}
				if (type === "day_night") {
					this.delete_gallery_view(type, this.gallary_view_night[index], index);
				}
				if (type === "front_view") {
					this.delete_gallery_view(type, this.gallary_view_front[index], index);
				}
				if (type === "back_view") {
					this.delete_gallery_view(type, this.gallary_view_back[index], index);
				}
			},
			delete_gallery_view(type, file_data, index) {
				axios
				.post("/api/delete_gallery_file", {
					child_venue_id: this.child_venue_id,
					image_id: file_data.id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.$root.$refs.app.showToast("success", resp.data.message);
							this.delete_images(type, index);
							} else {
							this.$root.$refs.app.showToast("danger", resp.data.message);
						}
					}
				});
			},
			delete_images(type, index) {
				if (type === "day_light") {
					this.gallary_view_day.splice(index, 1);
					this.day_light++;
				}
				if (type === "day_night") {
					this.gallary_view_night.splice(index, 1);
					this.day_night++;
				}
				if (type === "front_view") {
					this.gallary_view_front.splice(index, 1);
					this.front_view++;
				}
				if (type === "back_view") {
					this.gallary_view_back.splice(index, 1);
					this.back_view++;
				}
			},
			closeGallery() {
				this.files_day_light.length = [];
				this.files_night_life.length = [];
				this.files_front_view.length = [];
				this.files_back_view.length = [];
				this.$bvModal.hide("gallary_view");
			},
			add_gallary() {
				this.$bvModal.show("gallary_view");
			},
			saveGallery() {
				
				if (
				this.files_day_light.length != 0 ||
				this.files_night_life.length != 0 ||
				this.files_front_view.length != 0 ||
				this.files_back_view.length != 0
				) {
					const tab_type = "gallery_view";
					var edit_status = true;
					const tabs = JSON.stringify(
					'{"overview":1, "shift": 1, "more_detais":1, "gallery":1 , "amenities":0, "add_on":0}'
					);
					var formData = new FormData();
					formData.append("tab_type", tab_type);
					formData.append("tabs", tabs);
					formData.append("child_venue_id", this.child_venue_id);
					formData.append("edit_status", edit_status);
					
					if (this.files_day_light.length != 0) {
						for (var i = 0; i < this.files_day_light.length; i++) {
							formData.append("day_arr[]", this.files_day_light[i]);
						}
					}
					
					if (this.files_night_life.length != 0) {
						for (var a = 0; a < this.files_night_life.length; a++) {
							formData.append("night_arr[]", this.files_night_life[a]);
						}
					}
					if (this.files_front_view.length != 0) {
						for (var n = 0; n < this.files_front_view.length; n++) {
							formData.append("front_arr[]", this.files_front_view[n]);
						}
					}
					if (this.files_back_view.length != 0) {
						for (var b = 0; b < this.files_back_view.length; b++) {
							formData.append("back_arr[]", this.files_back_view[b]);
						}
					}
					axios
					.post("/api/add_child_venue", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then((resp) => {
						if (resp.data.status_code == 200) {
							if (resp.data.status) {
								this.get_gallery_data();
								this.closeGallery();
								} else {
								this.$root.$refs.app.showToast(
								"danger",
								"only .jpg, .png, .jpeg, .svg, .mp4, .mkv file format are supported"
								);
							}
						}
					});
				}
			},
			updateShiftsTimeMorning()
			{
				this.updateShiftsTimingShiftWiseMorning(6, 24);
			},
			updateShiftsTimingShiftWiseMorning(start_time, end_time) {
				if (this.shift_wise_new.morning_start_time != null) {
					var morning_end_time;
					if(this.shift_wise_new.afternoon_start_time != null){
						morning_end_time = this.shift_wise_new.afternoon_start_time;
						}else if(this.shift_wise_new.evening_start_time != null){
						morning_end_time = this.shift_wise_new.afternoon_start_time;
						}else{
						morning_end_time = end_time;
					}
					
					this.shift_wise_new.morning_timelist_end = this.generateHoursInterval(this.addIntervalMinutes(this.shift_wise_new.morning_start_time), this.calculateMinuteTime(morning_end_time));
					// this.updateShiftsEndTimeMorning(end_time);
				}
				if (this.shift_wise_new.morning_end_time != null) {
					this.shift_wise_new.morning_timelist_from = this.generateHoursInterval(this.calculateMinuteTime(this.shift_wise_new.shifts_start_time), this.removeIntervalMinutes(this.shift_wise_new.morning_end_time));
					// this.updateShiftsStartTimeMorning(start_time);
					
					this.shift_wise_new.afternoon_timelist_from = this.generateHoursInterval(this.calculateMinuteTime(this.shift_wise_new.morning_end_time), this.calculateMinuteTime(this.shift_wise_new.shifts_end_time));
					this.shift_wise_new.afternoon_timelist_end = this.generateHoursInterval(this.calculateMinuteTime(this.shift_wise_new.morning_end_time), this.calculateMinuteTime(this.shift_wise_new.shifts_end_time));
					this.shift_wise_new.evening_timelist_from = this.generateHoursInterval(this.calculateMinuteTime(this.shift_wise_new.morning_end_time), this.calculateMinuteTime(this.shift_wise_new.shifts_end_time));
					this.shift_wise_new.evening_timelist_end = this.generateHoursInterval(this.calculateMinuteTime(this.shift_wise_new.morning_end_time), this.calculateMinuteTime(this.shift_wise_new.shifts_end_time));
				}
			},
			updateShiftsEndTimeMorning(end_time) {
				shiftnew_convertedStartTime = parseInt(
				this.convertTime12to24(this.shift_wise_new.morning_start_time)
				);
				this.shift_wise_new.morning_timelist_end = [];
				this.shift_wise_new.morning_timelist_end.push({
					value: null,
					text: "Select time",
				});
				
				//update end time to selcted starttime
				shiftnew_curr_time = shiftnew_convertedStartTime + 1;
				while (shiftnew_curr_time <= end_time) {
					shiftnew_time24 = this.convertTime24to12(shiftnew_curr_time);
					this.shift_wise_new.morning_timelist_end.push({
						value: shiftnew_time24,
						text: shiftnew_time24,
					});
					shiftnew_curr_time = parseInt(shiftnew_curr_time) + 1;
				}
			},
			updateShiftsStartTimeMorning(start_time) {
				shiftnew_convertedEndTime = parseInt(this.convertTime12to24(this.shift_wise_new.morning_end_time));
				this.shift_wise_new.morning_timelist_from = [];
				this.shift_wise_new.morning_timelist_from.push({
					value: null,
					text: "Select time",
				});
				
				//update start time to selcted Endtime
				shiftnew_curr_time = start_time;
				while (shiftnew_curr_time < shiftnew_convertedEndTime) {
					shiftnew_time24 = this.convertTime24to12(shiftnew_curr_time);
					this.shift_wise_new.morning_timelist_from.push({
						value: shiftnew_time24,
						text: shiftnew_time24,
					});
					shiftnew_curr_time = parseInt(shiftnew_curr_time) + 1;
				}
			},
			updateShiftsTimeAfternoon()
			{
				this.updateShiftsTimingShiftWiseAfternoon(6, 24);
			},
			updateShiftsTimingShiftWiseAfternoon(start_time, end_time) {
				if (this.shift_wise_new.afternoon_start_time != null) {
					// this.updateShiftsEndTimeAfternoon(end_time);
					
					var afternoon_end_time = this.shift_wise_new.evening_start_time != null ? this.shift_wise_new.evening_start_time : end_time;
					this.shift_wise_new.afternoon_timelist_end = this.generateHoursInterval(this.addIntervalMinutes(this.shift_wise_new.afternoon_start_time), this.calculateMinuteTime(afternoon_end_time));
					
					this.shift_wise_new.morning_timelist_end = this.generateHoursInterval(this.addIntervalMinutes(this.shift_wise_new.morning_start_time), this.calculateMinuteTime(this.shift_wise_new.afternoon_start_time));
				}
				if (this.shift_wise_new.afternoon_end_time != null) {
					// this.updateShiftsStartTimeAfternoon(start_time);
					
					var afternoon_start_time = this.shift_wise_new.morning_end_time != null ? this.shift_wise_new.morning_end_time : start_time;
					console.log
					this.shift_wise_new.afternoon_timelist_from = this.generateHoursInterval(this.calculateMinuteTime(afternoon_start_time), this.removeIntervalMinutes(this.shift_wise_new.afternoon_end_time));
					
					this.shift_wise_new.evening_timelist_from = this.generateHoursInterval(this.calculateMinuteTime(this.shift_wise_new.afternoon_end_time), this.calculateMinuteTime(this.shift_wise_new.shifts_end_time));
					this.shift_wise_new.evening_timelist_end = this.generateHoursInterval(this.calculateMinuteTime(this.shift_wise_new.afternoon_end_time), this.calculateMinuteTime(this.shift_wise_new.shifts_end_time));
				}
			},
			updateShiftsEndTimeAfternoon(end_time) {
				shiftnew_convertedStartTime = parseInt(
				this.convertTime12to24(this.shift_wise_new.afternoon_start_time)
				);
				this.shift_wise_new.afternoon_timelist_end = [];
				this.shift_wise_new.afternoon_timelist_end.push({
					value: null,
					text: "Select time",
				});
				
				//update end time to selcted starttime
				shiftnew_curr_time = shiftnew_convertedStartTime + 1;
				while (shiftnew_curr_time <= end_time) {
					shiftnew_time24 = this.convertTime24to12(shiftnew_curr_time);
					this.shift_wise_new.afternoon_timelist_end.push({
						value: shiftnew_time24,
						text: shiftnew_time24,
					});
					shiftnew_curr_time = parseInt(shiftnew_curr_time) + 1;
				}
			},
			updateShiftsStartTimeAfternoon(start_time) {
				shiftnew_convertedEndTime = parseInt(this.convertTime12to24(this.shift_wise_new.afternoon_end_time));
				this.shift_wise_new.afternoon_timelist_from = [];
				this.shift_wise_new.afternoon_timelist_from.push({
					value: null,
					text: "Select time",
				});
				
				//update start time to selcted Endtime
				shiftnew_curr_time = start_time;
				while (shiftnew_curr_time < shiftnew_convertedEndTime) {
					shiftnew_time24 = this.convertTime24to12(shiftnew_curr_time);
					this.shift_wise_new.afternoon_timelist_from.push({
						value: shiftnew_time24,
						text: shiftnew_time24,
					});
					shiftnew_curr_time = parseInt(shiftnew_curr_time) + 1;
				}
			},
			updateShiftsTimeEvening()
			{
				this.updateShiftsTimingShiftWiseEvening(6, 24);
			},
			updateShiftsTimingShiftWiseEvening(start_time, end_time) {
				if (this.shift_wise_new.evening_start_time != null) {
					// this.updateShiftsEndTimeEvening(end_time);
					this.shift_wise_new.evening_timelist_end = this.generateHoursInterval(this.addIntervalMinutes(this.shift_wise_new.evening_start_time), this.calculateMinuteTime(this.shift_wise_new.shifts_end_time));
					
					var afternoon_end_time = this.shift_wise_new.evening_start_time != null ? this.shift_wise_new.evening_start_time : end_time;
					this.shift_wise_new.afternoon_timelist_end = this.generateHoursInterval(this.addIntervalMinutes(this.shift_wise_new.afternoon_start_time), this.calculateMinuteTime(afternoon_end_time));
				}
				if (this.shift_wise_new.evening_end_time != null) {
					// this.updateShiftsStartTimeEvening(start_time);
					this.shift_wise_new.evening_timelist_from = this.generateHoursInterval(this.calculateMinuteTime(this.shift_wise_new.evening_start_time), this.removeIntervalMinutes(this.shift_wise_new.evening_end_time));
				}
			},
			updateShiftsEndTimeEvening(end_time) {
				shiftnew_convertedStartTime = parseInt(
				this.convertTime12to24(this.shift_wise_new.evening_start_time)
				);
				this.shift_wise_new.evening_timelist_end = [];
				this.shift_wise_new.evening_timelist_end.push({
					value: null,
					text: "Select time",
				});
				
				//update end time to selcted starttime
				shiftnew_curr_time = shiftnew_convertedStartTime + 1;
				while (shiftnew_curr_time <= end_time) {
					shiftnew_time24 = this.convertTime24to12(shiftnew_curr_time);
					this.shift_wise_new.evening_timelist_end.push({
						value: shiftnew_time24,
						text: shiftnew_time24,
					});
					shiftnew_curr_time = parseInt(shiftnew_curr_time) + 1;
				}
			},
			updateShiftsStartTimeEvening(start_time) {
				shiftnew_convertedEndTime = parseInt(this.convertTime12to24(this.shift_wise_new.evening_end_time));
				this.shift_wise_new.evening_timelist_from = [];
				this.shift_wise_new.evening_timelist_from.push({
					value: null,
					text: "Select time",
				});
				
				//update start time to selcted Endtime
				shiftnew_curr_time = start_time;
				while (shiftnew_curr_time < shiftnew_convertedEndTime) {
					shiftnew_time24 = this.convertTime24to12(shiftnew_curr_time);
					this.shift_wise_new.evening_timelist_from.push({
						value: shiftnew_time24,
						text: shiftnew_time24,
					});
					shiftnew_curr_time = parseInt(shiftnew_curr_time) + 1;
				}
			},
			addMoreShiftMorning(){
				this.shift_wise_new.morning_shifts_arr.push({
					start_date:new Date().toISOString().substr(0, 10),
					end_date:'',
					price:'',
				});
			},
			removeShiftMorning(index){
				this.shift_wise_new.morning_shifts_arr.splice(index, 1);
			},
			addMoreShiftAfternoon(){
				this.shift_wise_new.afternoon_shifts_arr.push({
					start_date:new Date().toISOString().substr(0, 10),
					end_date:'',
					price:'',
				});
			},
			removeShiftAfternoon(index){
				this.shift_wise_new.afternoon_shifts_arr.splice(index, 1);
			},
			addMoreShiftEvening(){
				this.shift_wise_new.evening_shifts_arr.push({
					start_date:new Date().toISOString().substr(0, 10),
					end_date:'',
					price:'',
				});
			},
			removeShiftEvening(index){
				this.shift_wise_new.evening_shifts_arr.splice(index, 1);
			},
			//bellow function will calculate minutes from time ex. if 06:00 AM then return 360 minutes
			calculateMinuteTime(selected_time){
				// var startHourInMinute = 60 * startTime; // start time in minutes
				var timeInMinutes;
				selected_time = moment(selected_time, "hh:mm A").format("HH:mm");
				timeInMinutes = moment.duration(selected_time).asMinutes();
				if(timeInMinutes == 0){
					timeInMinutes = 1440;
				}
				return timeInMinutes;
			},
			//bellow function will add 30 minute to time ex. if 6:00 AM then return 6:30 AM's minutes
			addIntervalMinutes(selected_time)
			{
				var timeInMinutes = this.calculateMinuteTime(selected_time) + this.shift_wise_new.shifts_interval;
				return timeInMinutes;
			},
			//bellow function will remove 30 minute from time ex. if 11:30 PM then return 11:00 PM's minutes
			removeIntervalMinutes(selected_time)
			{
				var timeInMinutes = this.calculateMinuteTime(selected_time) - this.shift_wise_new.shifts_interval;
				return timeInMinutes;
			},
			generateHoursInterval(startHourInMinute,endHourInMinute){
				var times = [], hh, mm, curr_time, fomated_time;
				times.push({
					value: null,
					text: "Select time",
				});
				
				for (let i = 0; startHourInMinute <= 24 * 60; i++) {
					if (startHourInMinute > endHourInMinute) break;
					hh = Math.floor(startHourInMinute / 60); // getting hours of day in 0-24 format
					mm = startHourInMinute % 60; // getting minutes of the hour in 0-55 format
					curr_time = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2);
					fomated_time = moment(curr_time, "hh:mm").format(
					"hh:mm A"
					);
					times.push({
						value: fomated_time,
						text: fomated_time,
					});
					startHourInMinute = startHourInMinute + this.shift_wise_new.shifts_interval;
				}
				return times;
			},
			sortShiftsDatesMorning(){
				setTimeout(() => 
				this.shift_wise_new.morning_shifts_arr.sort(function(a,b){
					// Turn your strings into dates, and then subtract them
					// to get a value that is either negative, positive, or zero.
					// return new Date(b.start_date) - new Date(a.start_date);
					return new Date(a.start_date) - new Date(b.start_date);
				})
				, this.shift_wise_new.sort_shifts_timeout);
			},
			sortShiftsDatesAfternoon(){
				setTimeout(() => 
				this.shift_wise_new.afternoon_shifts_arr.sort(function(a,b){
					return new Date(a.start_date) - new Date(b.start_date);
				})
				, this.shift_wise_new.sort_shifts_timeout);
			},
			sortShiftsDatesEvening(){
				setTimeout(() => 
				this.shift_wise_new.evening_shifts_arr.sort(function(a,b){
					return new Date(a.start_date) - new Date(b.start_date);
				})
				, this.shift_wise_new.sort_shifts_timeout);
			},
			morningPublishChange(){
				if(this.shift_wise_new.morning_publish_status == '0'){
					this.shift_wise_new_layout();
				}
			},
			afternoonPublishChange(){
				if(this.shift_wise_new.afternoon_publish_status == '0'){
					this.shift_wise_new_layout();
				}
			},
			eveningPublishChange(){
				if(this.shift_wise_new.evening_publish_status == '0'){
					this.shift_wise_new_layout();
				}
			},
			shift_wise_new_layout()
			{
				
				this.shift_wise_new.morning_start_time = null;
				this.shift_wise_new.morning_end_time = null;
				this.shift_wise_new.afternoon_start_time = null;
				this.shift_wise_new.afternoon_end_time = null;
				this.shift_wise_new.evening_start_time = null;
				this.shift_wise_new.evening_end_time = null;
				
				this.shift_wise_new.morning_timelist_from = 
				this.shift_wise_new.morning_timelist_end =
				this.shift_wise_new.afternoon_timelist_from = 
				this.shift_wise_new.afternoon_timelist_end =
				this.shift_wise_new.evening_timelist_from =
				this.shift_wise_new.evening_timelist_end = this.generateHoursInterval(this.calculateMinuteTime(this.shift_wise_new.shifts_start_time), this.calculateMinuteTime(this.shift_wise_new.shifts_end_time));
			},
			CreateAmetc() {
				this.Getamenities();
				this.$bvModal.show("add_on_amtec");
				
			},
			Getamenities()
			{
				axios
				.post("api/listget_amenities",
				{ child_id: this.child_venue_id})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.amCategory=resp.data.get_amenities;
							} else {
							this.$root.$refs.app.showToast("danger", resp.data.message);
						}
					}
				});
			},
			selectnew()
			{
				this.newCategory=true;
			},
			selectRemove()
			{
				this.newCategory=false;
			},
			handleOkAddOnCQ() {
				axios
				.post("/api/add_amenties_category",this.amenities)
				.then((resp) => {
				   if (resp.data.status_code == 200) {
					if (resp.data.status) { 
					this.$root.$refs.app.showToast("success", 'added');
					//  this.closeAddOnC();
					//  this.add_on_Listing();
					this.get_amenities();
					  }
					} 
					else
					{
						this.$root.$refs.app.showToast("danger", resp.data.message);
					}
				});
			},
			pincodeCheck()
			{
				
				const str=this.overview_form.pincode;
				this.NearBy(str);
				axios
                .post("/api/pincodeCheck", {
                    pincode: str
				})
                .then((resp) => {
					
					//console.log(resp.data.NearBy.airport);
                    if (resp.data.pincode_city==0) {
						this.overview_form.city="";
						this.overview_form.state="";
						this.overview_form.v_location="";
					}
                    else
                    {
						
                        this.overview_form.city=resp.data.pincode_city.division;
						this.overview_form.state=resp.data.pincode_city.state;
						this.overview_form.v_location=resp.data.pincode_city.division;
						
						/* this.overview_form.dist_airport=resp.data.NearBy.airport;
						this.overview_form.near_by=resp.data.NearBy.attraction; */
						
						
					}
					
					
                    
				});
			},
			NearBy(str)
			{
				axios
                .post("/api/NearBy", {
                    pincode: str
				})
                .then((resp) => {
					
					
					this.overview_form.dist_airport=resp.data.NearBy.airport;
					this.overview_form.near_by=resp.data.NearBy.attraction.join(', ');
					
					
                    
					
                    
				});
			},
			nameKeydown(e) {
				return this.amenities.NCategory.replace(/\W/g, "");
			},
			isLetter(e) {
				let char = String.fromCharCode(e.keyCode);
				if (/^[A-Z a-z]+$/.test(char)) return true;
				else e.preventDefault();
			},
			checkvalidation(data) {
				
				var content= /^[A-Z a-z]+$/.test(data);
				// alert(content);
				if(content==false)
				{
					this.buttonok=true;
				}
				else
				{
					this.buttonok=false;
				}
			},
			newShiftUpdate()
			{
				this.checkparent='no';
				
				return new Promise((resolve, reject) => {
					resolve(true);
					this.get_data('Morning');
					this.get_data('Afternoon');
					this.get_data('Evening');
					this.checkparent='yes';
					//this.$forceUpdate()

					/* if(this.card_enabled==true)
					{
						this.get_data('Morning');
					}
					if(this.card_enabled1==true)
					{
						this.get_data('Afternoon');
					}
					if(this.card_enabled2==true)
					{
						this.get_data('Evening');
					} */

					//alert(this.mst);
					/* if (this.shift_types_selected === null) {
						this.shift_types_selected_status = true;
						reject(false);
					}
					else if(this.shift_types_selected == 1){
						this.next_shift_timing_hourly();
					}
					else if(this.shift_types_selected == 2){
						this.next_shift_timing_shift_wise();
					}
					
					if(this.shift_wise_new.shift_error_status == true){
						resolve(true);
						}else{
						reject(false);
					} */
				});
			},
			
			/*  TEMP */
			isValidTime(checkshift,fromtime,totime,side) 
			{
				side
				// const totimes =fromtime ? fromtime : totime;
				const time = moment(fromtime, this.format);
				const beforeTime = moment(totime, this.format);
				const afterTime = moment('23:59:00', this.format);
				/* 	let m=0;
					let n=0;
				let c=false; */
				if (time.isBetween(beforeTime, afterTime))
				{
					//this.card_enabled=true;
					//this.card_enabled=true;
					/* if(checkshift=="morning")
					{ */
					//this.card_enabled=true;
					/* m=this.mst;
						n = this.met;
					c=this.card_enabled; */
					//	}
					/* else if(checkshift=="afternoon")
						{
						this.card_enabled1=true;
						m=this.ast;
						n = this.aet;
						c=this.card_enabled1;
						}
						else if(checkshift=="evening")
						{
						this.card_enabled2=true;
						m=this.est;
						n = this.eet;
						c=this.card_enabled2;
					} */
					//this.saveTiming(checkshift,m,n,side,c);
					return true;
					
				} 
				else 
				{
					return false;
				}
				
				
			},
			isValidTime1(checkshift,fromtime,totime,side) 
			{
				side
				var beforeTime;
				var afterTime;
				var time;
				if(this.card_enabled==true && side=="L")
				{
					beforeTime = moment(this.met, "HH:mm"); //proper
					//	console.log('Moring Enabled Proper condiiton');
					afterTime = moment('23:59', "HH:mm");
					time= moment(totime, "HH:mm");
				}
				else if(side=="L")
				{
					beforeTime = moment(totime, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(totime, "HH:mm");
					//console.log('Moring Dibled Proper condiiton');  //proper
				}
				if(side=="R")
				{
					beforeTime = moment(this.ast, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.aet, "HH:mm");
					console.log('Right side Enabled Proper condiiton');
				}
				
				
				
				
				
				
				
				if(time.isSameOrAfter(beforeTime) && time.isSameOrBefore(afterTime))
				{
					return true;
				}
				/* if(moment(fromtime,'HH:mm:ss').isSameOrAfter(moment(totime,'HH:mm:ss')) && 
					moment(fromtime,'HH:mm:ss').isSameOrAfter(moment('23:59:00','HH:mm:ss')))
					{
					return true;
				} */
				/* 	if(side=="L")
					{
					console.log(fromtime);
					console.log(totime);
					console.log(side);
					}
					const time1 = moment(fromtime, this.format);
					const beforeTime1 = moment(totime, this.format);
					const afterTime1 = moment('23:59:00', this.format);
					if (time1.isBetween(beforeTime1, afterTime1))
					{
					return true;
					} 
					else 
					{
					return false;
				} */
				
				/* side
					var time1=null;
					
					if(this.card_enabled==true && side=="L")
					{
					time1 = moment(fromtime, this.format);
					}
					else if(this.card_enabled==false && side=="L")
					{
					time1 = moment('00:00:00', this.format);
					}
					if(side=="R")
					{
					time1 = moment(this.aet, this.format);
				}  */
				
				//time1 = moment(fromtime, this.format);
				
				//const beforeTime1 = moment(totime, this.format);
				//const afterTime1 = moment('23:59:00', this.format);
				
				
				
				
			},
			isValidTime2(checkshift,fromtime,totime,side) 
			{
				var beforeTime;
				var afterTime;
				var time;
				if(this.card_enabled==true && this.card_enabled1==true && side=="L")
				{
					beforeTime = moment(this.aet, "HH:mm"); //proper
					//	console.log('Moring Enabled Proper condiiton');
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.est, "HH:mm");
				}
				else if(this.card_enabled==true && this.card_enabled1==false && side=="L")
				{
					beforeTime = moment(this.met, "HH:mm"); //proper
					//	console.log('Moring Enabled Proper condiiton');
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.est, "HH:mm");
				}
				else if(this.card_enabled==false && this.card_enabled1==true && side=="L")
				{
					beforeTime = moment(this.aet, "HH:mm"); //proper
					//	console.log('Moring Enabled Proper condiiton');
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.est, "HH:mm");
				}
				else if(side=="L")
				{
					beforeTime = moment(totime, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(totime, "HH:mm");
					//console.log('Moring Dibled Proper condiiton');  //proper
				}
				if(side=="R")
				{
					beforeTime = moment(this.est, "HH:mm");
					afterTime = moment('23:59', "HH:mm");
					time= moment(this.eet, "HH:mm");
					console.log('Right side Enabled Proper condiiton');
				}
				
				
				
				
				
				
				
				if(time.isSameOrAfter(beforeTime) && time.isSameOrBefore(afterTime))
				{
					return true;
				}
				/* side
					
					var  time2;
					if(fromtime=="")
					{
					time2 = moment('00:00:00', this.format);
					}
					else{
					time2 = moment(fromtime, this.format);
					}
					//const time2 = moment(fromtime, this.format);
					
					const beforeTime2 = moment(totime, this.format);
					const afterTime2 = moment('23:59:00', this.format);
					
					if (time2.isBetween(beforeTime2, afterTime2))
					{
					//this.card_enabled2=true;
					
					return true;
					} 
					else 
					{
					return false;
				} */
				
				
			}, 
			/* saveTiming(checkshift,time,beforeTime,side,status)
				{
				if(time!=null && beforeTime!=null)
				{
				console.log(checkshift+' -- '+time +' --- '+beforeTime+' --- '+side);
				axios.post("/api/calender_time_update",{shift:checkshift,time:time,beforeTime:beforeTime,status:status,vid:this.child_venue_id }).then((resp) => {
				resp;
				});
				}
				
			}, */
			get_toggle_data(checkshift,status)
			{
				axios.post("/api/Enable_public",{shift:checkshift,status:status,vid:this.child_venue_id }).then((resp) => {
					resp;
					this.year_calender();
					this.allHeader();
				});
			},
			today_date()
			{
				var myDate = new Date();
				var d = new Date(myDate),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();
				
				if (month.length < 2) 
				month = '0' + month;
				if (day.length < 2) 
				day = '0' + day;
				return [year,month,day].join('-');
			},
			checkvalidation(a,b,c)
			{
				return [c,b,a].join('-');
				
			},
			get_data(shift)
			{
				if(shift=='Morning')
				{
					this.morningsave=true;
					
					if(this.mst =="" || this.met =="")
					{
						this.$root.$refs.app.showToast("danger", 'Please select Time');
						
						//this.card_enabled='false';
						return  false;
					}
					if(this.card_enabled==true)
					{    //Same time			 //morning afternoon
                        if(this.mst==this.met)
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							
							return false;
						}
					}
					if(this.card_enabled1==true)
					{
						if(this.mst==this.ast || this.mst==this.aet || this.met==this.ast || this.met==this.aet   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					if(this.card_enabled2==true)
					{
						if(this.mst==this.est || this.mst==this.eet || this.met==this.est || this.met==this.eet   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					
					axios.post("/api/calender_time_update",{shift_name:this.mshift_name,shift:shift,time:this.mst,beforeTime:this.met,status:this.card_enabled,vid:this.child_venue_id }).then((resp) => {
						resp;
					//	this.$root.$refs.app.showToast("success", 'Morning Time Updated');
						
						
					});
					
				}
				else if(shift=='Afternoon')
				{
					this.afternoonsave=true;
					if(this.ast =="" || this.aet =="")
					{
						//this.card_enabled1=false;
						this.$root.$refs.app.showToast("danger", 'Please select Time');
						return  false;
					}
					if(this.card_enabled1==true)
					{    //Same time			 //morning afternoon
                        if(this.ast==this.aet)
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					if(this.card_enabled==true)
					{
						if(this.ast==this.mst || this.ast==this.met || this.aet==this.mst || this.aet==this.met   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					if(this.card_enabled2==true)
					{
						if(this.ast==this.est || this.ast==this.eet || this.aet==this.est || this.aet==this.eet   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					axios.post("/api/calender_time_update",{shift_name:this.ashift_name,shift:shift,time:this.ast,beforeTime:this.aet,status:this.card_enabled1,vid:this.child_venue_id }).then((resp) => {
						resp;
						//this.$root.$refs.app.showToast("success", 'Afternoon Time Updated');
						
						
					});
				}
				else if(shift=='Evening')
				{
					this.eveningsave=true
					if(this.est =="" || this.eet =="")
					{
						this.$root.$refs.app.showToast("danger", 'Please select Time');
						//this.card_enabled2='false';
						return  false;
						
					}
					if(this.card_enabled3==true)
					{    //Same time			 //morning afternoon
                        if(this.est==this.eet)
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					if(this.card_enabled==true)
					{
						if(this.est==this.mst || this.est==this.met || this.eet==this.mst || this.eet==this.met   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					if(this.card_enabled1==true)
					{
						if(this.est==this.ast || this.est==this.aet || this.eet==this.ast || this.eet==this.aet   )
						{
							this.$root.$refs.app.showToast("danger", 'Time Overlapped Please check');
							return false;
						}
					}
					axios.post("/api/calender_time_update",{shift_name:this.eshift_name,shift:shift,time:this.est,beforeTime:this.eet,status:this.card_enabled2,vid:this.child_venue_id }).then((resp) => {
						resp;
						//this.$root.$refs.app.showToast("success", 'Evening Time Updated');
					
						
					});
				}
				
			},
			allHeader()
			{
				axios.post("/api/all_publish_header",{vid:this.child_venue_id} ).then((resp) => {
					this.headers=resp.data.data;
					
				});
			},
            check_shift_header()
			{
                this.child_venue_id =this.$route.params.id;
				axios.get("/api/check_shift_header?vid="+this.child_venue_id).then((resp) => {
				
					var morning=resp.data.morning
					var afternoon=resp.data.afternoon
					var evening=resp.data.evening

					this.mshift_name = morning ? morning.custom_name : "Morning";
					this.mst = morning ? morning.from_time : "";
					this.met = morning ? morning.to_time : "";
					this.card_enabled =  morning ? morning.publish==1 ? true : false : false;

                   

					this.ashift_name = afternoon ? afternoon.custom_name : "Afternoon";
					this.ast = afternoon ? afternoon.from_time : "";
					this.aet = afternoon ? afternoon.to_time : "";
					this.card_enabled1 =  afternoon ? afternoon.publish==1 ? true : false : false;

					this.eshift_name = evening ? evening.custom_name : "Evening";
					this.est = evening ? evening.from_time : "";
					this.eet = evening ? evening.to_time : "";
					this.card_enabled2 =  evening ? evening.publish==1 ? true : false : false;
					
					
				});
			},
			updateDescriptions()
			{
				alert('hi');
				this.check_shift_header();
			},
			loadTodos()
			{
				alert('bye')
				this.check_shift_header();
				
			},
			saveGallerydesc(gid,index)
			{
				axios.post("/api/dallery_text_update",{gid:gid,desc:this.gallerydesc[index]}).then((resp) => {
					resp
				})


			},
			
			/*  TEMP */
			doSomething() {
				this.checkparent="nono";
      		}
	
		},
		mounted() {
			this.overview_category_list();
			this.get_overview();
			this.get_amenities();
			this.add_on_listing_data();
			this.get_typeofRoom();
			this.get_location();
			
			this.shift_wise_new_layout();
			
			var wizard = this.$refs.wizard
			wizard.maxStep = wizard.tabs.length - 1
			wizard.tabs.forEach((tab)=>{
				tab.checked = true
			})

			this.check_shift_header();

			//this.checkparent='no';
			
			
		},
		computed: {
			firstNameValid() {
				
				var content= /^[A-Z a-z]+$/.test(this.amenities.NCategory);
				return content ? false :true ;
			},
			new_amenities() {
				var content= /^[A-Z a-z]+$/.test(this.amenities.new_amenities);
				return content ? false :true ;
			}
		}
		
	};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style >
	.row.add-remove-button {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	margin: 10px 0;
	}
	.row.add-remove-button input {
	height: auto;
	max-width: 340px;
	flex: 1;
	}
	.row.add-remove-button button {
	background: #000;
	text-transform: uppercase;
	font-size: 14px;
	margin-left: 8px;
	}
	.form-group {
	margin: 0;
	}
	@media (max-width: 640px) {
	}
	#pd {
	padding: 10px;
	}
	
	#e_message {
	color: red;
	}
	#v_location {
	border-color: rgba(0, 0, 0, 0.1);
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	color: #4f5467;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #e9ecef;
	border-radius: 4px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	.shift-time-font{
	font-size: 15px;
	}
	.shift-box {
	padding: 7px 10px;
	}
	.shift-box .row{
	margin-inline: -5px;
	}
	.shift-box .row .col{
	padding: 5px;
	}
	.time-selector select.custom-select {    
	padding-left: 0.5em;
	}
	.shift-card.card-deck .card-body {
    padding: 1rem;
	}
	.shift-box .date-picker.form-control {
    font-size: 13px;
	}
	.shift-box .disable_past_shifts{
	opacity: 0.5;
	}
	.error{
	color: #e94545;
	}
	#renovated
	{
	background: unset;
	}
	
	#category {
	background: transparent;
	}
	
	select#category.is-valid::after{
	content: "good";
	background:red !important;
	}
	.vue-form-wizard .wizard-header {
	padding: 0 !important;
	}
	.event-tab ul.nav-tabs li.nav-item a.active {
    border-color: #DD1BFB;
    background: unset;
	}
	#v_locations
	{
	background:none;
	}
	.built_year .vdp-datepicker input {
	padding: 0 !important;
	}



.img-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color :rgb(112, 103, 103);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
  opacity: 0.8;
	border-bottom-left-radius:16px;
	border-bottom-right-radius:16px;
}

.product-box:hover .img-overlay {
  height: 30%;
}

.img-overlay .text {
  position: absolute;
  top: 30%;
  left: 40%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: left;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
}
.img-overlay  .text span{
  font-weight: 300;
}
.editoption
{
	top: 0;
    position: absolute;
    /* top: -37%; */
    z-index: 222;
    /* background: red; */
    color: white;
    cursor: pointer;
}
.btn-extra
{
	position: absolute;
    right: 21px;
}
.btn-extras
{
	position: absolute;
    right: -50px;
}
.img-overlay .text textarea {
	background: unset;
	border: none;
	border-bottom:1px solid white;
	color:white;
}
.custom-fixed-top {
    position: fixed !important;
    top: 68px;
   
    z-index: 1030;
    width: -webkit-fill-available;
    background: #f3f2ee;
	}

</style>